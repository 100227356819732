import { BreadCrumb, DefaultPageTitle } from 'components'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import SAC from './components/SAC'
import Email from './components/Email'
import Enderecos from './components/Enderecos'

const ContactContainer = styled.div``

const Contact: React.FC = () => {
	return (
		<ContactContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Contato</span>]} />
			<DefaultPageTitle>Contato</DefaultPageTitle>

			<Tabs>
				<TabList>
					<Tab>SAC</Tab>
					<Tab>Email</Tab>
					<Tab>Endereços</Tab>
				</TabList>

				<TabPanel>
					<SAC />
				</TabPanel>
				<TabPanel>
					<Email />
				</TabPanel>
				<TabPanel>
					<Enderecos />
				</TabPanel>
			</Tabs>
		</ContactContainer>
	)
}

export default Contact
