import { convertToLive } from 'helpers/converters/convert-live'
import httpClient from 'http-client'
import LiveFromResponse from 'models/from-api-response/live'
import Live from '../models/live'


const getLive = async (live_id: any) => {
	const live = (await httpClient.get<LiveFromResponse>(`/lives/${live_id}`)).data
	return live;
}

const getAllLives = async () => {
	const sambaVideosLives = (await httpClient.get<LiveFromResponse[]>('/lives')).data

	let convertedList = [] as Live[]
	if (sambaVideosLives) {
		convertedList = sambaVideosLives.map((res) => convertToLive(res))
	}

	return convertedList
}


const getLivesForSelect = async () => {
	const sambaVideosLives = (await httpClient.get<LiveFromResponse[]>('/lives')).data

	let convertedList = [] as Live[]
	if (sambaVideosLives) {
		convertedList = sambaVideosLives.map((res) => convertToLive(res))
	}

	let select
	if (convertedList && convertedList.length) {
		select = convertedList.map((live) => {
			return {
				label: live.name,
				value: live.live_id,
			}
		})
	}

	return select
}

const stopLiveService = async (liveId: string) => {
	await httpClient.put(`/lives/${liveId}/stop`)
}

const changeLiveStatus = async (liveId: string, status: string) => {
	await httpClient.patch(`/lives/${liveId}/status`, {
		status: status
	})
}

const deleteLiveService = async (liveId: string) => {
	return httpClient.delete(`/lives/${liveId}`)
}


export { getLive, getAllLives, stopLiveService, deleteLiveService, getLivesForSelect, changeLiveStatus }
