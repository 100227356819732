import styled from 'styled-components'

export const LogAccessUsersContainer = styled.div``

export const WrapperLiveSelect = styled.div`
  position: relative;
  button{
    background: transparent;
    border: none;
    padding: 10px;
    display: flex;
    align-items:center;
    justify-content: center;
    position: absolute;
    top 0;
    right: 50px;
    cursor: pointer;
    color: hsl(0, 0%, 50%);
    font-size: 15px;
  }
`;