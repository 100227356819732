import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultButton, DefaultPageTitle, CreateButtonDefaultContainer, DefaultTable } from 'components'
import Author from 'models/author'
import {
	getAllAuthors,
	deleteAuthor,
	activateOrInactivateAuthor as activateOrInactivateAuthorService,
} from 'services/authors'
import { AuthorsContainer } from './style'

const Authors: React.FC = () => {
	const history = useHistory()

	const [authors, setAuthors] = useState([] as Author[])

	const getAuthors = async () => {
		const authors = await getAllAuthors()
		if (authors && authors?.authors && authors.authors.length) {
			setAuthors(authors.authors)
		}
	}

	const editAuthor = useCallback(
		(authorId: any) => {
			history.push(`edit-professionals/${authorId}`)
		},
		[history]
	)

	const removeAuthor = useCallback(async (authorId: any) => {
		Swal.fire({
			title: '<strong>Confirmação</strong>',
			html: 'Tem certeza que deseja remover este profissional?',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			focusConfirm: false,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await deleteAuthor(authorId)

					Swal.fire({
						icon: 'success',
						title: 'Sucesso!',
						text: 'Profissional excluido com sucesso!',
					})

					await getAuthors()
				} catch (e: any) {
					let errorText = ''
					switch (e.response.data.message) {
						case 'Unexpected error deleting author - This author can not be deleted. It is already enrolled in a trail.':
							errorText = ' Profissional vinculado a uma aula.'
							break

						default:
							errorText = e.response.data.message
							break
					}
					Swal.fire({
						icon: 'error',
						title: 'Erro ao excluir profissional',
						text: errorText,
					})
				}
			}
		})
	}, [])

	const createAuthor = async () => {
		history.push('create-professionals')
	}

	const activateOrInactivateAuthor = useCallback(async (authorId: string, activate: boolean) => {
		Swal.fire({
			title: 'Confirmação',
			text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este profissional?`,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			focusConfirm: false,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await activateOrInactivateAuthorService(authorId, activate)

					getAuthors()
				} catch (error: any) {
					let errorText = ''
					switch (error.response.data.message) {
						case 'Unexpected error activating/inactivating author - This author can not be inactivated. It is already enrolled in a trail.':
							errorText = ' Profissional vinculado a uma aula.'
							break

						default:
							errorText = error.response.data.message
							break
					}
					Swal.fire({
						icon: 'error',
						title: 'Erro',
						text: `Erro ao ${activate ? 'ativar' : 'inativar'} profissional! ${errorText}`,
					})
				}
			}
		})
	}, [])

	const authorsToBeShown = useMemo(() => {
		return authors && authors.length
			? authors.map((author) => ({
					id: author.author_id,
					name: author.name,
					specialty: author.info.specialty.map((specialty, index, array) => (
						<React.Fragment key={index}>
							{specialty}
							{array.length !== index + 1 ? ', ' : null}
						</React.Fragment>
					)),
					active: author.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
					actions: (
						<>
							<DefaultButton className="small info" onClick={() => editAuthor(author.author_id)}>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => activateOrInactivateAuthor(author.author_id, !author.is_active)}
								className="small warning"
								title={(author.is_active ? 'Inativar' : 'Ativar') + ' profissional'}
							>
								{author.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => removeAuthor(author.author_id)}
								className="small danger"
								title="Excluir Profissional"
							>
								<BiTrash />
							</DefaultButton>
						</>
					),
			  }))
			: []
	}, [authors, editAuthor, removeAuthor, activateOrInactivateAuthor])

	useEffect(() => {
		getAuthors()
	}, [])

	return (
		<AuthorsContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Profissionais</span>]} />
			<DefaultPageTitle>Profissionais</DefaultPageTitle>
			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createAuthor}>Criar Profissionais</DefaultButton>
			</CreateButtonDefaultContainer>
			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Nome</span>,
						propName: 'name',
					},
					{
						headerLabel: <span>Especialidade</span>,
						propName: 'specialty',
					},
					{
						headerLabel: <span>Ativo</span>,
						propName: 'active',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={authorsToBeShown}
				emptyListMessage={'Não foram encontrados profissionais cadastrados!'}
			/>
		</AuthorsContainer>
	)
}

export default Authors
