import styled from 'styled-components'

export const CreateAndEditHomeInfoContainer = styled.div``

export const ContentUploadOrSelectContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	iframe {
		margin-top: 10px;
		width: 600px;
		height: 340px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

export const ButtonGroupInline = styled.div`
	display: flex;
	align-items: center;

	button + button {
		margin-left: 10px;
	}
`

export const BannerUploadContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	img {
		margin-top: 10px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

export const ContentBanner = styled.img`
	max-width: 600px;
`
