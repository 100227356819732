import { BreadCrumb, DefaultButton, DefaultPageTitle, DefaultTable } from 'components'
import DefaultFilterForm from 'components/DefaultFilterForm'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
	getContacts as getContactsService,
	deleteContact as deleteContactService,
	toogleContactActive as toogleContactActiveService,
} from 'services/contacts'
import ContactFromApi from 'models/from-api-response/contact'
import CreateAndEditContact from './components/CreateAndEditContact'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import Swal from 'sweetalert2'
import showErrorMessage from 'helpers/show-error-message'
import ModalContext from 'contexts/ModalContext'

const ContactsContainer = styled.div``

const Contacts: React.FC = () => {
	const [contacts, setContacts] = useState([] as ContactFromApi[])
	const { openModal } = useContext(ModalContext);

	const getContacts = async () => {
		const contacts = await getContactsService()
		setContacts(contacts)
	}

	const createContact = () => {
		openModal('Criar Contato', <CreateAndEditContact onSubmit={getContacts} />)
	}

	const editContact = (contactId: string) => {
		openModal('Editar Contato', <CreateAndEditContact onSubmit={getContacts} contactId={contactId} />)
	}

	const deleteContact = async (contactId: string) => {
		const result = await Swal.fire({
			icon: 'question',
			text: 'Tem certeza que deseja remover este contato?',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			try {
				await deleteContactService(contactId)
			} catch (error) {
				showErrorMessage(error)
			}

			await getContacts()
		}
	}

	const toogleContactActive = async (contactId: string, shouldActive: boolean) => {
		const result = await Swal.fire({
			icon: 'question',
			text: `Tem certeza que deseja ${shouldActive ? 'ativar' : 'inativar'} este contato?`,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			try {
				await toogleContactActiveService(contactId, shouldActive)
			} catch (error) {
				showErrorMessage(error)
			}

			await getContacts()
		}
	}

	useEffect(() => {
		getContacts()
	}, [])

	const contactsToShow = useMemo(() => {
		return contacts?.map((contact) => ({
			name: contact.name,
			employeeName: contact.employee_name,
			email: contact.email,
			phone: contact.phone_number,
			actions: (
				<>
					<DefaultButton
						onClick={() => editContact(contact.contact_id!)}
						type="button"
						title="Editar Contato"
						className="small info"
					>
						<BiEdit />
					</DefaultButton>{' '}
					<DefaultButton
						onClick={() => toogleContactActive(contact.contact_id!, !contact.is_active)}
						type="button"
						title="Ativar/Inativar Contato"
						className="small warning"
					>
						{contact.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
					</DefaultButton>{' '}
					<DefaultButton
						onClick={() => deleteContact(contact.contact_id!)}
						type="button"
						title="Excluir Contato"
						className="small danger"
					>
						<BiTrash />
					</DefaultButton>{' '}
				</>
			),
		}))
	}, [contacts])

	return (
		<ContactsContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Equipe de campo</span>]} />
			<DefaultPageTitle>Equipe de campo</DefaultPageTitle>
			<DefaultFilterForm>
				<div>
					<DefaultButton type="button" onClick={createContact}>
						Adicionar Membro da Equipe de Campo
					</DefaultButton>
				</div>
			</DefaultFilterForm>
			<DefaultTable
				items={contactsToShow}
				headersConfig={[
					{ headerLabel: <span>Nome</span>, propName: 'name' },
					{ headerLabel: <span>Nome do Colaborador</span>, propName: 'employeeName' },
					{ headerLabel: <span>Email</span>, propName: 'email' },
					{ headerLabel: <span>Telefone</span>, propName: 'phone' },
					{ headerLabel: <span>Ações</span>, propName: 'actions' },
				]}
			/>
		</ContactsContainer>
	)
}

export default Contacts
