import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Swal from 'sweetalert2'
import Select from 'react-select'
import { Link, useLocation } from 'react-router-dom'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import DefaultFilterForm from 'components/DefaultFilterForm'
import { DefaultCreationFormButtonGroup } from 'components/DefaultCreationForm'
import { getUsersForSelect } from 'services/users'
import { getAllContentsForSelect } from 'services/contents'
import { generateLogUserAccessReport, generateEngagementReport, generateUsersReport } from 'services/reports'
import DefaultButton from 'components/DefaultButton'

import { LogAccessUsersContainer } from './style'

const LogAccessUser: React.FC = () => {
	const location = useLocation()

	const [usersSelect, setUsersSelect] = useState(([] as unknown) as { label: string; value: string }[])
	const [selectedUsers, setSelectedUsers] = useState(([] as unknown) as { label: string; value: string }[])

	const [contentSelect, setContentSelect] = useState(([] as unknown) as { label: string; value: string }[])
	const [selectedContents, setSelectedContents] = useState(([] as unknown) as { label: string; value: string }[])

	const [dateAccessLte, setDateAccessLte] = useState<string>()
	const [dateAccessGte, setDateAccessGte] = useState<string>()

	const [startAtLte, setStartAtLte] = useState<string>()
	const [startAtGte, setStartAtGte] = useState<string>()

	const [createdAtLte, setCreatedAtLte] = useState<string>()
	const [createdAtGte, setCreatedAtGte] = useState<string>()

	const getUsers = useCallback(async () => {
		const localUsers = await getUsersForSelect()

		if (localUsers) {
			setUsersSelect(localUsers)
		}
	}, [])

	const getContents = useCallback(async () => {
		const localContents = await getAllContentsForSelect()

		if (localContents) {
			setContentSelect(localContents)
		}
	}, [])

	useEffect(() => {
		switch (location.pathname) {
			case '/reports/user-access':
				getUsers()
				break

			case '/reports/engage':
				getUsers()
				getContents()
				break

			default:
				break
		}
	}, [getContents, getUsers, location])

	const generateReport = async (event: React.FormEvent, type: string) => {
		event.preventDefault()

		const queryStrings: any = {
			access_at_gte: dateAccessGte,
			access_at_lte: dateAccessLte,
			user_id_ft: selectedUsers.map((user) => user.value),
			content_id_ft: selectedContents.map((content) => content.value),
			start_at_lte: startAtLte,
			start_at_gte: startAtGte,
			created_at_lte: createdAtLte,
			created_at_gte: createdAtGte
		}

		for (let prop of Object.keys(queryStrings)) {
			if (!queryStrings[prop]) {
				delete queryStrings[prop]
			}
		}

		try {
			switch (location.pathname) {
				case '/reports/user-access':
					await generateLogUserAccessReport(type, queryStrings)
					break

				case '/reports/engage':
					await generateEngagementReport(type, queryStrings)
					break

				case '/reports/users':
					await generateUsersReport(type, queryStrings)
					break

				default:
					break
			}
		} catch (error) {
			Swal.fire({
				title: 'Erro',
				text: 'Houve um erro ao gerar o relatório. ' + error.message,
				icon: 'error',
			})
		}
	}

	const cleanFilters = (event: React.FormEvent) => {
		event.preventDefault()

		setSelectedUsers([])
		setDateAccessGte('')
		setDateAccessLte('')
		setSelectedContents([])
		setStartAtGte('')
		setStartAtLte('')
		setCreatedAtLte('')
		setCreatedAtGte('')
	}

	const getTypeOfReport = useMemo(() => {
		switch (location.pathname) {
			case '/reports/user-access':
				return 'Logs de Acessos dos Usuários'

			case '/reports/engage':
				return 'Engajamento'

			case '/reports/users':
				return 'Relatório de Usuários'

			default:
				return ''
		}
	}, [location])

	return (
		<LogAccessUsersContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Relatórios</span>]} />

			<DefaultPageTitle>{getTypeOfReport}</DefaultPageTitle>

			<DefaultFilterForm>
				{location.pathname === '/reports/user-access' || location.pathname === '/reports/engage' ? (
				<span className="entire-row">
					<label htmlFor="users">Usuário</label>

					<Select
						isMulti
						options={usersSelect}
						value={selectedUsers}
						id="users"
						onChange={(options) => {
							if (options !== null) {
								setSelectedUsers(options.flat())
							}
						}}
						placeholder="Selecione um ou mais usuários"
					/>
				</span>) : null}

				{location.pathname === '/reports/user-access' ? (
					<>
						<span>
							<label htmlFor="date-gte">Data de Acesso Inicial</label>

							<input
								type="date"
								id="date-gte"
								onChange={(e) => setDateAccessGte(e.target.value)}
								value={dateAccessGte}
							/>
						</span>

						<span>
							<label htmlFor="date-lte">Data de Acesso Final</label>

							<input
								type="date"
								id="date-lte"
								onChange={(e) => setDateAccessLte(e.target.value)}
								value={dateAccessLte}
							/>
						</span>
					</>
				) : location.pathname === '/reports/engage' ? (
					<>
						<span className="entire-row">
							<label htmlFor="users">Aulas</label>

							<Select
								isMulti
								options={contentSelect}
								value={selectedContents}
								id="users"
								onChange={(options) => {
									if (options !== null) {
										setSelectedContents(options.flat())
									}
								}}
								placeholder="Selecione um ou mais aulas"
							/>
						</span>

						<span>
							<label htmlFor="start-gte">Data Inicial</label>

							<input
								type="date"
								id="start-gte"
								onChange={(e) => setStartAtGte(e.target.value)}
								value={startAtGte}
							/>
						</span>

						<span>
							<label htmlFor="start-lte">Data Final</label>

							<input
								type="date"
								id="start-lte"
								onChange={(e) => setStartAtLte(e.target.value)}
								value={startAtLte}
							/>
						</span>
					</>
				) : location.pathname === '/reports/users' ? (
					<>
						<span>
							<label htmlFor="create-gte">Data de Criação Inicial</label>

							<input
								type="date"
								id="create-gte"
								onChange={(e) => setCreatedAtGte(e.target.value)}
								value={createdAtGte}
							/>
						</span>

						<span>
							<label htmlFor="create-lte">Data de Criação Final</label>

							<input
								type="date"
								id="create-lte"
								onChange={(e) => setCreatedAtLte(e.target.value)}
								value={createdAtLte}
							/>
						</span>
					</>
				) : null}

				<DefaultCreationFormButtonGroup>
					<DefaultButton className="danger" onClick={(e) => cleanFilters(e)}>
						Limpar Campos
					</DefaultButton>

					<DefaultButton type="submit" className="sucess" onClick={(e) => generateReport(e, 'csv')}>
						Gerar Relatório (CSV)
					</DefaultButton>

					<DefaultButton type="submit" className="sucess" onClick={(e) => generateReport(e, 'pdf')}>
						Gerar Relatório (PDF)
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultFilterForm>
		</LogAccessUsersContainer>
	)
}

export default LogAccessUser
