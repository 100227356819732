import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);

	h2 {
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		font-family: 'Cookie';
		font-weight: 400;
		color: #e46c9a;
		font-size: 68px;
	}

	h3 {
		font-size: 24px;
		text-transform: uppercase;
		color: #7f67da;
		margin-top: 20px;
		font-family: 'Nunito Sans ExtraBold';
	}

	img {
		margin: 80px 0;
		max-width: 250px;
		width: 100%;
	}

	button {
		max-width: 300px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #7f67da;
	}

	@media (max-width: 1366px) {
		h2 {
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			font-family: 'Cookie';
			font-weight: 400;
			color: #e46c9a;
			font-size: 50px;
		}

		img {
			margin: 80px 0;
			max-width: 180px;
			width: 100%;
		}
	}
`

export const ContainerFluid = styled.div`
	@media (max-width: 1078px) {
		display: none;
	}
`
