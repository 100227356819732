import httpClient from "http-client";
import Author from "models/author";
import AuthorForCreate from "models/for-create/authors";
import AuthorForUpdate from "models/for-update/author";
import AuthorFromApi from "models/from-api-response/author";
import { uploadFile } from "./files";

const getAuthor = async (authorId: string) => {
  const author = (await httpClient.get<Author>(`authors/${authorId}`)).data;

  return author;
};

const getAllAuthors = async () => {
  const authors = (await httpClient.get<AuthorFromApi>("authors")).data;

  if (authors.authors && authors.authors.length) {
    return authors;
  }
};

const getAllAuthorsForSelect = async () => {
  const authors = (await httpClient.get<AuthorFromApi>("authors")).data;

  if (authors.authors && authors.authors.length) {
    return authors.authors.map((author) => ({
      label: author.name,
      value: `${author.author_id}`,
    }));
  }
};

const createAuthor = async (newAuthor: AuthorForCreate) => {
  const createAuthorResponse = (
    await httpClient.post<any>(`authors/`, newAuthor)
  ).data;
  return createAuthorResponse;
};

const updateAuthor = async (authorId: string, newInfo: AuthorForUpdate) => {
  await httpClient.put(`authors/${authorId}`, newInfo);
};

const deleteAuthor = async (contentId: string) => {
  await httpClient.delete(`authors/${contentId}`);
};

const changeAuthorThumbnail = async (authorId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`authors/${authorId}/avatar`, {
    avatar_url: uploadedFile.reference,
  });
};

const activateOrInactivateAuthor = async (
  authorId: string,
  activate: boolean
) => {
  await httpClient.put(`authors/${authorId}/active`, { is_active: activate });
};

export {
  getAllAuthors,
  deleteAuthor,
  updateAuthor,
  createAuthor,
  changeAuthorThumbnail,
  getAuthor,
  activateOrInactivateAuthor,
  getAllAuthorsForSelect,
};
