import { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import DefaultCreationForm, {
	DefaultCreationFormGroup,
	DefaultCreationFormButtonGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import DefaultButton from 'components/DefaultButton'
import checkEmptyString from 'helpers/check-empty-string'
import CutImage from 'components/CutImage'

import { ExtraMaterialFormContainer, ThumbnailUploadContainer, ContentThumbnail, ExtraMaterialsInput } from './style'
import { useEffect } from 'react'
import { editExtraMaterial, getExtraMaterial } from 'services/extra-materials'
import { uploadFile } from 'services/files'
import showErrorMessage from 'helpers/show-error-message'
import ModalContext from 'contexts/ModalContext'

interface IExtraMaterialContent {
	addMaterial: (data: any) => void
	closeModal: () => void
	id?: number
}

const inputCheckBoxValues = {
	fileMaterial: 'fileMaterial',
	linkMaterial: 'linkMaterial',
}

const ExtraMaterialContent: React.FC<IExtraMaterialContent> = ({ addMaterial, closeModal, id }) => {
	const { openModal, hideModal } = useContext(ModalContext)
	const [extraMaterialEdit, setExtraMaterialEdit] = useState<any>()
	const [name, setName] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [link, setLink] = useState<string>('')

	const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
	const [thumbnail, setThumbnail] = useState('')

	const [fileToUpload, setFileToUpload] = useState<File>()

	const [hasExtraMaterialContent, setHasExtraMaterialContent] = useState(false)

	const [checkBoxValue, setCheckBoxValue] = useState<string>(inputCheckBoxValues.fileMaterial)

	useEffect(() => {
		const getData = async (id: any) => {
			const extraMaterial = await getExtraMaterial(id)
			setExtraMaterialEdit(extraMaterial)
		}
		if (id) {
			getData(id)
		}
	}, [id])

	useEffect(() => {
		setName(extraMaterialEdit?.name)
		setDescription(extraMaterialEdit?.info?.description)
		setThumbnail(extraMaterialEdit?.thumbnail)
		setLink(extraMaterialEdit?.reference)
		setHasExtraMaterialContent(true)
	}, [extraMaterialEdit])

	const uploadMaterialFile = async (materialFile: File): Promise<string> => {
		const formData = new FormData()
		formData.append('file', materialFile)

		const reference = await uploadFile(formData)

		return reference.reference
	}

	const selectThumbnail = () => {
		openModal('Selecionar Imagem de Capa', <CutImage aspect={1.812} onCutImage={onCutImage} />)
	}

	const onCutImage = (file: File) => {
		if (file) {
			setThumbnailFileToUpload(file)

			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setThumbnail(`${reader.result}`)

			hideModal()
		}
	}

	const addExtraMaterial = (event: any) => {
		event.preventDefault()

		if (event) {
			let file = event.target.files[0]
			file.alreadySaved = false

			setFileToUpload(file)
			setHasExtraMaterialContent(true)
		}
	}

	const onLinkInputChange = (event: any) => {
		if (event.target.value !== '') {
			setLink(event.target.value)
			setHasExtraMaterialContent(true)
		}
	}

	const onFileInputChange = (event: any) => {
		addExtraMaterial(event)
		setHasExtraMaterialContent(true)
	}

	const handleAddExtraMaterial = (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(name) || !hasExtraMaterialContent) {
				throw new Error('Erro ao salvar o Material de Apoio. Verifique os campos obrigatórios.')
			}

			if (link && !checkEmptyString(link)) {
				if (!['http', 'https'].includes(link.split('://')[0])) {
					throw new Error('Insira um link válido (iniciando com http:// ou https://)')
				}
			}

			const form = {
				filename: name,
				info: {
					description: checkEmptyString(description) ? undefined : description,
				},
				thumbnail: thumbnailFileToUpload,
				reference: fileToUpload || link,
			}

			addMaterial(form)
			setHasExtraMaterialContent(false)
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const handleEditExtraMaterial = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(name) || !hasExtraMaterialContent) {
				throw new Error('Erro ao salvar o Material de Apoio. Verifique os campos obrigatórios.')
			}

			if (link && !checkEmptyString(link)) {
				if (!['http', 'https'].includes(link.split('://')[0])) {
					throw new Error('Insira um link válido (iniciando com http:// ou https://)')
				}
			}

			if (thumbnailFileToUpload) {
				const thumbnail = thumbnailFileToUpload ? await uploadMaterialFile(thumbnailFileToUpload) : undefined
				const form = {
					filename: name,
					info: {
						description: checkEmptyString(description) ? undefined : description,
					},
					thumbnail: thumbnail,
					reference: fileToUpload || link,
				}

				editExtraMaterial(extraMaterialEdit?.extra_material_id, form)
				setHasExtraMaterialContent(false)
				closeModal()
			} else {
				const form = {
					filename: name,
					info: {
						description: checkEmptyString(description) ? undefined : description,
					},
					thumbnail: thumbnailFileToUpload,
					reference: fileToUpload || link,
				}

				editExtraMaterial(extraMaterialEdit?.extra_material_id, form)
				setHasExtraMaterialContent(false)
				closeModal()
			}
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const showDefaultsInputs = () => {
		if (checkBoxValue === inputCheckBoxValues.fileMaterial)
			return <ExtraMaterialsInput type="file" onChange={(e) => onFileInputChange(e)} />
		if (checkBoxValue === inputCheckBoxValues.linkMaterial)
			return <DefaultInput required id="link-material" value={link} onChange={(e) => onLinkInputChange(e)} />
		return null
	}

	return (
		<ExtraMaterialFormContainer>
			<DefaultCreationForm style={{ marginTop: '30px' }}>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="name-material">
						Tipo do Material de Apoio
					</label>
					<p style={{ color: 'grey', fontSize: '14px', marginBottom: '12px' }}>
						Ex: BLOG, EBOOK ou o nome da rede social{' '}
					</p>
					<DefaultInput required id="name-material" value={name} onChange={(e) => setName(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="description-material">Descrição</label>
					<DefaultInput
						required
						id="description-material"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="reference">Capa</label>

					<ThumbnailUploadContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem de Capa
						</DefaultButton>

						{thumbnail && <ContentThumbnail src={thumbnail} />}
					</ThumbnailUploadContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="reference" className="required">
						Material de Apoio
					</label>

					<div className="input-radios">
						<input
							type="radio"
							value={inputCheckBoxValues.fileMaterial}
							checked={checkBoxValue === inputCheckBoxValues.fileMaterial}
							name="fileMaterial"
							onChange={(event) => setCheckBoxValue(event.target.value)}
							className="input-radios-check-file"
						/>
						Upload
						<input
							type="radio"
							value={inputCheckBoxValues.linkMaterial}
							checked={checkBoxValue === inputCheckBoxValues.linkMaterial}
							name="fileMaterial"
							onChange={(event) => setCheckBoxValue(event.target.value)}
							className="input-radios-check"
						/>
						Link
					</div>

					{showDefaultsInputs()}
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={closeModal}>
						Cancelar
					</DefaultButton>
					{id ? (
						<DefaultButton className="success" onClick={(event) => handleEditExtraMaterial(event)}>
							Salvar
						</DefaultButton>
					) : (
						<DefaultButton className="success" onClick={(event) => handleAddExtraMaterial(event)}>
							Adicionar
						</DefaultButton>
					)}
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</ExtraMaterialFormContainer>
	)
}

const extraMaterialShow = (content: React.ReactElement) => {
	const modal = document.createElement('div')
	modal.id = 'global-modal-material'

	document.body.appendChild(modal)

	ReactDOM.render(<>{content}</>, document.getElementById('global-modal-material'))
}

const hideExtraMaterial = () => {
	const modal = document.getElementById('global-modal-material')
	if (modal) {
		modal.remove()
	}
}

export { hideExtraMaterial, extraMaterialShow, ExtraMaterialContent }
