import ModalContext from 'contexts/ModalContext'
import { ModalContentContainer, ModalHeader, ModalTitle, ModalCloseButton, ModalContent } from 'helpers/modal/style'
import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr'
import { v4 as uuidv4 } from 'uuid'

const ModalContextProvider: React.FC = ({ children }) => {
	const [modals, setModals] = useState([] as JSX.Element[])

	const openModal = (title: string, content: JSX.Element) => {
		const modalId = `global-modal_${uuidv4()}`

		const contentWithId = React.cloneElement(content, { modalId })

		const modal = (
			<ModalContentContainer>
				<ModalHeader>
					<ModalTitle>{title}</ModalTitle>
					<ModalCloseButton type="button" onClick={() => hideModal(modalId)}>
						<GrFormClose />
					</ModalCloseButton>
				</ModalHeader>
				<ModalContent>{contentWithId}</ModalContent>
			</ModalContentContainer>
		)

		setModals((modals) => [...(modals || []), modal])
	}

	const hideModal = (modalId?: string) => {
		if (modalId) {
			setModals((modals || []).filter((modal) => modal.props.modalId !== modalId))
		} else {
			setModals([])
		}
	}

	return <ModalContext.Provider value={{ openModal, hideModal, modals }}>{children}</ModalContext.Provider>
}

export default ModalContextProvider
