import httpClient from 'http-client'
import ExtraMaterialCreate from 'models/for-create/extra-material'
import ExtraMaterialFromResponse from 'models/from-api-response/extra-material'

const createLessonExtraMaterials = async (lessonId: string, extra_materials: ExtraMaterialCreate[]) => {
	for (let extraMaterial of extra_materials) {
		if (!extraMaterial.thumbnail) {
			delete extraMaterial.thumbnail
		}
	}
	return (
		await httpClient.post<ExtraMaterialFromResponse>(`/extra-materials/${lessonId}`, {
			extra_materials,
		})
	).data
}

const editExtraMaterial = async (extraMaterialId: string, extraMaterial: any) => {
	await httpClient.put(`/extra-materials/${extraMaterialId}`, 
		extraMaterial
	)
}

const getExtraMaterialsForLesson = async (lessonId: string) => {
	const extraMaterials = (await httpClient.get<ExtraMaterialFromResponse>(`/extra-materials/${lessonId}`)).data

	if (extraMaterials) {
		return extraMaterials.extra_material
	}

	return []
}
const getExtraMaterial = async (extraMaterialId: string) => {
	const extraMaterials = (await httpClient.get<any>(`/extra-materials/${extraMaterialId}/get`)).data

	if (extraMaterials) {
		return extraMaterials
	}

	return []
}


const deleteExtraMaterial = async (extraMaterialId: string) => {
	await httpClient.delete(`/extra-materials/${extraMaterialId}`)
}

const changeExtraMaterialThumbnail = async (extraMaterialId: string, thumbnail: string) => {
	await httpClient.patch(`/extra-materials/${extraMaterialId}/thumbnail`, {
		thumbnail: thumbnail,
	})
}

export { createLessonExtraMaterials, getExtraMaterialsForLesson, getExtraMaterial, deleteExtraMaterial, changeExtraMaterialThumbnail, editExtraMaterial }
