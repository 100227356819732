import LiveFromResponse from '../../models/from-api-response/live'
import Live from '../../models/live'

interface ConvertedToLive extends Live {
	status: string
}

const convertToLive = (live: LiveFromResponse): ConvertedToLive => {
	return {
		live_id: live.live_id,
		name: live.name,
		description: live.description,
		status: live.status,
		task_status: live.task_status,
		transmission_id: live.transmission_id,
		images: {
			thumbnail_url: live.thumbnail_url,
		},
		start: new Date(live.start),
		stop: new Date(live.stop),
		rtmp: live.rtmp || '',
	} as ConvertedToLive
}

export { convertToLive }
