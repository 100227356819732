import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import DefaultButton from 'components/DefaultButton'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'
import MediaFromResponse from 'models/from-api-response/media'
import {
	changeContentThumbnail as changeContentThumbnailService,
	createContent as createContentService,
	deleteContent,
	getContent as getContentService,
	updateContent as updateContentService,
} from 'services/contents'
import CutImage from 'components/CutImage'
import checkEmptyString from 'helpers/check-empty-string'
import {
	ContentThumbnail,
	ContentUploadOrSelectContainer,
	CreateAndEditContentContainer,
	ThumbnailUploadContainer,
	ButtonGroupInline,
} from './style'
import showErrorMessage from '../../../helpers/show-error-message'
import { VIDEOS, PODCASTS } from '../../../components/Routes/Constants'
import DefaultTextArea from 'components/DefaultTextArea'
import Select from 'react-select'
import { getAllCategories as getCategoriesService } from 'services/categories'
import { getTags as getTagsService } from 'services/tags'
import TagFromResponse from 'models/from-api-response/tag'
import ModalContext from 'contexts/ModalContext'
import AppContext from 'contexts/AppContext'

interface CreateAndEditContentParams {
	contentId: string
}

interface CreateAndEditContentProps {
	type: 'VIDEO' | 'PODCAST'
}

const CreateAndEditContent: React.FC<CreateAndEditContentProps> = ({ type }) => {
	const { contentId } = useParams<CreateAndEditContentParams>()

	const history = useHistory()
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [reference, setReference] = useState('')
	const [categories, setCategories] = useState([] as { value: string; label: string }[])
	const [selectedCategory, setSelectedCategory] = useState('')
	const [tags, setTags] = useState([] as TagFromResponse[])
	const [selectedTags, setSelectedTags] = useState<string[]>([])
	const { openModal, hideModal } = useContext(ModalContext)

	const [duration, setDuration] = useState(0)

	const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
	const [thumbnail, setThumbnail] = useState('')
	const { environmentVariables } = useContext(AppContext)

	const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
		if (sambaVideosContent) {
			if (sambaVideosContent.files && sambaVideosContent.files.length) {
				const firstFile = sambaVideosContent.files[0]

				if (firstFile) {
					const playerKey = environmentVariables.playerKey
					const referenceUrl = `${environmentVariables.playerInitialUrl}/${playerKey}/${sambaVideosContent.id}`

					setReference(referenceUrl)
					setDuration(firstFile.fileInfo.duration / 1000)
				}
			}
		}

		hideModal()
	}

	const validate = () => {
		if (checkEmptyString(title)) {
			throw new Error(`Informe um título valido para o ${type === 'VIDEO' ? 'vídeo' : 'podcast'}.`)
		}

		if (!selectedCategory) {
			throw new Error(`Informe uma categoria para o ${type === 'VIDEO' ? 'vídeo' : 'podcast'}.`)
		}

		if (!selectedTags?.length) {
			throw new Error(`Informe ao menos uma tag para o ${type === 'VIDEO' ? 'vídeo' : 'podcast'}.`)
		}

		if (!reference) {
			throw new Error('Selecione um conteúdo antes.')
		}
	}

	const createContent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			validate()

			const createdContent = await createContentService({
				title: title,
				duration: duration,
				reference: reference,
				type: type,
				is_library: true,
				info: { description },
				category_id: selectedCategory,
				tags: selectedTags?.length
					? selectedTags.map((tag) => ({
						tag_id: tag,
					}))
					: undefined,
			})

			if (thumbnailFileToUpload) {
				try {
					await changeContentThumbnail(thumbnailFileToUpload, createdContent.content_id)
				} catch (error) {
					await deleteContent(createdContent.content_id)

					throw new Error(
						'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			Swal.fire({
				title: 'Sucesso!',
				text: `${type === 'VIDEO' ? 'vídeo' : 'podcast'} criado com sucesso!`,
				icon: 'success',
			})

			goToContents()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const updateContent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			validate()

			await updateContentService(contentId, {
				title: title,
				duration: duration,
				reference: reference,
				type: type,
				is_library: true,
				info: { description },
				category_id: selectedCategory,
				tags: selectedTags?.length
					? selectedTags.map((tag) => ({
						tag_id: tag,
					}))
					: undefined,
			})

			if (thumbnailFileToUpload) {
				try {
					await changeContentThumbnail(thumbnailFileToUpload, contentId)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			Swal.fire({
				title: 'Sucesso!',
				text: `${type === 'VIDEO' ? 'vídeo' : 'podcast'} editado com sucesso!`,
				icon: 'success',
			})

			goToContents()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const changeContentThumbnail = async (localThumbnailFileToUpload: File, localContentId: string) => {
		const formData = new FormData()
		formData.append('file', localThumbnailFileToUpload)
		formData.append('name', `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`)
		formData.append('description', `thumbnail do conteúdo de id ${localContentId}`)

		await changeContentThumbnailService(localContentId, formData)
	}

	const selectContent = () => {
		openModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />)
	}

	const selectThumbnail = () => {
		openModal('Selecionar Imagem de Capa', <CutImage aspect={290 / 160} onCutImage={onCutImage} />)
	}

	const onCutImage = (file: File) => {
		if (file) {
			setThumbnailFileToUpload(file)

			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setThumbnail(`${reader.result}`)

			hideModal()
		}
	}

	const goToContents = () => {
		history.push(type === 'VIDEO' ? VIDEOS : PODCASTS)
	}

	const getContent = useCallback(async () => {
		if (contentId) {
			const content = await getContentService(contentId)
			if (content && Object.keys(content).length) {
				setTitle(content.title)
				setReference(content.reference)
				setDuration(content.duration)
				setThumbnail(content.thumbnail)
				setDescription(content?.info?.description)
				setSelectedCategory(
					content?.category_id ||
					(content?.categories || [])
						.filter((cat: any) => cat?.category_id)
						?.map((cat: any) => cat?.category_id)[0]
				)
				setSelectedTags(content.tags?.map((tag: { tag_id: string }) => tag.tag_id))
			}
		}
	}, [contentId, setTitle, setReference, setDuration, setThumbnail, setDescription, setSelectedCategory])

	const getCategories = useCallback(async () => {
		const categories = await getCategoriesService()
		setCategories(categories?.map((cat) => ({ value: cat?.category_id, label: cat?.title })))
	}, [setCategories])

	const getTags = useCallback(async () => {
		const tags = await getTagsService()
		setTags(tags)
	}, [setTags])

	useEffect(() => {
		getCategories()
	}, [getCategories])

	useEffect(() => {
		getTags()
	}, [getTags])

	useEffect(() => {
		getContent()
	}, [getContent])

	const isEditting = useMemo(() => {
		if (contentId) {
			return true
		}

		return false
	}, [contentId])

	const tagsForSelect = useMemo(() => {
		return tags?.map((tag) => ({ value: tag.tag_id, label: tag.tag }))
	}, [tags])

	return (
		<CreateAndEditContentContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/profile">Perfil</Link>,
					<Link to="/contents">{type === 'VIDEO' ? 'Vídeos' : 'Podcasts'}</Link>,
					<span>
						{isEditting ? 'Editar' : 'Criar'} {type === 'VIDEO' ? 'Vídeo' : 'Podcast'}
					</span>,
				]}
			/>

			<DefaultPageTitle>
				{isEditting ? 'Editar' : 'Criar'} {type === 'VIDEO' ? 'Vídeo' : 'Podcast'}
			</DefaultPageTitle>

			<DefaultCreationForm>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="title">
						Título
					</label>
					<DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="description">Descrição</label>
					<DefaultTextArea
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						id="description"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="reference">Capa</label>
					<span className="observation-img">
						<li>Recomendado: 290×160</li>
					</span>
					<ThumbnailUploadContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem de Capa
						</DefaultButton>

						{thumbnail && <ContentThumbnail src={thumbnail} />}
					</ThumbnailUploadContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="categories" className="required">
						Categoria
					</label>

					<Select
						options={categories}
						value={categories?.find((cat) => cat?.value === selectedCategory)}
						onChange={(opt) => opt && setSelectedCategory(opt.value)}
						styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="tags" className="required">
						Tags
					</label>

					<Select
						isMulti
						options={tagsForSelect}
						value={tagsForSelect?.filter((tag) => selectedTags.includes(tag.value))}
						onChange={(opts) => opts && setSelectedTags(opts?.map((opt) => opt.value))}
						styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="reference">
						Conteúdo
					</label>
					<ContentUploadOrSelectContainer>
						<ButtonGroupInline>
							<DefaultButton type="button" onClick={selectContent}>
								Selecionar Conteúdo
							</DefaultButton>
						</ButtonGroupInline>
						{reference && (
							<iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
						)}
					</ContentUploadOrSelectContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToContents}>
						Cancelar
					</DefaultButton>
					<DefaultButton
						onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
						className="success"
					>
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditContentContainer>
	)
}

export default CreateAndEditContent
