import HomePage from 'pages/ForGestante/Home'
import Profile from 'pages/ForGestante/Profile'
import Products from 'pages/ForGestante/Products'
import {
	BANNERS,
	CONTENTS,
	COURSES,
	CREATE_CONTENTS,
	CREATE_FAQS,
	CREATE_PRODUCT,
	CREATE_PROFESSIONALS,
	EDIT_CONTENTS,
	EDIT_COURSES,
	EDIT_FAQS,
	EDIT_PRODUCT_BASE_ID,
	EDIT_PROFESSIONALS,
	ERROR_404,
	FAQS,
	HOME,
	HOME_INFO_CREATE,
	HOME_INFO_EDIT,
	PRIVATE_POLITICS,
	PRODUCTS,
	PROFESSIONALS,
	PROFILE,
	REPORTS_LOGS_ENGAGE,
	REPORTS_LOGS_USERS,
	REPORTS_USERS,
	TERMS_OF_USE,
	USERS,
	USERS_BY_ID,
	WELCOME_VIDEO,
} from '.'
import { IRoutes } from '../routes'
import CreateAndEditProduct from 'pages/ForGestante/Products/CreateAndEdit'
import Authors from 'pages/ForGestante/Authors'
import CreateAndEditAuthor from 'pages/ForGestante/CreateAndEditAuthors'
import Contents from 'pages/ForGestante/Contents'
import CreateAndEditContent from 'pages/ForGestante/CreateAndEditContent'
import Courses from 'pages/ForGestante/Courses'
import EditCourse from 'pages/ForGestante/EditCourse'
import HomeInfoPage from 'pages/ForGestante/CMS'
import FAQs from 'pages/ForGestante/CMS/FAQ'
import CreateAndEditFAQS from 'pages/ForGestante/CreateAndEditFAQS'
import CreateAndEditHomeInfo from 'pages/ForGestante/CreateAndEditHomeInfo'
import Reports from 'pages/ForGestante/Reports'
import Users from 'pages/ForGestante/Users'
import Error404 from 'pages/Error404'

const gestanteRoutes = [
	{
		path: HOME,
		name: 'Página Principal',
		component: HomePage,
		exact: true,
		isProtected: true,
	},
	{
		path: PROFILE,
		name: 'Perfil',
		component: Profile,
		exact: true,
		isProtected: true,
	},
	{
		path: PRODUCTS,
		name: 'Produtos',
		component: Products,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_PRODUCT,
		name: 'Criar produto',
		component: CreateAndEditProduct,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_PRODUCT_BASE_ID,
		name: 'Editar produto',
		component: CreateAndEditProduct,
		exact: true,
		isProtected: true,
	},
	{
		path: PROFESSIONALS,
		name: 'Autores',
		component: Authors,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_PROFESSIONALS,
		name: 'Criar Autores',
		component: CreateAndEditAuthor,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_PROFESSIONALS,
		name: 'Profile',
		component: CreateAndEditAuthor,
		exact: true,
		isProtected: true,
	},
	{
		path: CONTENTS,
		name: 'Conteúdos',
		component: Contents,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_CONTENTS,
		name: 'Criar Conteúdos',
		component: CreateAndEditContent,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_CONTENTS,
		name: 'Editar Conteúdos',
		component: CreateAndEditContent,
		exact: true,
		isProtected: true,
	},
	{
		path: COURSES,
		name: 'Cursos',
		component: Courses,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_COURSES,
		name: 'Editar Cursos',
		component: EditCourse,
		exact: true,
		isProtected: true,
	},
	{
		path: TERMS_OF_USE,
		name: 'Termos de Uso',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: PRIVATE_POLITICS,
		name: 'Políticas de Privacidade',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: WELCOME_VIDEO,
		name: 'Vídeo introdutório',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: BANNERS,
		name: 'Banners Home',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: FAQS,
		name: 'FAQS',
		component: FAQs,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_FAQS,
		name: 'Create FAQS',
		component: CreateAndEditFAQS,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_FAQS,
		name: 'Edit FAQS',
		component: CreateAndEditFAQS,
		exact: true,
		isProtected: true,
	},
	{
		path: HOME_INFO_CREATE,
		name: 'Criar Info',
		component: CreateAndEditHomeInfo,
		exact: true,
		isProtected: true,
	},
	{
		path: HOME_INFO_EDIT,
		name: 'Editar Info',
		component: CreateAndEditHomeInfo,
		exact: true,
		isProtected: true,
	},
	{
		path: REPORTS_LOGS_USERS,
		name: 'Log de Acesso usuários',
		component: Reports,
		exact: true,
		isProtected: true,
	},
	{
		path: REPORTS_LOGS_ENGAGE,
		name: 'Engajamento',
		component: Reports,
		exact: true,
		isProtected: true,
	},
	{
		path: REPORTS_USERS,
		name: 'Relatório de Usuários',
		component: Reports,
		exact: true,
		isProtected: true,
	},
	{
		path: USERS,
		name: 'Lista de usuários',
		component: Users,
		exact: true,
		isProtected: true,
	},
	{
		path: USERS_BY_ID,
		name: 'Lista de usuários',
		component: Users,
		exact: true,
		isProtected: true,
	},
	{
		path: ERROR_404,
		name: 'Página de erro 404',
		component: Error404,
		exact: true,
	},
] as IRoutes[]

export default gestanteRoutes
