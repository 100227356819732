import React, { useEffect, useState } from 'react'
import getActualUser from 'helpers/get-actual-user'
import User from 'models/user'
import { DefaultPageTitle, BreadCrumb } from 'components'
import { ProfileContainer } from './style'

const Profile: React.FC = () => {
	const [user, setUser] = useState({} as User)

	useEffect(() => {
		;(async () => {
			setUser(await getActualUser())
		})()
	}, [])

	return (
		<>
			<ProfileContainer>
				<BreadCrumb crumbs={[]} />
				<DefaultPageTitle>Perfil</DefaultPageTitle>
				<div className="container">
					<div className="avatar-profile">
						<img src={user?.info?.avatar} alt="avatar" />
					</div>
					<div className="info">
						<h3>Nome: {user.name}</h3>
						<h3>CPF: {user.cpf}</h3>
						<h3>E-mail: {user.email}</h3>
						<h3>Telefone: {user?.info?.telephone}</h3>
					</div>
				</div>
			</ProfileContainer>
		</>
	)
}

export default Profile
