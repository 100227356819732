import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Swal from 'sweetalert2'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { getFAQS, deleteFAQS } from 'services/faq'
import { useHistory, Link } from 'react-router-dom'
import FAQ from 'models/faq'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import CreateButtonDefaultContainer from 'components/CreateButtonDefaultContainer'
import DefaultButton from 'components/DefaultButton'
import DefaultTable from 'components/DefaultTable'
import { formatDate } from 'helpers/format-date'

import { FAQContainer } from './style'

const FAQS: React.FC = () => {
	const history = useHistory()
	const [faqs, setFaqs] = useState<Array<FAQ>>()

	const getFaqsInfo = async () => {
		const localFaqs = await getFAQS()

		setFaqs(localFaqs)
	}

	useEffect(() => {
		getFaqsInfo()
	}, [])

	const editFAQS = useCallback(
		(faqId: string) => {
			history.push(`edit-faqs/${faqId}`)
		},
		[history]
	)

	const createFAQS = () => {
		history.push('create-faqs')
	}

	const deleteFAQ = useCallback(async (faqId: string) => {
		Swal.fire({
			title: '<strong>Confirmação</strong>',
			html: 'Tem certeza que deseja remover este FAQ?',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			focusConfirm: false,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await deleteFAQS(faqId)

					Swal.fire({
						icon: 'success',
						title: 'Sucesso!',
						text: 'FAQ excluida com sucesso!',
					})

					await getFaqsInfo()
				} catch (error: any) {
					Swal.fire({
						icon: 'error',
						title: 'Erro',
						text: `Erro ao excluir FAQ. ${error.message}`,
					})
				}
			}
		})
	}, [])

	const contentsToBeShown = useMemo(() => {
		return faqs && faqs.length
			? faqs.map((faq) => ({
					question: faq.question,
					date: formatDate(faq.created_at),
					actions: (
						<>
							<DefaultButton
								className="small info"
								title="Editar FAQ"
								onClick={() => editFAQS(faq.faq_id)}
							>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								className="small danger"
								title="Deletar FAQ"
								onClick={() => deleteFAQ(faq.faq_id)}
							>
								<BiTrash />
							</DefaultButton>
						</>
					),
			  }))
			: []
	}, [deleteFAQ, faqs, editFAQS])

	return (
		<FAQContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>FAQ's</span>]} />

			<DefaultPageTitle>FAQ's</DefaultPageTitle>

			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createFAQS}>Criar FAQ'S</DefaultButton>
			</CreateButtonDefaultContainer>

			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Pergunta</span>,
						propName: 'question',
					},
					{
						headerLabel: <span>Data de Criação</span>,
						propName: 'date',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={contentsToBeShown}
				emptyListMessage={'Não foram encontrados FAQS cadastradas!'}
			/>
		</FAQContainer>
	)
}

export default FAQS
