import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { Link, useHistory, useParams } from 'react-router-dom'
import BreadCrumb from 'components/BreadCrumb'
import DefaultButton from 'components/DefaultButton'
import Select from 'react-select'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import { SortLessonsContainer, LessonSelectionContainer, EditCourseContainer } from './style'
import SelectLesson from './components/SelectLesson'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { getCourse as getCourseService, updateCourse as updateCourseService } from 'services/courses'
import Swal from 'sweetalert2'
import checkEmptyString from 'helpers/check-empty-string'
import Content from 'models/content'
import { getAllProductsForSelect } from 'services/products'
import showErrorMessage from 'helpers/show-error-message'
import ModalContext from 'contexts/ModalContext'

interface EditCourseProps {
	courseId: string
}

const EditCourse: React.FC = () => {
	const history = useHistory()
	const [products, setProducts] = useState([] as unknown as { label: string; value: string }[])
	const [selectedProduct, setSelectedProduct] = useState({} as unknown as { label: string; value: string })

	const { courseId } = useParams<EditCourseProps>()

	const [name, setName] = useState('')
	const [selectedLessons, setSelectedLessons] = useState([] as any[])
	const { openModal, hideModal } = useContext(ModalContext)

	const updateCourse = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(name)) {
				throw new Error('Informe um nome valido para o curso.')
			}

			if (!selectedLessons || !selectedLessons.length) {
				throw new Error('Selecione uma ou mais aulas antes.')
			}

			if (!selectedProduct.label) {
				throw new Error('Selecione um produto a ser exibido antes.')
			}

			await updateCourseService(courseId, {
				title: name,
				contents: selectedLessons.map((lesson, index) => ({
					content_id: lesson?.content_id ? lesson?.content_id : lesson?.id,
					position: index + 1,
				})),
				products: [
					{
						product_id: selectedProduct.value,
					},
				],
			})

			Swal.fire({
				title: 'Sucesso!',
				text: 'Curso editado com sucesso!',
				icon: 'success',
			})

			goToCourses()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const selectLessons = () => {
		openModal(
			'Selecionar',
			<SelectLesson onApplySelection={applyLessonsSelection} selectedLessons={[...selectedLessons]} />
		)
	}

	const applyLessonsSelection = (localSelectedLessons: Content[]) => {
		hideModal()

		if (localSelectedLessons) {
			setSelectedLessons(localSelectedLessons)
		}
	}

	const reorderSelectedLessons = (lesson: Content, up: boolean) => {
		if (selectedLessons) {
			const localSelectedLessons = [...selectedLessons]
			const indexOfLesson = localSelectedLessons.indexOf(lesson)
			if (indexOfLesson > -1 && indexOfLesson < localSelectedLessons.length) {
				localSelectedLessons.splice(indexOfLesson, 1)
				if (up) {
					localSelectedLessons.splice(indexOfLesson - 1, 0, lesson)
				} else {
					localSelectedLessons.splice(indexOfLesson + 1, 0, lesson)
				}
			}
			console.log(localSelectedLessons)
			setSelectedLessons(localSelectedLessons)
		}
	}

	const getCourse = useCallback(async () => {
		if (courseId) {
			const course = await getCourseService(courseId)
			const foundProductsSelect = await getAllProductsForSelect()
			if (course && Object.keys(course).length) {
				if (foundProductsSelect) {
					setProducts(foundProductsSelect)
					setName(course.title)
					setSelectedLessons(course.contents ?? [])
					setSelectedProduct({
						label: course.products[0]?.title || foundProductsSelect[0].label,
						value: course.products[0]?.id || foundProductsSelect[0].value,
					})
				}
			}
		}
	}, [courseId])

	const goToCourses = () => {
		history.push('/courses')
	}

	useEffect(() => {
		getCourse()
	}, [getCourse])

	const isEditting = useMemo(() => {
		if (courseId) {
			return true
		}

		return false
	}, [courseId])

	const removeLessonFromArray = (index: number) => {
		const aux = selectedLessons.filter((_, position) => position !== index)

		setSelectedLessons(aux)
	}

	return (
		<EditCourseContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/profile">Perfil</Link>,
					<Link to="/trails/episodes">Cursos</Link>,
					<span>{isEditting ? 'Editar' : 'Criar'} Módulo</span>,
				]}
			/>

			<DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Módulo</DefaultPageTitle>

			<DefaultCreationForm>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="title">
						Titúlo
					</label>
					<DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="title" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="reference">
						Vídeos
					</label>
					<LessonSelectionContainer>
						{selectedLessons && selectedLessons.length ? (
							<SortLessonsContainer>
								{selectedLessons.map((lesson, index) => (
									<div key={lesson.content_id} className="selected-lessons">
										<div className="buttons">
											{index > 0 ? (
												<DefaultButton
													type="button"
													title="Subir Aula"
													className="small white up"
													onClick={() => reorderSelectedLessons(lesson, true)}
												>
													<AiOutlineUp />
												</DefaultButton>
											) : (
												<></>
											)}
											{index < selectedLessons.length - 1 ? (
												<DefaultButton
													type="button"
													title="Descer Aula"
													className="small white down"
													onClick={() => reorderSelectedLessons(lesson, false)}
												>
													<AiOutlineDown />
												</DefaultButton>
											) : (
												<></>
											)}
										</div>
										<div className="lesson-title">
											{index + 1} - {lesson.title}
										</div>
										<DefaultButton
											className="small danger"
											type="button"
											title="Remover Aula"
											onClick={() => removeLessonFromArray(index)}
										>
											<BiTrash />
										</DefaultButton>
									</div>
								))}
							</SortLessonsContainer>
						) : (
							<></>
						)}
						<DefaultButton type="button" onClick={selectLessons}>
							Selecionar
						</DefaultButton>
					</LessonSelectionContainer>
				</DefaultCreationFormGroup>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="title">
						Produto relacionado
					</label>
					<Select
						styles={{
							container: (provided) => ({
								...provided,
								flexGrow: 1,
								width: '100%',
							}),
						}}
						options={products}
						value={selectedProduct}
						onChange={(options) => {
							if (options !== null) {
								setSelectedProduct(options)
							}
						}}
						id="products"
						placeholder="Selecione um produto para este módulo"
					/>
				</DefaultCreationFormGroup>
				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToCourses}>
						Cancelar
					</DefaultButton>
					<DefaultButton onClick={(e) => updateCourse(e)} className="success">
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</EditCourseContainer>
	)
}

export default EditCourse
