import { BreadCrumb, CreateButtonDefaultContainer, DefaultButton, DefaultPageTitle, DefaultTable } from 'components'
import HomeInfo from 'models/home-info'
import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
	getBannerLanding as getBannerLandingService,
	createBannerLanding as createBannerLandingService,
	updateBannerLanding as updateBannerLandingService,
} from 'services/banner-landing'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import showErrorMessage from 'helpers/show-error-message'
import CutImage from 'components/CutImage'
import { uploadFile } from 'services/files'
import Swal from 'sweetalert2'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import ModalContext from 'contexts/ModalContext'
import { editHomeInfo as editHomeInfoService, createHomeInfo as createHomeInfoService, deleteHomeInfo } from 'services/home-info'
import { formatDate } from 'helpers/format-date'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'

const BannersLandingContainer = styled.div``

const BannersList = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
`

const BannersLanding: React.FC = () => {
	let [banners, setBanners] = useState<any | undefined>()
	const [editLandingBanners, setEditLandingBanners] = useState<boolean>(false);
	const { openModal, hideModal } = useContext(ModalContext);
	const history = useHistory()

	const getBanner = useCallback(async () => {
		const banner = await getBannerLandingService()
		setBanners(banner)
	}, [setBanners])


	const cutBanner = () => {
		openModal('Adicionar Banner', <CutImage aspect={2.7} onCutImage={addImage} />)
	}

	const addImage = async (image: File) => {
		hideModal()
		let bannerPath;
		try {
			if (image) {
				try {
					const formData = new FormData()
					formData.append('file', image)
					const { reference } = await uploadFile(formData)
					bannerPath = reference;
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}
			const bannerData = {
				text: 'Banner Landing',
				type: 'landing_medic',
				info: {
					image: bannerPath
				}
			}

			await createHomeInfoService(bannerData);
			getBanner()
			Swal.fire({
				title: 'Sucesso!',
				text: `Banner criado com sucesso!`,
				icon: 'success',
			})

		} catch (error) {
			showErrorMessage(error)
		}

	}


	const removeBanner = useMemo(() => {
		return async (banner: any) => {
			const result = await Swal.fire({
				icon: 'question',
				text: 'Deseja remover este banner?',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			})

			if (result.isConfirmed) {
				try {
					await deleteHomeInfo(banner.home_id)

					Swal.fire({
						icon: 'success',
						title: 'Sucesso!',
						text: `Banner excluido com sucesso!`,
					})
					getBanner()
				} catch (error: any) {
					Swal.fire({
						icon: 'error',
						title: 'Erro',
						text: `Erro ao excluir banner. ${error.message}`,
					})
				}
			}

		}
	}, [getBanner])

	useEffect(() => {
		getBanner()
	}, [getBanner])


	const reorderLandingBanners = useMemo(() => {
		return async (banner: any, up: boolean) => {
			if (banners) {
				setEditLandingBanners(true)
				const localBanners = [...banners]
				const indexOfBanner = banners.indexOf(banner)

				if (indexOfBanner > -1 && indexOfBanner < localBanners.length) {
					localBanners.splice(indexOfBanner, 1)
					if (up) {
						localBanners.splice(indexOfBanner - 1, 0, banner)
					} else {
						localBanners.splice(indexOfBanner + 1, 0, banner)
					}
				}
				setBanners(localBanners)
			}
		}
	}, [banners])

	const saveReorderedLandingBanners = (e: FormEvent) => {
		e.preventDefault();
		banners?.map(async (banner: any, index: number) => {
			const bannerData = {
				text: banner.text,
				info: {
					image: banner.info.image,
					link: banner.info.link
				},
				position: index + 1,
			}
			await editHomeInfoService(bannerData, banner.home_id,);
		})
		Swal.fire({
			icon: 'success',
			title: 'Sucesso!',
			text: 'Reordenação salva com sucesso!',
		})
		setEditLandingBanners(false)
	}

	const editBanner = useCallback(
		(bannerId: string) => {
			history.push(`banners_landing/edit/${bannerId}`)
		},
		[history]
	)

	const createBanner = () => {
		history.push('/banners_landing/create');
	}

	const contentsToBeShown = useMemo(() => {
		return banners && banners.length
			? banners.map((banner: any, index: number) => ({
				preview: (
					<>
						<img src={banner.info.image} alt="banner" />
					</>
				),
				date: formatDate(banner.date),
				actions: (
					<>
						<DefaultButton
							className="small info"
							title={`Editar`}
							onClick={() => editBanner(banner.home_id)}
						>
							<BiEdit />
						</DefaultButton>{' '}
						<DefaultButton
							className="small danger"
							title={`Excluir`}
							onClick={() => removeBanner(banner)}
						>
							<BiTrash />
						</DefaultButton>
						{' '}
						{index > 0 ? (
							<DefaultButton type="button"
								title="Subir Banner"
								className="small white up"
								onClick={() => reorderLandingBanners(banner, true)}>
								<AiOutlineUp />
							</DefaultButton>
						) : (<></>)
						} {' '}
						{index < banners.length - 1 ? (
							<DefaultButton type="button"
								title="Descer Banner"
								className="small white down"
								onClick={() => reorderLandingBanners(banner, false)}>
								<AiOutlineDown />
							</DefaultButton>) : (<></>)}
					</>
				),
			}))
			: []
	}, [banners, editBanner, removeBanner, reorderLandingBanners])



	return (
		<BannersLandingContainer>
			<BreadCrumb
				crumbs={[<Link to="/profile">Perfil</Link>, <span>CMS</span>, <span>Banners Landing Page</span>]}
			/>

			<DefaultPageTitle>Banners Landing Page</DefaultPageTitle>

			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createBanner}>Criar Banner</DefaultButton>

			</CreateButtonDefaultContainer>
			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Thumbnail</span>,
						propName: 'preview',
					},
					{
						headerLabel: <span>Data de Modificação</span>,
						propName: 'date',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={contentsToBeShown}
				emptyListMessage={`Não foram encontrados banners cadastrados!`}
			/>
			<DefaultCreationForm onSubmit={(e) => saveReorderedLandingBanners(e)}>
				{editLandingBanners && (
					<DefaultCreationFormButtonGroup>
						<DefaultButton>Salvar</DefaultButton>
					</DefaultCreationFormButtonGroup>
				)}
			</DefaultCreationForm>
		</BannersLandingContainer>
	)
}

interface BannerLandingCardProps {
	banner: string
	moveBanner: (banner: string, up: boolean) => void
	removeBanner: (banner: string) => void
	bannerImage: string
}

const BannerLandingCardContainer = styled.div<Partial<BannerLandingCardProps>>`
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	width: 100%;
	min-height: 260px;

	div.actions {
		position: absolute;
		top: 0;
		right: 0;
		padding: 8px;
		display: flex;
		align-items: center;
		gap: 4px;
	}

	background-image: url(${(props) => props.bannerImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`


export default BannersLanding
