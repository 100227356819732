import React from 'react'
import gestanteRoutes from './Constants/gestante-routes'
import medicoRoutes from './Constants/medico-routes'

export interface IRoutes {
	path: string
	name: string
	component: React.FC
	exact?: boolean
	isProtected?: boolean
}

const getRoutesByAdminType = (adminType: 'gestante' | 'medico') => {
	if (adminType === 'gestante') {
		return gestanteRoutes
	} else {
		return medicoRoutes
	}
}

export default getRoutesByAdminType
