import httpClient from 'http-client'
import ContactFromApi from 'models/from-api-response/contact'

const getContacts = async () => {
	return (await httpClient.get<ContactFromApi[]>(`/contacts`)).data
}

const getContact = async (contactId: string) => {
	return (await httpClient.get<ContactFromApi>(`/contacts/${contactId}`)).data
}

const createContact = async (newContact: ContactFromApi) => {
	return (await httpClient.post<ContactFromApi>(`/contacts`, newContact)).data
}

const updateContact = async (contactId: string, newContact: ContactFromApi) => {
	return (await httpClient.put<ContactFromApi>(`/contacts/${contactId}`, newContact)).data
}

const deleteContact = async (contactId: string) => {
	return (await httpClient.delete(`/contacts/${contactId}`)).data
}

const toogleContactActive = async (contactId: string, shouldActive: boolean) => {
	return (await httpClient.patch(`/contacts/${contactId}`, { is_active: shouldActive })).data
}

export { getContacts, getContact, createContact, updateContact, deleteContact, toogleContactActive }
