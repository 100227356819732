import React, { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
import Swal from 'sweetalert2'
import MediaFromResponse from '../../models/from-api-response/media'
import { getMedias } from '../../services/medias'
import DefaultButton from '../DefaultButton'
import SambaVideosContentCard from '../SambaVideosContentCard'
import UploadProgressBar from './components/UploadProgressBar'
import {
	ContentList,
	ContentListContainer,
	ContentListFilter,
	ContentListFilters,
	ContentUpload,
	SelectSambaVideosContentContainer,
} from './style'
import SambaUploader from 'samba-uploader'
import AppContext from 'contexts/AppContext'

interface SelectSambaVideosContentProps {
	onSelectContent: (content: MediaFromResponse) => void
}

const SelectSambaVideosContent: React.FC<SelectSambaVideosContentProps> = ({ onSelectContent }) => {
	const uploadButton = useRef(null)

	const numberOfMediasPerPage = 10

	const [loadingMoreMedias, setLoadingMoreMedias] = useState(false)
	const [shouldShowLoadMoreMedias, setShouldShowLoadMoreMedias] = useState(true)
	const [searchText, setSearchText] = useState('')
	const [isUploading, setIsUploading] = useState(false)
	const [completeUpload, setCompleteUpload] = useState(false)
	const [uploadProgress, setUploadProgress] = useState(0)
	const [contents, setContents] = useState([] as MediaFromResponse[])
	const [shouldShowLoading, setShouldShowLoading] = useState(true)
	const [sambaUploader, setSambaUploader] = useState({} as any)
	const [numberOfContentsToBeShown, setNumberOfContentsToBeShown] = useState(1)
	const { environmentVariables } = useContext(AppContext)

	const getSambaVideosContents = async (event?: React.FormEvent) => {
		if (event && event.preventDefault) {
			event.preventDefault()
		}
		setCompleteUpload(false)
		setContents([])
		const localContents = await getMedias(environmentVariables.sambaAccessToken!, environmentVariables.sambaPid!, {
			search: searchText,
			limit: numberOfMediasPerPage,
			types: 'VIDEO',
		})
		if (localContents && localContents.length) {
			setContents(localContents)
			setShouldShowLoading(true)
		} else {
			setShouldShowLoading(false)
		}
	}

	const loadMoreMedias = async () => {
		setLoadingMoreMedias(true)

		const localContents = await getMedias(environmentVariables.sambaAccessToken!, environmentVariables.sambaPid!, {
			search: searchText,
			start: numberOfContentsToBeShown,
			limit: numberOfMediasPerPage,
			types: 'VIDEO',
		})

		if (localContents && localContents.length) {
			const localJoinedContents = [...contents, ...localContents]

			if (localJoinedContents.length % numberOfMediasPerPage > 0) {
				setShouldShowLoadMoreMedias(false)
			}

			setContents(localJoinedContents)
			setShouldShowLoading(true)
		} else {
			setShouldShowLoading(false)
			setShouldShowLoadMoreMedias(false)
		}

		setNumberOfContentsToBeShown(numberOfContentsToBeShown + 1)
		setLoadingMoreMedias(false)
	}

	useEffect(
		() => {
			getSambaVideosContents()
		},
		// eslint-disable-next-line
		[completeUpload]
	)

	useEffect(() => {
		if (uploadButton.current && (!sambaUploader || !Object.keys(sambaUploader).length)) {
			console.log('will upload')
			setSambaUploader(
				new SambaUploader({
					button: uploadButton.current,
					sambaUploadKey: environmentVariables.sambaUploadKey,
					allowedExtensions: ['avi', 'wmv', 'wma', 'mov', 'flv', 'rm', 'rmvb', 'mp4', 'mp3', 'mkv', '3gp'],
				})
			)
			console.log('will upload 2')
		}
	}, [uploadButton, sambaUploader])

	useEffect(() => {
		if (sambaUploader && Object.keys(sambaUploader).length) {
			sambaUploader.on('start', () => {
				console.log('start')
				Swal.fire({
					title: 'Iniciando Upload',
					text: 'Upload de conteúdo iniciado!',
					timer: 2000,
				})
			})

			sambaUploader.on('progress', (event: any) => {
				console.log('progress')
				const progress = (event.uploadedBytes / event.totalBytes) * 100
				if (progress) {
					setUploadProgress(progress)
					setIsUploading(true)
				}
			})

			sambaUploader.on('complete', async (event: any) => {
				console.log('complete')
				Swal.fire({
					title: 'Sucesso!',
					text: 'Upload realizado com sucesso! Aguarde o tempo de encoding que pode durar até 10 min, após isso seu vídeo estará disponível para cadastro.',
					timer: 4000,
				})

				setUploadProgress(0)
				setIsUploading(false)
				setCompleteUpload(true)
			})
		}
	}, [sambaUploader])

	return (
		<SelectSambaVideosContentContainer>
			<ContentUpload ref={uploadButton}>
				<AiOutlineCloudUpload size={128} />
				{!isUploading && <p>Fazer Upload de Conteúdo</p>}
				{isUploading && <p>Aguarde o Upload do Conteúdo</p>}
				{uploadProgress > 0 ? <UploadProgressBar progress={uploadProgress} /> : <></>}
			</ContentUpload>

			<ContentListContainer>
				<ContentListFilters onSubmit={getSambaVideosContents}>
					<ContentListFilter
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						type="search"
					/>
					<DefaultButton type="submit">
						<BsSearch />
					</DefaultButton>
				</ContentListFilters>

				<ContentList>
					{contents && contents.length ? (
						<>
							{contents.map((content) => (
								<SambaVideosContentCard onClick={onSelectContent} key={content.id} content={content} />
							))}
							{shouldShowLoadMoreMedias ? (
								<div className="center">
									{loadingMoreMedias ? (
										<div className="spinner small"></div>
									) : (
										<DefaultButton onClick={loadMoreMedias}>Carregar Mais</DefaultButton>
									)}
								</div>
							) : (
								<></>
							)}
						</>
					) : shouldShowLoading ? (
						<div className="spinner"></div>
					) : (
						<>Nenhum conteúdo encontrado.</>
					)}
				</ContentList>
			</ContentListContainer>
		</SelectSambaVideosContentContainer>
	)
}

export default SelectSambaVideosContent
