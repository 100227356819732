import { DefaultButton } from 'components'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import showErrorMessage from 'helpers/show-error-message'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
	updateEmail as updateEmailService,
	getEmail as getEmailService,
	createEmail as createEmailService,
} from 'services/contact/email'
import HomeInfo from 'models/home-info'

const EmailContainer = styled.div``

const Email: React.FC = () => {
	let [email, setEmail] = useState<HomeInfo | undefined>(undefined)
	const [emailHasBeenCreated, setEmailHasBeenCreated] = useState(false)

	const updateEmail = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (!email?.info?.subtitle) {
				throw new Error('Informe um email válido!')
			}

			if (emailHasBeenCreated) {
				await updateEmailService(email)
			} else {
				await createEmailService(email.info)
			}
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const getEmail = async () => {
		const email = await getEmailService()

		setEmailHasBeenCreated(!!email)

		setEmail(email)
	}

	useEffect(() => {
		getEmail()
	}, [])

	return (
		<EmailContainer>
			<DefaultCreationForm onSubmit={updateEmail}>
				<DefaultCreationFormGroup>
					<label htmlFor="text">Email</label>
					<DefaultInput
						required
						type="email"
						value={email?.info?.subtitle || ''}
						onChange={(e) => {
							if (!email) {
								email = {} as HomeInfo
							}

							if (!email.info) {
								email.info = {}
							}

							email.info.subtitle = e.target.value

							setEmail((email) => ({ ...(email as HomeInfo) }))
						}}
					/>
				</DefaultCreationFormGroup>
				<DefaultCreationFormButtonGroup>
					<DefaultButton type="submit" className="primary">
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</EmailContainer>
	)
}

export default Email
