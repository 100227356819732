import DefaultButton from 'components/DefaultButton'
import React, { useEffect, useMemo, useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { useNumberOfPages, DOTS } from './hooks/useNumberOfPages'
import { DefaultTableContainer, DefaultTablePagination, Table, Tbody, Td, Th, Thead, Tr } from './style'

export interface HeaderConfig {
    propName: string
    headerLabel: React.ReactElement
    noWrap?: boolean
    attributes?: React.StyleHTMLAttributes<HTMLElement>
}

interface DefatultTableProps {
    headersConfig: HeaderConfig[]
    items: any[]
    emptyListMessage?: string
    itemsPerPage?: number
    usePagination?: boolean
}

const DefaultTable: React.FC<DefatultTableProps> = ({
    headersConfig,
    items: initialItems,
    emptyListMessage,
    itemsPerPage: initialItemsPerPage,
    usePagination: initialUsePagination,
}) => {

const [items, setItems] = useState([] as any[])
const [itemsPerPage, setItemsPerPage] = useState(15)
const [currentPage, setCurrentPage] = useState(0)
const [usePagination, setUsePagination] = useState(true)
const [lastPageNumber, setLastPageNumber] = useState<number>(0)

let totalCount = items.length
const paginationRange = useNumberOfPages({
    currentPage,
    totalCount,
    pageSize: itemsPerPage,
})

const itemsToShow = useMemo(() => {
    return usePagination
      ? [...items].splice(currentPage * itemsPerPage, itemsPerPage)
      : items;
}, [items, itemsPerPage, currentPage])

const numberOfPages = useMemo(() => {
    return Math.ceil(items.length / itemsPerPage);
}, [items, itemsPerPage, currentPage])

const changeCurrentPage = (newPage: any) => {
    setCurrentPage(newPage -1)
}

useEffect(() => {
    setCurrentPage(0)
}, [headersConfig])

useEffect(() => {
    setItems(initialItems)
}, [initialItems])

useEffect(() => {
    if (initialUsePagination !== undefined) {
      setUsePagination(initialUsePagination)
    }
}, [initialUsePagination])

useEffect(() => {
    if (initialItemsPerPage) {
      setItemsPerPage(initialItemsPerPage);
    }

	if (paginationRange !== undefined && paginationRange.length) {
		const sizePagination = paginationRange.length
		const lastPage = paginationRange[sizePagination -1] as number
		setLastPageNumber(lastPage)
	}
}, [initialItemsPerPage, paginationRange])

return (
	<DefaultTableContainer>
		<Table>
			<Thead>
				<Tr>
					{headersConfig && headersConfig.length ? (
						headersConfig.map((headerConfig, index) => (
							<Th
								style={{
									...headerConfig.attributes?.style,
								}}
								key={index}
							>
								{headerConfig.headerLabel}
							</Th>
						))
					) : (
							<></>
						)}
				</Tr>
			</Thead>
			<Tbody>
				{itemsToShow && itemsToShow.length ? (
					itemsToShow.map((item, index) => (
						<Tr key={index}>
							{item && Object.keys(item).length && headersConfig && headersConfig.length ? (
								headersConfig.map((headerConfig, index) => (
									<Td
										key={index}
										style={{
											whiteSpace: headerConfig.noWrap ? 'nowrap' : 'normal',
										}}
									>
										{(() => {
											switch (typeof item[headerConfig.propName]) {
												case 'boolean':
													return `${item[headerConfig.propName]}`
												default:
													return item[headerConfig.propName]
											}
										})()}
									</Td>
								))
							) : (
									<></>
								)}
						</Tr>
					))
				) : (
						<Tr>
							<Td style={{ textAlign: 'center' }} colSpan={headersConfig.length}>
								{emptyListMessage || 'Não foram fornecidos itens para a lista'}
							</Td>
						</Tr>
					)}
			</Tbody>
		</Table>
	{
		usePagination && numberOfPages > 1 && (
			<DefaultTablePagination>
				<DefaultButton
					className="medium white"
					onClick={() => changeCurrentPage(currentPage)}
					disabled={currentPage <= 0}
				>
					<AiOutlineLeft size={18} />
				</DefaultButton>
				{paginationRange?.length && paginationRange
					.map((pageNumber, index) => {

						return (
							(
								<DefaultButton
									key={index}
									className="white medium"
									onClick={() => pageNumber !== DOTS && changeCurrentPage(pageNumber)}
									style={
										currentPage +1 === pageNumber
											? { background: 'var(--primary-color)', color: 'white' }
											: {}
									}
									disabled={pageNumber > numberOfPages}
								>
									{pageNumber}
								</DefaultButton>
							)
						)
					})}
				<DefaultButton
					className="medium white"
					onClick={() => changeCurrentPage(currentPage + 2)}
					disabled={(currentPage + 1) >= lastPageNumber}
				>
					<AiOutlineRight size={18} />
				</DefaultButton>
			</DefaultTablePagination>
			)
		}
	</DefaultTableContainer>
    )
}

export default DefaultTable
