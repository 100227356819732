import httpClient from '../http-client/index'

export interface IOperationResult {
	successMessage?: string
	errorMessage?: string
	token?: string | undefined
	error?: string
}

const login = async (email: string, password: string, isMedic = false): Promise<IOperationResult> => {
	const response = await httpClient.post('/cognito/login', {
		email,
		password,
		is_medic: isMedic,
	})

	const hasAdminRole = response.data.user.roles.filter((role: string) => role === 'Admin')

	if (hasAdminRole && hasAdminRole.length) {
		window.localStorage.setItem('exeltis-api-token-admin', response.data.api_token)
	} else {
		throw new Error('Usuário não possuí permissão para acessar a plataforma.')
	}

	let { api_token: token } = response.data
	return token
}

export { login }
