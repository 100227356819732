import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import { Link, useHistory, useParams } from 'react-router-dom'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import DefaultButton from 'components/DefaultButton'
import {
	editHomeInfo as editHomeInfoService,
	createHomeInfo as createHomeInfoService,
	getHomeInfoById as getHomeInfoByIdService,
} from 'services/home-info'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'
import MediaFromResponse from 'models/from-api-response/media'
import checkEmptyString from 'helpers/check-empty-string'
import HomeInfoCreate from 'models/for-create/home-info'
import HomeInfoForUpdate from 'models/for-update/home-info'
import CutImage from 'components/CutImage'
import { uploadFile } from 'services/files'
import { EditorState } from 'draft-js'
import { DescriptionTextarea, getCleanHtml } from 'components/Editor'

import {
	CreateAndEditHomeInfoContainer,
	ButtonGroupInline,
	ContentUploadOrSelectContainer,
	BannerUploadContainer,
	ContentBanner,
} from './style'
import showErrorMessage from 'helpers/show-error-message'
import ModalContext from 'contexts/ModalContext'
import AppContext from 'contexts/AppContext'

interface CreateAndEditHomeInfoProps {
	infoId: string
	type: string
}

const CreateAndEditHomeInfo: React.FC = () => {
	const history = useHistory()
	const { infoId, type } = useParams<CreateAndEditHomeInfoProps>()
	const [title, setTitle] = useState<string>('')
	const [text, setText] = useState<string>('')
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [reference, setReference] = useState<string>('')
	const [banner, setBanner] = useState<string>('')
	const [link, setLink] = useState<any>()
	const [bannerFileToUpload, setBannerFileToUpload] = useState<File>()
	const { openModal, hideModal } = useContext(ModalContext)
	const { environmentVariables } = useContext(AppContext)

	const returnTypeForFunction = useMemo(() => {
		switch (type) {
			case 'terms':
				return 'TERM_MEDIC'

			case 'banners':
				return 'home_medic'

			case 'politic':
				return 'POLITIC_MEDIC'

			case 'welcome_video':
				return 'WELCOME_MEDIC'

			default:
				return ''
		}
	}, [type])

	const createHomeInfo = async (e: React.FormEvent) => {
		e.preventDefault()

		const cleanHTML = getCleanHtml(editorState)

		try {
			let bannerPath: string = ''

			if (isType === 'Vídeo de Apresentação') {
				if (checkEmptyString(title)) {
					throw new Error('Informe um título válido.')
				}
			}

			if (isType === 'Banners Home') {
				if (checkEmptyString(title)) {
					throw new Error('Informe um título válido.')
				}

				if (!!link && !checkEmptyString(link)) {
					if (!['http', 'https'].includes(link.split('://')[0])) {
						throw new Error('Insira um link válido (iniciando com http:// ou https://)')
					}
				}

			}

			if (isType === 'Termo' || isType === 'Política de Privacidade') {
				if (checkEmptyString(cleanHTML)) {
					throw new Error('Informe um texto válido.')
				}
			}

			if (isType === 'Vídeo de Apresentação') {
				if (!reference) {
					throw new Error('Selecione um vídeo válido.')
				}
			}

			if (bannerFileToUpload) {
				try {
					bannerPath = await uploadBanner(bannerFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			let info: HomeInfoCreate = {
				text: isType === 'Termo' || isType === 'Política de Privacidade' ? text : title,
				info: {
					[`${isType === 'Termo' || isType === 'Política de Privacidade'
						? undefined
						: isType === 'Banners Home'
							? 'link'
							: null
						}`
					]: isType === 'Termo' || isType === 'Política de Privacidade'
							? undefined
							: isType === 'Banners Home'
								? link
								: null,
					[`${isType === 'Termo' || isType === 'Política de Privacidade'
						? undefined
						: isType === 'Banners Home'
							? 'image'
							: 'reference'
						}`]:
						isType === 'Termo' || isType === 'Política de Privacidade'
							? undefined
							: isType === 'Banners Home'
								? bannerPath
								: reference,
				},
				type: returnTypeForFunction,
			}

			await createHomeInfoService(info)

			Swal.fire({
				title: 'Sucesso!',
				text: `${isType} criado com sucesso!`,
				icon: 'success',
			})

			returnToPreview()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const editHomeInfo = async (e: React.FormEvent) => {
		e.preventDefault()

		const cleanHTML = getCleanHtml(editorState)

		try {
			let bannerPath: string = banner

			if (isType === 'Vídeo de Apresentação') {
				if (checkEmptyString(title)) {
					throw new Error('Informe um título válido.')
				}
			}

			if (isType === 'Banners Home') {
				if (checkEmptyString(title)) {
					throw new Error('Informe um título válido.')
				}

				if (!!link && !checkEmptyString(link)) {
					if (!['http', 'https'].includes(link.split('://')[0])) {
						throw new Error('Insira um link válido (iniciando com http:// ou https://)')
					}
				}

			}

			if (isType === 'Termo' || isType === 'Política de Privacidade') {
				if (checkEmptyString(cleanHTML)) {
					throw new Error('Informe um texto válido.')
				}
			}

			if (isType === 'Vídeo de Apresentação') {
				if (!reference) {
					throw new Error('Selecione um vídeo válido.')
				}
			}

			if (bannerFileToUpload) {
				try {
					bannerPath = await uploadBanner(bannerFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			let info: HomeInfoForUpdate = {
				text: isType === 'Termo' || isType === 'Política de Privacidade' ? cleanHTML : title,
				info: {
					[`${isType === 'Termo' || isType === 'Política de Privacidade'
						? undefined
						: isType === 'Banners Home'
							? 'link'
							: null
						}`
					]: isType === 'Termo' || isType === 'Política de Privacidade'
							? undefined
							: isType === 'Banners Home'
								? link
								: null,
					[`${isType === 'Termo' || isType === 'Política de Privacidade'
						? undefined
						: isType === 'Banners Home'
							? 'image'
							: 'reference'
						}`]:
						isType === 'Termo' || isType === 'Política de Privacidade'
							? undefined
							: isType === 'Banners Home'
								? bannerPath
								: reference,
				},
			}

			await editHomeInfoService(info, infoId)

			Swal.fire({
				title: 'Sucesso!',
				text: `${isType} editado com sucesso!`,
				icon: 'success',
			})

			returnToPreview()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const uploadBanner = async (banner: File): Promise<string> => {
		const formData = new FormData()
		formData.append('file', banner)

		const reference = await uploadFile(formData)

		return reference.reference
	}

	const isEditting = useMemo(() => {
		if (infoId) {
			return true
		}

		return false
	}, [infoId])

	const isType = useMemo(() => {
		switch (type) {
			case 'terms':
				return 'Termo'

			case 'banners':
				return 'Banners Home'

			case 'politic':
				return 'Política de Privacidade'

			case 'welcome_video':
				return 'Vídeo de Apresentação'

			default:
				return ''
		}
	}, [type])

	const returnToPreview = () => {
		if (type === 'banners') {
			history.push('/banners')
		} else {
			history.push('/home')
		}

	}

	const getHomeInfoByInfoId = useCallback(async () => {
		if (infoId) {
			const localHomeInfo = await getHomeInfoByIdService(infoId)

			if (localHomeInfo && Object.keys(localHomeInfo).length) {
				switch (type) {
					case 'terms':
						setText(localHomeInfo.text)
						break

					case 'politic':
						setText(localHomeInfo.text)
						break

					case 'banners':
						setBanner(localHomeInfo.info.image)
						setTitle(localHomeInfo.text)
						setLink(localHomeInfo.info.link)
						break

					case 'welcome_video':
						setReference(localHomeInfo.info.reference)
						setTitle(localHomeInfo.text)
						break

					default:
						break
				}
			}
		}
	}, [infoId, type])

	useEffect(() => {
		getHomeInfoByInfoId()
	}, [getHomeInfoByInfoId])

	const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
		if (sambaVideosContent) {
			if (sambaVideosContent.files && sambaVideosContent.files.length) {
				const firstFile = sambaVideosContent.files[0]

				if (firstFile) {
					const playerKey = environmentVariables.playerKey
					const referenceUrl = `${environmentVariables.playerInitialUrl}/${playerKey}/${sambaVideosContent.id}`

					setReference(referenceUrl)
				}
			}
		}

		hideModal()
	}

	const onCutImage = (file: File) => {
		if (file) {
			setBannerFileToUpload(file)

			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setBanner(`${reader.result}`)

			hideModal()
		}
	}

	const selectContent = () => {
		openModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />)
	}

	const selectThumbnail = () => {
		openModal('Selecionar Imagem de Capa', <CutImage aspect={1205 / 438} onCutImage={onCutImage} />)
	}

	return (
		<CreateAndEditHomeInfoContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/profile">Perfil</Link>,
					<span>{isType}</span>,
					<span>
						{isEditting ? 'Editar' : 'Criar'} {isType}
					</span>,
				]}
			/>

			<DefaultPageTitle>
				{isEditting ? 'Editar' : 'Criar'} {isType}
			</DefaultPageTitle>

			<DefaultCreationForm>
				{isType === 'Termo' || isType === 'Política de Privacidade' ? (
					<>
						<DefaultCreationFormGroup>
							<label htmlFor="text-term" className="required">
								Texto
							</label>
							<DescriptionTextarea
								description={text}
								editorState={editorState}
								setEditorState={setEditorState}
							/>
						</DefaultCreationFormGroup>
					</>
				) : isType === 'Vídeo de Apresentação' ? (
					<>
						<DefaultCreationFormGroup>
							<label htmlFor="title-term" className="required">
								Título
							</label>
							<DefaultInput
								id="title-term"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								required
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="reference" className="required">
								Conteúdo
							</label>

							<ContentUploadOrSelectContainer>
								<ButtonGroupInline>
									<DefaultButton type="button" onClick={selectContent}>
										Selecionar Conteúdo
									</DefaultButton>
								</ButtonGroupInline>
								{reference ? (
									<iframe
										title="referenced-video"
										allowFullScreen
										src={reference}
										frameBorder={0}
									></iframe>
								) : (
									<></>
								)}
							</ContentUploadOrSelectContainer>
						</DefaultCreationFormGroup>
					</>
				) : (
					<>
						<DefaultCreationFormGroup>
							<label htmlFor="title-term" className="required">
								Título
							</label>
							<DefaultInput
								id="title-term"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								required
							/>
						</DefaultCreationFormGroup>
						<DefaultCreationFormGroup>
							<label htmlFor="url" >
								Link
							</label>
							<DefaultInput
								type="url"
								id="url"
								placeholder="http://"
								value={link}
								onChange={(e) => setLink(e.target.value)}
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="reference" className="required">
								Capa
							</label>
							<span className="observation-img">
								<li>Recomendado: 1394x505</li>
							</span>
							<BannerUploadContainer>
								<DefaultButton type="button" onClick={selectThumbnail}>
									Selecionar Imagem
								</DefaultButton>

								{banner && <ContentBanner src={banner} />}
							</BannerUploadContainer>
						</DefaultCreationFormGroup>
					</>
				)}

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={returnToPreview}>
						Cancelar
					</DefaultButton>
					<DefaultButton
						className="success"
						onClick={(e) => (isEditting ? editHomeInfo(e) : createHomeInfo(e))}
					>
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditHomeInfoContainer>
	)
}

export default CreateAndEditHomeInfo
