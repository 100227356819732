import styled from 'styled-components'

const CreateAndEditContentContainer = styled.div``

const ContentUploadOrSelectContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	iframe {
		margin-top: 10px;
		width: 600px;
		height: 340px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const ThumbnailUploadContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	img {
		margin-top: 10px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const ContentThumbnail = styled.img`
	max-width: 380px;
`

const ButtonGroupInline = styled.div`
	display: flex;
	align-items: center;

	button + button {
		margin-left: 10px;
	}
`

const ListExtraMaterialContainer = styled.div`
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const ExtraMaterialElement = styled.div`
	width: 100%;
	border-radius: 4px;
	border: solid 1px var(--default-dark-gray);
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	button:first-of-type {
		margin-left: auto;
		margin-right: 10px;
	}
`

export {
	CreateAndEditContentContainer,
	ContentUploadOrSelectContainer,
	ThumbnailUploadContainer,
	ContentThumbnail,
	ButtonGroupInline,
	ListExtraMaterialContainer,
	ExtraMaterialElement,
}
