import Profile from 'pages/ForMedico/Profile'
import {
	ARTICLES,
	BANNERS,
	CREATE_ARTICLE,
	CREATE_FAQS,
	CREATE_PODCAST,
	CREATE_NEWS,
	CREATE_PRODUCT,
	CREATE_VIDEO,
	EDIT_ARTICLE,
	EDIT_FAQS,
	EDIT_PODCAST,
	EDIT_NEWS,
	EDIT_PRODUCT_BASE_ID,
	EDIT_VIDEO,
	ERROR_404,
	FAQS,
	HOME,
	HOME_INFO_CREATE,
	HOME_INFO_EDIT,
	NEWS,
	PODCASTS,
	PRIVATE_POLITICS,
	PRODUCTS,
	PROFILE,
	REPORTS_LOGS_ENGAGE,
	REPORTS_LOGS_USERS,
	TERMS_OF_USE,
	USERS,
	VIDEOS,
	WELCOME_VIDEO,
	CONTACTS,
	TAGS,
	CONTACT,
	BANNERS_LANDING,
	REPORTS_USERS,
	EVENTS,
	CREATE_EVENT,
	EDIT_EVENT,
	LIVES,
	REPORTS_LIVES,
	BANNERS_LANDING_EDIT,
	BANNERS_LANDING_CREATE,
	USERS_MEDIC,
} from '.'
import { IRoutes } from '../routes'
import Contents from 'pages/ForMedico/Contents'
import Users from 'pages/ForMedico/Users'
import HomePage from 'pages/ForMedico/Home'
import Products from 'pages/ForMedico/Products'
import CreateAndEditProduct from 'pages/ForMedico/Products/CreateAndEdit'
import HomeInfoPage from 'pages/ForMedico/CMS'
import CreateAndEditFAQS from 'pages/ForMedico/CreateAndEditFAQS'
import CreateAndEditHomeInfo from 'pages/ForMedico/CreateAndEditHomeInfo'
import Reports from 'pages/ForMedico/Reports'
import FAQs from 'pages/ForMedico/CMS/FAQ'
import Error404 from 'pages/Error404'
import Articles from 'pages/ForMedico/Articles'
import News from 'pages/ForMedico/News'
import CreateAndEditContent from 'pages/ForMedico/CreateAndEditContent'
import CreateAndEditArticle from 'pages/ForMedico/CreateAndEditArticle'
import CreateAndEditNews from 'pages/ForMedico/CreateAndEditNews'
import Contacts from 'pages/ForMedico/Contacts'
import Tags from 'components/Tags'
import Contact from 'pages/ForMedico/Contact'
import BannersLanding from 'pages/ForMedico/BannersLanding'
import BannersLandingEdit from 'pages/ForMedico/BannersLanding/EditBannersLanding'
import Events from 'pages/ForMedico/Events'
import CreateAndEditEvent from 'pages/ForMedico/CreateAndEditEvent'
import Lives from 'pages/ForMedico/Live'

const medicoRoutes = [
	{
		path: HOME,
		name: 'Página Principal',
		component: HomePage,
		exact: true,
		isProtected: true,
	},
	{
		path: PROFILE,
		name: 'Perfil',
		component: Profile,
		exact: true,
		isProtected: true,
	},
	{
		path: REPORTS_USERS,
		name: 'Relatório de Usuários',
		component: Reports,
		exact: true,
		isProtected: true,
	},
	{
		path: REPORTS_LIVES,
		name: 'Relatório de Lives',
		component: Reports,
		exact: true,
		isProtected: true,
	},
	{
		path: USERS_MEDIC,
		name: 'Usuários',
		component: Users,
		exact: true,
		isProtected: true,
	},
	{
		path: TAGS,
		name: 'Tags',
		component: Tags,
		exact: true,
		isProtected: true,
	},
	{
		path: PRODUCTS,
		name: 'Produtos',
		component: Products,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_PRODUCT,
		name: 'Criar produto',
		component: CreateAndEditProduct,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_PRODUCT_BASE_ID,
		name: 'Editar produto',
		component: CreateAndEditProduct,
		exact: true,
		isProtected: true,
	},
	{
		path: VIDEOS,
		name: 'Vídeos',
		component: <Contents type="VIDEO" />,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_VIDEO,
		name: 'Vídeos',
		component: <CreateAndEditContent type="VIDEO" />,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_VIDEO,
		name: 'Vídeos',
		component: <CreateAndEditContent type="VIDEO" />,
		exact: true,
		isProtected: true,
	},
	{
		path: PODCASTS,
		name: 'Podcasts',
		component: <Contents type="PODCAST" />,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_PODCAST,
		name: 'Podcasts',
		component: <CreateAndEditContent type="PODCAST" />,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_PODCAST,
		name: 'Podcasts',
		component: <CreateAndEditContent type="PODCAST" />,
		exact: true,
		isProtected: true,
	},
	{
		path: ARTICLES,
		name: 'Artigos',
		component: Articles,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_ARTICLE,
		name: 'Criar Artigo',
		component: CreateAndEditArticle,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_ARTICLE,
		name: 'Editar Artigo',
		component: CreateAndEditArticle,
		exact: true,
		isProtected: true,
	},
	{
		path: NEWS,
		name: 'Notícias',
		component: News,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_NEWS,
		name: 'Criar Notícias',
		component: CreateAndEditNews,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_NEWS,
		name: 'Editar Notícias',
		component: CreateAndEditNews,
		exact: true,
		isProtected: true,
	},
	{
		path: TERMS_OF_USE,
		name: 'Termos de Uso',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: PRIVATE_POLITICS,
		name: 'Políticas de Privacidade',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: WELCOME_VIDEO,
		name: 'Vídeo introdutório',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: BANNERS,
		name: 'Banners Home',
		component: HomeInfoPage,
		exact: true,
		isProtected: true,
	},
	{
		path: BANNERS_LANDING,
		name: 'Banners Landing',
		component: BannersLanding,
		exact: true,
		isProtected: true,
	},
	{
		path: BANNERS_LANDING_EDIT,
		name: 'Banners Landing',
		component: BannersLandingEdit,
		exact: true,
		isProtected: true,
	},
	{
		path: BANNERS_LANDING_CREATE,
		name: 'Banners Landing',
		component: BannersLandingEdit,
		exact: true,
		isProtected: true,
	},
	{
		path: CONTACT,
		name: 'Contato',
		component: Contact,
		exact: true,
		isProtected: true,
	},
	{
		path: CONTACTS,
		name: 'Equipe de campo',
		component: Contacts,
		exact: true,
		isProtected: true,
	},
	{
		path: FAQS,
		name: 'FAQS',
		component: FAQs,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_FAQS,
		name: 'Create FAQS',
		component: CreateAndEditFAQS,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_FAQS,
		name: 'Edit FAQS',
		component: CreateAndEditFAQS,
		exact: true,
		isProtected: true,
	},
	{
		path: HOME_INFO_CREATE,
		name: 'Criar Info',
		component: CreateAndEditHomeInfo,
		exact: true,
		isProtected: true,
	},
	{
		path: HOME_INFO_EDIT,
		name: 'Editar Info',
		component: CreateAndEditHomeInfo,
		exact: true,
		isProtected: true,
	},
	{
		path: REPORTS_LOGS_USERS,
		name: 'Log de Acesso usuários',
		component: Reports,
		exact: true,
		isProtected: true,
	},
	{
		path: REPORTS_LOGS_ENGAGE,
		name: 'Engajamento',
		component: Reports,
		exact: true,
		isProtected: true,
	},
	{
		path: EVENTS,
		name: 'Página para criação de eventos',
		component: Events,
		exact: true,
		isProtected: true,
	},
	{
		path: CREATE_EVENT,
		name: 'Criar eventos',
		component: CreateAndEditEvent,
		exact: true,
		isProtected: true,
	},
	{
		path: EDIT_EVENT,
		name: 'Editar eventos',
		component: CreateAndEditEvent,
		exact: true,
		isProtected: true,
	},
	{
		path: LIVES,
		name: 'Gerenciamento das lives',
		component: Lives,
		exact: true,
		isProtected: true,
	},
	{
		path: ERROR_404,
		name: 'Página de erro 404',
		component: Error404,
		exact: true,
	},
] as IRoutes[]

export default medicoRoutes
