import httpClient from 'http-client'
import HomeInfo from 'models/home-info'

const convertAddressesToSubtitle = (addresses: any[]) => {
	let addressText = ''
	for (let addressReference of addresses) {
		addressText += `${addressReference.address}, ${addressReference.number}`

		if (addressReference.complement) {
			addressText += ` - ${addressReference.complement}`
		}

		addressText += ` - ${addressReference.city}`

		addressText += ` - ${addressReference.state}`

		addressText += ' | '

		addressText += `CEP: ${addressReference.cep}`

		addressText += ' | '
	}

	return addressText
}

const getEnderecos = async () => {
	const homeInfos = (await httpClient.get<HomeInfo[]>(`/home-info`, { params: { type: 'contact_medic' } })).data

	return homeInfos
		.sort((hiA, hiB) => (new Date(hiA.date).getTime() > new Date(hiB.date).getTime() ? -1 : 1))
		.find((hi) => hi.text === 'Endereços')
}

const updateEnderecos = async (enderecos: HomeInfo) => {
	const subtitle = convertAddressesToSubtitle(enderecos.info.addresses)
	return httpClient.put(`/home-info/${enderecos.home_id}`, {
		text: 'Endereços',
		info: { subtitle, addresses: enderecos.info.addresses },
	})
}

const createEnderecos = async (enderecosInfo: any) => {
	const subtitle = convertAddressesToSubtitle(enderecosInfo.addresses)
	return httpClient.post(`/home-info/`, {
		type: 'contact_medic',
		text: 'Endereços',
		info: { subtitle, addresses: enderecosInfo.addresses },
	})
}

export { getEnderecos, updateEnderecos, createEnderecos }
