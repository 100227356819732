import ModalContext from 'contexts/ModalContext'
import React, { useContext } from 'react'
import { ModalsContainer } from './style'

const Modals: React.FC = () => {
	const { modals } = useContext(ModalContext)

	return modals?.length ? <ModalsContainer>{modals}</ModalsContainer> : <></>
}

export default Modals
