import styled from 'styled-components'

const UsersContainer = styled.div`
	.users-list tr td:nth-child(3) {
		text-transform: capitalize;
	}
	.users-list tr:nth-child(even) {
		background-color: blue;
		border: 1px solid grey;
		color: red;
	}

	.anchor-link {
		a {
			text-decoration: none;
		}
	}
`

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: var(--font-small);
    color: var(--primary-color);
  }
`

const DefaultCreateButtonAndSearchFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 400px;
  margin: 10px 0;
  form {
    button {
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      width: 320px;
    }
  }
`

export {
	UsersContainer,
	SearchContainer,
	DefaultCreateButtonAndSearchFormContainer,
}
