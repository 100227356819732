import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import DefaultButton from 'components/DefaultButton'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import { getFAQ as getFAQService, createFAQ as createFAQService, updateFAQ as updateFAQService, getFAQS } from 'services/faq'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import checkEmptyString from 'helpers/check-empty-string'
import { CreateAndEditContainer } from './style'

interface CreateAndEditFAQSProps {
	faqId: string
}

const CreateAndEditFAQS: React.FC = () => {
	const { faqId } = useParams<CreateAndEditFAQSProps>()
	const history = useHistory()

	const [question, setQuestion] = useState<string>('')
	const [answer, setAnswer] = useState<string>('')

	const isEditting = useMemo(() => {
		if (faqId) {
			return true
		}

		return false
	}, [faqId])

	const goToFAQS = () => {
		history.push('/faqs')
	}

	const getFAQ = useCallback(async () => {
		if (faqId) {
			const localFaq = await getFAQService(faqId)
			
			setQuestion(localFaq.question)
			setAnswer(localFaq.answer)
		}
	}, [faqId])

	useEffect(() => {
		getFAQ()
		
	}, [getFAQ])

	

	const createFAQ = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(question)) {
				throw new Error('Informe uma questão válida.')
			}

			if (checkEmptyString(answer)) {
				throw new Error('Informe uma resposta válida.')
			}

			const faq = {
				question,
				answer,
			}

			await createFAQService(faq)

			Swal.fire({
				title: 'Sucesso!',
				text: 'FAQ criado com sucesso!',
				icon: 'success',
			})

			goToFAQS()
		} catch (error) {
			Swal.fire({
				title: 'Erro',
				text: `Houve um erro ao criar o FAQ. ` + error.message,
				icon: 'error',
			})
		}
	}

	const editFAQ = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(question)) {
				throw new Error('Informe uma questão válida.')
			}

			if (checkEmptyString(answer)) {
				throw new Error('Informe uma resposta válida.')
			}

			const faq = {
				question,
				answer,
			}

			await updateFAQService(faq, faqId)

			Swal.fire({
				title: 'Sucesso!',
				text: 'FAQ editado com sucesso!',
				icon: 'success',
			})

			goToFAQS()
		} catch (error) {
			Swal.fire({
				title: 'Erro',
				text: `Houve um erro ao editar o FAQ. ` + error.message,
				icon: 'error',
			})
		}
	}

	return (
		<CreateAndEditContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/profile">Perfil</Link>,
					<Link to="/faqs">FAQS</Link>,
					<span>{isEditting ? 'Editar' : 'Criar'} FAQ</span>,
				]}
			/>

			<DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} FAQ</DefaultPageTitle>

			<DefaultCreationForm>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="question">
						Pergunta
					</label>
					<DefaultInput
						value={question}
						onChange={(e) => setQuestion(e.target.value)}
						id="question"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="answer">
						Resposta
					</label>

					<DefaultInput value={answer} onChange={(e) => setAnswer(e.target.value)} id="answer" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToFAQS}>
						Cancelar
					</DefaultButton>
					<DefaultButton onClick={(e) => (isEditting ? editFAQ(e) : createFAQ(e))} className="success">
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditContainer>
	)
}

export default CreateAndEditFAQS
