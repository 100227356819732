import { BreadCrumb, DefaultButton, DefaultPageTitle, DefaultTable } from 'components'
import DefaultFilterForm from 'components/DefaultFilterForm'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { NewsContainer } from './style'
import NewsInterface from 'models/from-api-response/news'
import { CREATE_NEWS, EDIT_NEWS } from 'components/Routes/Constants'
import {
	getNews as getNewsService,
	removeNews as removeNewsService,
	checkNewsAsHighlighted as highlightNewsService,
} from 'services/news'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { AiOutlineCheck, AiOutlineHighlight } from 'react-icons/ai'
import Swal from 'sweetalert2'
import showErrorMessage from 'helpers/show-error-message'

const News: React.FC = () => {
	const [news, setNews] = useState([] as NewsInterface[])
	const history = useHistory()

	const createNews = () => {
		history.push(CREATE_NEWS)
	}

	const editNews = useCallback(
		(newsId: string) => {
			history.push(EDIT_NEWS.replace(':newsId', newsId))
		},
		[history]
	)

	const getNews = useCallback(async () => {
		const news = await getNewsService()
		setNews(news)
	}, [setNews])

	const highlightNews = useCallback(
		async (categoryId: string, newsId: string) => {
			const result = await Swal.fire({
				icon: 'question',
				text: `Tem certeza que deseja destacar esta noticia?`,
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			})

			if (result.isConfirmed) {
				try {
					await highlightNewsService(newsId, categoryId)

					await Swal.fire({
						icon: 'success',
						text: `Noticia destacada com sucesso!`,
					})

					await getNews()
				} catch (error) {
					showErrorMessage(error)
				}
			}
		},
		[getNews]
	)

	const removeNews = useCallback(
		async (newsId: string) => {
			const result = await Swal.fire({
				icon: 'question',
				text: 'Tem certeza que deseja remover esta notícia?',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			})

			if (result.isConfirmed) {
				try {
					await removeNewsService(newsId)

					Swal.fire({ icon: 'success', text: 'Notícia removida com sucesso!' })

					await getNews()
				} catch (error) {
					showErrorMessage(error, 'Erro ao excluír notícia')
				}
			}
		},
		[getNews]
	)

	const newsRows = useMemo(() => {
		return news?.map((n) => ({
			title: n.title,
			description: n.description,
			categoryName: (n.category || {}).title,
			isHighLighted: n.is_highlight ? <AiOutlineCheck size={16} /> : <></>,
			actions: (
				<>
					<DefaultButton
						onClick={() => editNews(n.news_id)}
						className="small info"
						type="button"
						title="Editar"
					>
						<BiEdit />
					</DefaultButton>{' '}
					{!n.is_highlight && (
						<DefaultButton
							onClick={() => {
								highlightNews((n.category || {}).category_id, n.news_id)
							}}
							className="small success"
							title={`Destacar Noticia`}
						>
							<AiOutlineHighlight />
						</DefaultButton>
					)}{' '}
					<DefaultButton
						onClick={() => removeNews(n.news_id)}
						className="small danger"
						type="button"
						title="Excluir"
					>
						<BiTrash />
					</DefaultButton>
				</>
			),
		}))
	}, [editNews, highlightNews, news, removeNews])

	useEffect(() => {
		getNews()
	}, [getNews])

	return (
		<NewsContainer>
			<BreadCrumb crumbs={[<Link to="/">Home</Link>, <span>Notícias</span>]} />

			<DefaultPageTitle>Notícias</DefaultPageTitle>

			<DefaultFilterForm>
				<div>
					<DefaultButton type="button" onClick={createNews}>
						Criar Notícia
					</DefaultButton>
				</div>
			</DefaultFilterForm>
			<DefaultTable
				usePagination={false}
				headersConfig={[
					{ headerLabel: <span>Título</span>, propName: 'title' },
					{ headerLabel: <span>Descrição</span>, propName: 'description' },
					{ headerLabel: <span>Destaque</span>, propName: 'isHighLighted' },
					{ headerLabel: <span>Ações</span>, propName: 'actions' },
				]}
				items={newsRows}
			/>
		</NewsContainer>
	)
}

export default News
