import httpClient from 'http-client'
import HomeInfoCreate from 'models/for-create/home-info'
import HomeInfoForUpdate from 'models/for-update/home-info'
import HomeInfo from 'models/home-info'

const getHomeInfo = async (type: string) => {
	const homeInfo = (
		await httpClient.get<HomeInfo[]>('home-info', {
			params: {
				type,
			},
		})
	).data

	if (homeInfo && homeInfo.length) {
		return homeInfo
	}

	return []
}

const getHomeInfoById = async (infoId: string) => {
	const homeInfo = (await httpClient.get<HomeInfo>(`home-info/${infoId}`)).data

	return homeInfo
}

const deleteHomeInfo = async (infoId: string) => {
	await httpClient.delete(`home-info/${infoId}`)
}

const createHomeInfo = async (info: HomeInfoCreate) => {
	const homeInfoResponse: HomeInfo = (await httpClient.post('home-info', info)).data

	return homeInfoResponse
}

const editHomeInfo = async (info: HomeInfoForUpdate, infoId: string) => {
	await httpClient.put(`home-info/${infoId}`, info)
}

export { getHomeInfo, deleteHomeInfo, createHomeInfo, editHomeInfo, getHomeInfoById }
