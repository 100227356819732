import httpClient from '../http-client'
import TagFromResponse from '../models/from-api-response/tag'

const getTags = async (): Promise<TagFromResponse[]> => {
	const tags = (await httpClient.get(`/tags`)).data

	return tags
}

const getTag = async (tagId: string) => {
	return (await httpClient.get(`/tags/${tagId}`)).data
}

const removeTag = async (tagId: string) => {
	return (await httpClient.delete(`/tags/${tagId}`)).data
}

const createTag = async (newTag: { tag: string }) => {
	return (await httpClient.post(`/tags`, newTag)).data
}

const updateTag = async (tagId: string, newTag: { tag: string }) => {
	return (await httpClient.put(`/tags/${tagId}`, newTag)).data
}

export { getTags, getTag, removeTag, createTag, updateTag }
