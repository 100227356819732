import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import DefaultButton from 'components/DefaultButton'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'
import { extraMaterialShow, hideExtraMaterial, ExtraMaterialContent } from 'components/ExtraMaterial'
import MediaFromResponse from 'models/from-api-response/media'
import ExtraMaterialForCreate from 'models/for-create/extra-material'
import {
	changeContentThumbnail as changeContentThumbnailService,
	createContent as createContentService,
	deleteContent,
	getContent as getContentService,
	updateContent as updateContentService,
} from 'services/contents'
import { getExtraMaterialsForLesson, createLessonExtraMaterials, deleteExtraMaterial } from 'services/extra-materials'
import { uploadFile } from 'services/files'
import CutImage from 'components/CutImage'
import checkEmptyString from 'helpers/check-empty-string'
import Select from 'react-select'
import { getAllAuthorsForSelect } from 'services/authors'
import {
	ContentThumbnail,
	ContentUploadOrSelectContainer,
	CreateAndEditContentContainer,
	ThumbnailUploadContainer,
	ButtonGroupInline,
	ListExtraMaterialContainer,
	ExtraMaterialElement,
} from './style'
import ModalContext from 'contexts/ModalContext'
import showErrorMessage from 'helpers/show-error-message'
import AppContext from 'contexts/AppContext'

interface CreateAndEditContentProps {
	contentId: string
}

const CreateAndEditContent: React.FC = () => {
	const { contentId } = useParams<CreateAndEditContentProps>()

	const history = useHistory()
	const [title, setTitle] = useState('')
	const [reference, setReference] = useState('')
	const [authors, setAuthors] = useState([] as unknown as { label: string; value: string }[])
	const [selectedAuthors, setSelectedAuthors] = useState({} as unknown as { label: string; value: string })
	const [extraMaterialToCreate, setExtraMaterialToCreate] = useState<Array<any>>([])
	const [extraMaterialToDelete, setExtraMaterialToDelete] = useState<Array<any>>([])
	const { openModal, hideModal } = useContext(ModalContext)
	const { environmentVariables } = useContext(AppContext)

	const [duration, setDuration] = useState(0)
	const [type, setType] = useState('')

	const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
	const [thumbnail, setThumbnail] = useState('')

	const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
		if (sambaVideosContent) {
			if (sambaVideosContent.files && sambaVideosContent.files.length) {
				const firstFile = sambaVideosContent.files[0]

				if (firstFile) {
					const playerKey = environmentVariables.playerKey
					const referenceUrl = `${environmentVariables.playerInitialUrl}/${playerKey}/${sambaVideosContent.id}`

					setReference(referenceUrl)
					setDuration(firstFile.fileInfo.duration / 1000)
					setType(firstFile.qualifier)
				}
			}
		}

		hideModal()
	}

	const createContent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(title)) {
				throw new Error('Informe um título valido para a aula.')
			}

			if (!reference) {
				throw new Error('Selecione um conteúdo antes.')
			}

			if (!selectedAuthors.label) {
				throw new Error('Selecione um autor antes.')
			}

			const createdContent = await createContentService({
				title: title,
				duration: duration,
				reference: reference,
				type: type,
				authors: [
					{
						author_id: selectedAuthors.value,
					},
				],
				info: {},
			})

			if (thumbnailFileToUpload) {
				try {
					await changeContentThumbnail(thumbnailFileToUpload, createdContent.content_id)
				} catch (error) {
					await deleteContent(createdContent.content_id)

					throw new Error(
						'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			if (extraMaterialToCreate && extraMaterialToCreate.length) {
				try {
					await uploadExtraMaterial(extraMaterialToCreate, createdContent.content_id)
				} catch (error) {
					throw new Error('Erro ao fazer upload do Material de Apoio.')
				}
			}

			Swal.fire({
				title: 'Sucesso!',
				text: 'Aula criada com sucesso!',
				icon: 'success',
			})

			goToContents()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const updateContent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(title)) {
				throw new Error('Informe um título valido para a aula.')
			}

			if (!reference) {
				throw new Error('Selecione um conteúdo antes.')
			}

			if (!selectedAuthors) {
				throw new Error('Selecione um autor antes.')
			}

			await updateContentService(contentId, {
				title: title,
				duration: duration,
				reference: reference,
				type: type,
				is_active: true,
				authors: [
					{
						author_id: selectedAuthors.value,
					},
				],
				info: {},
			})

			if (thumbnailFileToUpload) {
				try {
					await changeContentThumbnail(thumbnailFileToUpload, contentId)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			if (extraMaterialToDelete && extraMaterialToDelete.length) {
				const aux = extraMaterialToDelete.filter((material) => !!material.extra_material_id)

				for (let material of aux) {
					await deleteExtraMaterial(material.extra_material_id)
				}
			}

			if (extraMaterialToCreate && extraMaterialToCreate.length) {
				try {
					let extraMaterialToUpload: ExtraMaterialForCreate[] = extraMaterialToCreate.filter(
						(material) => !!material.extra_material_id === false
					)

					await uploadExtraMaterial(extraMaterialToUpload, contentId)
				} catch (error) {
					throw new Error('Erro ao fazer upload do Material de Apoio.')
				}
			}

			Swal.fire({
				title: 'Sucesso!',
				text: 'Aula editado com sucesso!',
				icon: 'success',
			})

			goToContents()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const uploadExtraMaterial = async (extraMaterialToCreate: Array<any>, contentId: string) => {
		let extra_material: ExtraMaterialForCreate[] = []

		for (let material of extraMaterialToCreate) {
			let aux: ExtraMaterialForCreate

			const reference = material.reference
				? typeof material.reference === 'string'
					? material.reference
					: await uploadMaterialFile(material.reference)
				: undefined
			const thumbnail = material.thumbnail ? await uploadMaterialFile(material.thumbnail) : undefined

			aux = {
				filename: material.filename,
				info: material.info,
				reference: reference ? reference : '',
				thumbnail: thumbnail ? thumbnail : '',
			}

			extra_material.push(aux)
		}

		if (extra_material && extra_material.length) {
			await createLessonExtraMaterials(contentId, extra_material)
		}
	}

	const uploadMaterialFile = async (materialFile: File): Promise<string> => {
		const formData = new FormData()
		formData.append('file', materialFile)

		const reference = await uploadFile(formData)

		return reference.reference
	}

	const changeContentThumbnail = async (localThumbnailFileToUpload: File, localContentId: string) => {
		const formData = new FormData()
		formData.append('file', localThumbnailFileToUpload)
		formData.append('name', `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`)
		formData.append('description', `thumbnail do conteúdo de id ${localContentId}`)

		await changeContentThumbnailService(localContentId, formData)
	}

	const selectContent = () => {
		openModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />)
	}

	const selectThumbnail = () => {
		openModal('Selecionar Imagem de Capa', <CutImage aspect={1.812} onCutImage={onCutImage} />)
	}

	const onCutImage = (file: File) => {
		if (file) {
			setThumbnailFileToUpload(file)

			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setThumbnail(`${reader.result}`)

			hideModal()
		}
	}

	const goToContents = () => {
		history.push('/contents')
	}

	const getContent = useCallback(async () => {
		const foundAuthorsSelect = await getAllAuthorsForSelect()
		if (foundAuthorsSelect) setAuthors(foundAuthorsSelect)

		if (contentId) {
			const foundExtraMaterial = await getExtraMaterialsForLesson(contentId)

			if (foundExtraMaterial && foundExtraMaterial.length) {
				setExtraMaterialToCreate(foundExtraMaterial)
			}

			const content = await getContentService(contentId)
			if (content && Object.keys(content).length) {
				setTitle(content.title)
				setReference(content.reference)
				setDuration(content.duration)
				setThumbnail(content.thumbnail)
				setType(content.type)

				if (content.authors && content.authors.length) {
					setSelectedAuthors({
						label: content.authors[0].name,
						value: `${content.authors[0].id}`,
					})
				}
			}
		}
	}, [contentId])

	useEffect(() => {
		getContent()
	}, [getContent])

	const isEditting = useMemo(() => {
		if (contentId) {
			return true
		}

		return false
	}, [contentId])

	const addExtraMaterial = () => {
		extraMaterialShow(
			<ExtraMaterialContent
				addMaterial={(data) => addExtraMaterialToArray(data)}
				closeModal={() => hideExtraMaterial()}
			/>
		)
	}

	const editExtraMaterial = (e: React.FormEvent, id: number) => {
		e.preventDefault()
		extraMaterialShow(
			<ExtraMaterialContent
				addMaterial={(data) => addExtraMaterialToArray(data)}
				closeModal={() => hideExtraMaterial()}
				id={id}
			/>
		)
	}

	const addExtraMaterialToArray = (form: any) => {
		if (extraMaterialToCreate && extraMaterialToCreate.length !== 0) {
			setExtraMaterialToCreate([...extraMaterialToCreate, form])
		} else {
			setExtraMaterialToCreate([form])
		}

		hideExtraMaterial()
	}

	const removeExtraMaterialFromArray = (index: number, event: React.FormEvent) => {
		event.preventDefault()

		if (extraMaterialToCreate !== undefined) {
			const aux = extraMaterialToCreate.filter((_, position) => position !== index)

			if (extraMaterialToDelete && extraMaterialToDelete.length) {
				setExtraMaterialToDelete([...extraMaterialToDelete, extraMaterialToCreate[index]])
			} else {
				setExtraMaterialToDelete([extraMaterialToCreate[index]])
			}
			setExtraMaterialToCreate(aux)
		}
	}

	return (
		<CreateAndEditContentContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/profile">Perfil</Link>,
					<Link to="/contents">Aulas</Link>,
					<span>{isEditting ? 'Editar' : 'Criar'} Aula</span>,
				]}
			/>

			<DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Aula</DefaultPageTitle>

			<DefaultCreationForm>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="title">
						Título
					</label>
					<DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="reference">Capa</label>

					<ThumbnailUploadContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem de Capa
						</DefaultButton>

						{thumbnail && <ContentThumbnail src={thumbnail} />}
					</ThumbnailUploadContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="reference">
						Conteúdo
					</label>
					<ContentUploadOrSelectContainer>
						<ButtonGroupInline>
							<DefaultButton type="button" onClick={selectContent}>
								Selecionar Conteúdo
							</DefaultButton>
						</ButtonGroupInline>
						{reference ? (
							<iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
						) : (
							<></>
						)}
					</ContentUploadOrSelectContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="extra-material">Material de Apoio</label>

					<ThumbnailUploadContainer>
						<DefaultButton type="button" onClick={addExtraMaterial}>
							Cadastrar Material de Apoio
						</DefaultButton>

						<ListExtraMaterialContainer>
							{extraMaterialToCreate && extraMaterialToCreate.length
								? extraMaterialToCreate.map((material, index) => (
										<ExtraMaterialElement key={index}>
											<div className="material-header">
												{material.name || material.filename} -{' '}
												<strong>{material.info.description}</strong>
											</div>
											<DefaultButton
												className="small info"
												title="Editar Material de Apoio"
												onClick={(e) => editExtraMaterial(e, material?.extra_material_id)}
											>
												<BiEdit />
											</DefaultButton>
											<DefaultButton
												className="small danger"
												title="Excluir Material de Apoio"
												onClick={(e) => removeExtraMaterialFromArray(index, e)}
											>
												<BiTrash />
											</DefaultButton>
										</ExtraMaterialElement>
								  ))
								: null}
						</ListExtraMaterialContainer>
					</ThumbnailUploadContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="tags">
						Profissional
					</label>
					<Select
						styles={{
							container: (provided) => ({
								...provided,
								flexGrow: 1,
								width: '100%',
							}),
						}}
						options={authors}
						value={selectedAuthors}
						onChange={(options) => {
							if (options !== null) {
								setSelectedAuthors(options)
							}
						}}
						id="authors"
						placeholder="Selecione um autor para esta aula"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToContents}>
						Cancelar
					</DefaultButton>
					<DefaultButton
						onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
						className="success"
					>
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditContentContainer>
	)
}

export default CreateAndEditContent
