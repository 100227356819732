import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ChromePicker } from 'react-color'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import DefaultButton from 'components/DefaultButton'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import DefaultTextArea from 'components/DefaultTextArea'
import {
	ContentThumbnail,
	CreateAndEditProductContainer,
	ContentUploadOrSelectContainer,
	ButtonGroupInline,
	ThumbnailUploadContainer,
	ProductColor,
} from './style'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'
import CutImage from 'components/CutImage'
import checkEmptyString from 'helpers/check-empty-string'
import { createProduct, getProduct, updateProduct } from 'services/products'
import { PRODUCTS } from 'components/Routes/Constants'
import MediaFromResponse from 'models/from-api-response/media'

import { uploadFile } from 'services/files'
import ModalContext from 'contexts/ModalContext'
import showErrorMessage from 'helpers/show-error-message'
import AppContext from 'contexts/AppContext'

interface CreateAndEditProductProps {
	productId: string
}

const CreateAndEditProduct: React.FC = () => {
	const { productId } = useParams<CreateAndEditProductProps>()

	const history = useHistory()

	const [title, setTitle] = useState('')
	const [subtitle, setSubtitle] = useState('')
	const [description, setDescription] = useState('')
	const [videoReference, setVideoReference] = useState('')
	const [infoUsageRecomendations, setInfoUsageRecomendations] = useState<any>('')
	const [infoConservationCare, setConservationCare] = useState<any>('')
	const [alerts, setAlerts] = useState('')
	const [NutricionalInformationFileToUpload, setNutricionalInformationFileToUpload] = useState<File>()
	const [NutricionalInformation, setNutricionalInformation] = useState<any>()
	const [ThumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
	const [thumbnail, setThumbnail] = useState<any>()
	const [color, setColor] = useState('#000000')
	const { openModal, hideModal } = useContext(ModalContext)
	const { environmentVariables } = useContext(AppContext)

	const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
		if (sambaVideosContent) {
			if (sambaVideosContent.files && sambaVideosContent.files.length) {
				const firstFile = sambaVideosContent.files[0]

				if (firstFile) {
					const playerKey = environmentVariables.playerKey
					const referenceUrl = `${environmentVariables.playerInitialUrl}/${playerKey}/${sambaVideosContent.id}`

					setVideoReference(referenceUrl)
				}
			}
		}

		hideModal()
	}

	const uploadNutricionalInformation = async (NutricionalInformationFile: any) => {
		const formData = new FormData()
		formData.append('file', NutricionalInformationFile)
		const uploadedFile = await uploadFile(formData)
		return uploadedFile
	}

	const uploadFileThumb = async (ThumbnailFile: any) => {
		const formData = new FormData()
		formData.append('file', ThumbnailFile)
		const uploadedFile = await uploadFile(formData)
		return uploadedFile
	}

	const createContent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(title)) {
				throw new Error('Informe um título válido para o produto')
			}

			if (checkEmptyString(subtitle)) {
				throw new Error('Informe uma descrição válida para o produto')
			}

			if (checkEmptyString(description)) {
				throw new Error('Informe um conteúdo válido para o produto')
			}

			if (checkEmptyString(videoReference)) {
				throw new Error('Informe um link de um vídeo válido que remeta seu produto')
			}

			if (checkEmptyString(infoUsageRecomendations)) {
				throw new Error('Informe a recomendação de uso do seu produto')
			}

			if (checkEmptyString(infoConservationCare)) {
				throw new Error('Informe os cuidados de conservação do seu produto')
			}

			if (checkEmptyString(alerts)) {
				throw new Error('Informe uma alerta ou notifique os do seu produto')
			}

			let FileThumb

			if (ThumbnailFileToUpload) {
				try {
					FileThumb = await uploadFileThumb(ThumbnailFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB e se a proporção recomendada para upload está correta.'
					)
				}
			}

			let FileNutricionalInformation

			if (NutricionalInformationFileToUpload) {
				try {
					FileNutricionalInformation = await uploadNutricionalInformation(NutricionalInformationFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB e se a proporção recomendada para upload está correta.'
					)
				}
			}

			await createProduct({
				title,
				subtitle,
				description: description ?? '',
				video_reference: videoReference,
				thumbnail: FileThumb ? FileThumb.reference : undefined,
				info: {
					usage_recomendations: infoUsageRecomendations,
					conservation_care: infoConservationCare,
					color: color,
					nutritional_information: FileNutricionalInformation
						? FileNutricionalInformation.reference
						: undefined,
				},
				alerts: alerts,
			})

			Swal.fire({
				title: 'Sucesso!',
				text: 'Produto criado com sucesso!',
				icon: 'success',
			})

			goToProducts()
		} catch (error) {
			Swal.fire({
				title: 'Erro',
				text: 'Houve um erro ao criar o produto. Tente novamente',
				icon: 'error',
			})
		}
	}

	const updateContent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(title)) {
				throw new Error('Informe um título válido para o produto')
			}

			if (checkEmptyString(subtitle)) {
				throw new Error('Informe uma descrição válida para o produto')
			}

			if (checkEmptyString(description)) {
				throw new Error('Informe um conteúdo válido para o produto')
			}

			if (checkEmptyString(videoReference)) {
				throw new Error('Informe um link de um vídeo válido que remeta seu produto')
			}

			if (checkEmptyString(infoUsageRecomendations)) {
				throw new Error('Informe a recomendação de uso do seu produto')
			}

			if (checkEmptyString(infoConservationCare)) {
				throw new Error('Informe os cuidados de conservação do seu produto')
			}

			if (checkEmptyString(alerts)) {
				throw new Error('Informe uma alerta ou notifique os do seu produto')
			}

			let FileThumb

			if (ThumbnailFileToUpload) {
				try {
					FileThumb = await uploadFileThumb(ThumbnailFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB e se a proporção recomendada para upload está correta.'
					)
				}
			}

			let FileNutricionalInformation

			if (NutricionalInformationFileToUpload) {
				try {
					FileNutricionalInformation = await uploadNutricionalInformation(NutricionalInformationFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB e se a proporção recomendada para upload está correta.'
					)
				}
			}

			await updateProduct(productId, {
				title,
				subtitle,
				description: description ?? '',
				video_reference: videoReference,
				thumbnail: FileThumb ? FileThumb.reference : undefined,
				info: {
					usage_recomendations: infoUsageRecomendations,
					conservation_care: infoConservationCare,
					color: color,
					nutritional_information: FileNutricionalInformation
						? FileNutricionalInformation.reference
						: undefined,
				},
				alerts: alerts,
			})

			Swal.fire({
				title: 'Sucesso!',
				text: 'Produto atualizado com sucesso!',
				icon: 'success',
			})

			goToProducts()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const selectThumbnail = () => {
		openModal('Selecionar a Imagem do Produto', <CutImage aspect={0.6135} onCutImage={onCutImage} />)
	}

	const onCutImage = (file: File) => {
		if (file) {
			setThumbnailFileToUpload(file)
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setThumbnail(`${reader.result}`)
			hideModal()
		}
	}

	const selectNutricionalInformation = () => {
		openModal(
			'Selecionar a Imagem de uma tabela nutricional do produto',
			<CutImage aspect={1.0906} onCutImage={onCutImageNutricionalInformation} />
		)
	}

	const onCutImageNutricionalInformation = (file: File) => {
		if (file) {
			setNutricionalInformationFileToUpload(file)
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setNutricionalInformation(`${reader.result}`)
			hideModal()
		}
	}

	const goToProducts = () => {
		history.push(`${PRODUCTS}`)
	}

	const getContent = useCallback(async () => {
		if (productId) {
			const product = await getProduct(productId)
			if (product && Object.keys(product).length) {
				setTitle(product.title)
				setSubtitle(product.subtitle)
				setThumbnail(product.thumbnail)
				setVideoReference(product.video_reference)
				setDescription(product.description)
				setColor(product.info.color || '#000000')
				setConservationCare(product.info.conservation_care)
				setInfoUsageRecomendations(product.info.usage_recomendations)
				setAlerts(product.alerts)
				setNutricionalInformation(product.info.nutritional_information)
			}
		}
	}, [productId])

	useEffect(() => {
		getContent()
	}, [getContent])

	const isEditting = useMemo(() => !!productId, [productId])

	const selectContent = () => {
		openModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />)
	}

	return (
		<CreateAndEditProductContainer>
			<BreadCrumb
				crumbs={[
					<Link to={`${PRODUCTS}`}>Produtos</Link>,
					<span>{isEditting ? 'Editar' : 'Criar'} Produto</span>,
				]}
			/>

			<DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Produto</DefaultPageTitle>

			<DefaultCreationForm>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="title">
						Nome do produto
					</label>
					<DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="subtitle">
						Subtitulo do produto
					</label>
					<DefaultInput
						value={subtitle}
						onChange={(e) => setSubtitle(e.target.value)}
						id="subtitle"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="videoReference">Vídeo do produto</label>

					<ContentUploadOrSelectContainer>
						<ButtonGroupInline>
							<DefaultButton type="button" onClick={selectContent}>
								Selecionar Conteúdo
							</DefaultButton>
						</ButtonGroupInline>
						{videoReference ? (
							<iframe
								title="referenced-video"
								allowFullScreen
								src={videoReference}
								frameBorder={0}
							></iframe>
						) : (
							<></>
						)}
					</ContentUploadOrSelectContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="description">
						Descrição
					</label>
					<DefaultTextArea
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						id="description"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="conservation_care">
						Recomendações de uso
					</label>
					<DefaultTextArea
						value={infoUsageRecomendations}
						onChange={(e) => setInfoUsageRecomendations(e.target.value)}
						id="usage_recomendations"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="usage_recomendations">Cuidados de conservação</label>
					<DefaultTextArea
						value={infoConservationCare}
						onChange={(e) => setConservationCare(e.target.value)}
						id="conservation_care"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="alerts" className="required">
						Informações importantes
					</label>
					<DefaultTextArea value={alerts} onChange={(e) => setAlerts(e.target.value)} id="alerts" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="reference">
						Imagem do produto
					</label>
					<span className="observation-img">
						<li>Precisa ter largura máxima de 391px e altura máxima de 502px</li>
					</span>
					<ThumbnailUploadContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem do Produto
						</DefaultButton>
						{thumbnail && <ContentThumbnail src={thumbnail} />}
					</ThumbnailUploadContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required">Cor do banner do produto (exibido em página de Módulo e de Aula)</label>
					<ProductColor color={color} />
					<div className="color-picker-container">
						<ChromePicker
							color={color}
							onChangeComplete={(color) => setColor(color.hex)}
							disableAlpha={true}
						/>
					</div>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="reference">Imagem da tabela nutricional do produto</label>
					<p style={{ marginBottom: '10px', color: '#8898aa' }}>
						Largura máxima de 1175px e altura máxima de 458px{' '}
					</p>
					<ThumbnailUploadContainer>
						<DefaultButton type="button" onClick={selectNutricionalInformation}>
							Selecionar Imagem
						</DefaultButton>
						{NutricionalInformation && <ContentThumbnail src={NutricionalInformation} />}
					</ThumbnailUploadContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToProducts}>
						Cancelar
					</DefaultButton>
					<DefaultButton
						onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
						className="success"
					>
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditProductContainer>
	)
}

export default CreateAndEditProduct
