import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import BreadCrumb from 'components/BreadCrumb'
import CutImage from 'components/CutImage'
import DefaultButton from 'components/DefaultButton'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import DefaultTextArea from 'components/DefaultTextArea'
import { UploadContainer, EventThumbnail, CreateAndEditEventContainer, Speaker, CheckboxBlock, WrapperSelect, ReloadWrapper, ButtonReload } from './style'
import {
	getEvent as getEventService,
	updateEvent as updateEventService,
	createEvent as createEventService,
	changeEventContent,
	changeEventThumbnail,
	deleteEvent,
	getContentReference,
	getLivesAndChannel,
} from 'services/events'
import Swal from 'sweetalert2'
import checkEmptyString from 'helpers/check-empty-string'
import { EventLocal, ISpeakerEvent } from 'models/from-api-response/event'
import EventForUpdate from 'models/for-update/event'
import Select from 'react-select'
import brazillianCities from 'storage/cidades-brasileiras.json'
import brazillianStates from 'storage/estados-brasileiros.json'
import DatePicker from 'react-datepicker'
import addDays from 'helpers/add-days'
import addHours from 'helpers/add-hours'
import showErrorMessage from 'helpers/show-error-message'
import ModalContext from 'contexts/ModalContext'
import { AvatarThumbnail } from '../CreateAndEditAuthors/style'
import { BiTrash } from 'react-icons/bi'
import { uploadFile } from 'services/files'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'
import MediaFromResponse from 'models/from-api-response/media'
import AppContext from 'contexts/AppContext'
import { differenceInHours } from 'date-fns'
import { getLive } from 'services/live'
import { AiOutlineReload } from 'react-icons/ai'

interface CreateAndEditEventProps {
	eventId: string
}

const CreateAndEditEvent: React.FC = () => {
	const history = useHistory()
	const { eventId } = useParams<CreateAndEditEventProps>()

	const [title, setTitle] = useState('')
	const [formerTitle, setFormerTitle] = useState('')
	const [subtitle, setSubtitle] = useState('')
	const [formerSubtitle, setFormerSubtitle] = useState('')
	const [description, setDescription] = useState('')
	const [formerDescription, setFormerDescription] = useState('')
	const [startDate, setStartDate] = useState(addHours(new Date(), 1))
	const [formerStartDate, setFormerStartDate] = useState('')
	const [endDate, setEndDate] = useState(addDays(new Date(), 1))
	const [formerEndDate, setFormerEndDate] = useState('')
	const [address, setAddress] = useState('')
	const [place, setPlace] = useState('')
	const [city, setCity] = useState('')
	const [state, setState] = useState('')
	const [formerLocal, setFormerLocal] = useState<EventLocal | undefined>()
	const [isPresential, setIsPresential] = useState(false)
	const [isLive, setIsLive] = useState(false)
	const [liveId, setLiveId] = useState('')
	const [thumbnail, setThumbnail] = useState('')
	const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
	const [contentId, setContentId] = useState<string | null | undefined>('')
	const [contentReference, setContentReference] = useState<any>('')
	const [formerContentReference, setFormerContentReference] = useState<any>('')
	const [videoTitle, setVideoTitle] = useState<string | undefined>()
	const [addingSpeaker, setAddingSpeaker] = useState(false)
	const [speakers, setSpeakers] = useState<ISpeakerEvent[] | any>([])
	const [formerSpeakers, setFormerSpeakers] = useState<ISpeakerEvent[] | any>([])
	const [nameSpeaker, setNameSpeaker] = useState('')
	const [descriptionSpeaker, setDescriptionSpeaker] = useState('')
	const [avatar, setAvatar] = useState<any>('')
	const [avatarImage, setAvatarImage] = useState<File | any>()
	const [allChannels, setAllChannels] = useState<any>();
	const [channels, setChannels] = useState<any>();
	const [currentChannel, setCurrentChannel] = useState<any>();
	const [tranmissions, setTransmissions] = useState<any>();
	const [currentTransmission, setCurrentTransmission] = useState<any>();
	const [liveStatus, setLiveStatus] = useState<any>();


	const { openModal, hideModal } = useContext(ModalContext)
	const { environmentVariables } = useContext(AppContext)

	const handleAddSpeaker = async () => {
		try {
			if (checkEmptyString(nameSpeaker)) {
				throw new Error('Informe um nome valido para o palestrante do evento.')
			}

			if (checkEmptyString(descriptionSpeaker)) {
				throw new Error('Informe uma descrição valida para o palestrante do evento.')
			}

			if (!avatar && !avatarImage) {
				throw new Error('Informe uma foto para o palestrante do evento.')
			}

			let avatarUrl
			if (avatarImage) {
				try {
					avatarUrl = await changeEventAvatar(avatarImage)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			setSpeakers([
				...speakers,
				{
					name: nameSpeaker,
					bio: descriptionSpeaker,
					avatar: avatarUrl ? avatarUrl.reference : '',
				},
			])

			setNameSpeaker('')
			setDescriptionSpeaker('')
			setAvatar('')
			setAddingSpeaker(false)
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const handleRemoveSpeaker = (name: any) => {
		setSpeakers(speakers.filter((speaker: any) => speaker.name !== name))
	}

	const createEvent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(title)) {
				throw new Error('Informe um nome valido para o evento.')
			}

			if (checkEmptyString(subtitle)) {
				throw new Error('Informe um subtítulo valido para o evento.')
			}

			if (checkEmptyString(description)) {
				throw new Error('Informe uma descrição valida para o evento.')
			}

			if (!thumbnail && !thumbnailFileToUpload) {
				throw new Error('Informe uma imagem de capa para o evento.')
			}

			if (!isLive && !isPresential) {
				throw new Error('Informe se o evento será presencial e/ou online.')
			}

			if (isPresential) {
				if (checkEmptyString(address)) {
					throw new Error('Informe um endereço para o evento.')
				}

				if (checkEmptyString(state)) {
					throw new Error('Informe um estado para o evento.')
				}

				if (checkEmptyString(city)) {
					throw new Error('Informe uma cidade para o evento.')
				}
			}

			if (!startDate) {
				throw new Error('Informe uma data de inicio para o evento.')
			}

			if (startDate >= endDate) {
				throw new Error('Informe uma data de inicio inferior a data de finalização.')
			}

			if (startDate <= new Date()) {
				throw new Error('Informe uma data de inicio superior a data atual.')
			}

			if (!endDate) {
				throw new Error('Informe uma data de finalização para o evento.')
			}

			if (endDate <= startDate) {
				throw new Error('Informe uma data de finalização superior a data de inicio.')
			}

			if (isLive && differenceInHours(endDate, startDate, { roundingMethod: 'ceil' }) > 4) {
				throw new Error('Não é possível criar evento com duração superior à 4 horas.')
			}

			if (speakers.length <= 0) {
				throw new Error('Informe pelo menos um palestrante para o evento.')
			}

			let eventData = {
				title: title,
				subtitle: subtitle,
				description: description,
				start_date: startDate ? startDate.toISOString() : '',
				end_date: endDate ? endDate.toISOString() : '',
				local: actualLocal,
				speakers: speakers,
				channel_sv_id: currentChannel?.value,
				live_sv_id: currentTransmission?.value
			}

			const createdEvent = await createEventService(
				eventData,
				isLive
			)

			if (thumbnailFileToUpload) {
				try {
					await changeEventImage(thumbnailFileToUpload, createdEvent.event_id)
				} catch (error) {
					await deleteEvent(createdEvent.event_id)

					throw new Error(
						'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			if (contentReference) {
				try {
					await changeEventContent(createdEvent.event_id, contentReference, title, createdEvent.live_id, contentId)
				} catch (error) {
					await deleteEvent(createdEvent.event_id)

					throw new Error(
						'Erro ao fazer upload do vídeo.'
					)
				}
			}

			Swal.fire({
				title: 'Sucesso!',
				text: 'Evento criado com sucesso!',
				icon: 'success',
			})

			goToEvents()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const updateEvent = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (checkEmptyString(title)) {
				throw new Error('Informe um nome valido para o evento.')
			}

			if (checkEmptyString(subtitle)) {
				throw new Error('Informe um subtítulo valido para o evento.')
			}

			if (checkEmptyString(description)) {
				throw new Error('Informe uma descrição valida para o evento.')
			}

			if (!thumbnail && !thumbnailFileToUpload) {
				throw new Error('Informe uma imagem de capa para o evento.')
			}

			if (!isLive && !isPresential) {
				throw new Error('Informe se o evento será presencial e/ou online.')
			}

			if (isPresential) {
				if (checkEmptyString(address)) {
					throw new Error('Informe um endereço para o evento.')
				}

				if (checkEmptyString(state)) {
					throw new Error('Informe um estado para o evento.')
				}

				if (checkEmptyString(city)) {
					throw new Error('Informe uma cidade para o evento.')
				}
			}

			if (speakers.length <= 0) {
				throw new Error('Informe pelo menos um palestrante para o evento.')
			}

			if (!startDate) {
				throw new Error('Informe uma data de inicio para o evento.')
			}

			if (startDate >= endDate) {
				throw new Error('Informe uma data de inicio inferior a data de finalização.')
			}

			if (!endDate) {
				throw new Error('Informe uma data de finalização para o evento.')
			}

			if (endDate <= startDate) {
				throw new Error('Informe uma data de finalização superior a data de inicio.')
			}

			if (isLive && differenceInHours(endDate, startDate, { roundingMethod: 'ceil' }) > 4) {
				throw new Error('Não é possível criar evento com duração superior à 4 horas.')
			}

			let live
			let editedLiveData, sendLiveData = false;
			let liveData: any = {}
			let eventData: EventForUpdate = { info: {} }

			if (formerTitle !== title) {
				editedLiveData = true;
				eventData.title = title;
			}
			if (formerSubtitle !== subtitle) {
				eventData.subtitle = subtitle;
			}
			if (formerDescription !== description) {
				editedLiveData = true;
				eventData.description = description;
			}
			if (formerStartDate !== startDate.toString()) {
				if (startDate <= new Date()) {
					throw new Error('Informe uma data de inicio superior a data atual.')
				}

				editedLiveData = true;
				liveData.start = startDate
				eventData.start_date = startDate.toISOString();
			}
			if (formerEndDate !== endDate.toString()) {
				editedLiveData = true;
				liveData.stop = endDate
				eventData.end_date = endDate.toISOString();
			}
			if (formerLocal?.city !== actualLocal.city || formerLocal?.place !== actualLocal.place || formerLocal.state !== actualLocal.state || formerLocal.street !== actualLocal.street) {
				eventData.local = actualLocal;
			}
			if (formerSpeakers !== speakers) {
				eventData.speakers = speakers;
			}


			if (!!liveId !== isLive || (isLive)) {
				sendLiveData = isLive ? true : false;
				live = liveId
				liveData.name = subtitle;
				liveData.description = description;
				liveData.live_sv_id = currentTransmission?.value;
				liveData.start = startDate.toISOString();
				liveData.stop = endDate.toISOString();
				liveData.channel_sv_id = currentChannel?.value;
			}

			if (videoTitle !== undefined && eventData.info) {
				eventData.info.video_title = videoTitle;
				if (!videoTitle) {
					eventData.content_id = null;
				}
			}


			await updateEventService(
				eventId,
				eventData,
				live,
				sendLiveData ? liveData : false
			)

			if (thumbnailFileToUpload) {
				try {
					await changeEventImage(thumbnailFileToUpload, eventId)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			if (contentReference && contentReference !== formerContentReference) {
				try {
					await changeEventContent(eventId, contentReference, title, liveId, contentId)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload do vídeo.'
					)
				}
			}

			Swal.fire({
				title: 'Sucesso!',
				text: 'Evento editado com sucesso!',
				icon: 'success',
			})

			goToEvents()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const changeEventImage = async (file: File, localEventId: string) => {
		const formData = new FormData()
		formData.append('file', file)
		formData.append('name', `event_thumbnail_${localEventId}.${file.type}`)
		formData.append('description', `thumbnail do evento de id ${localEventId}`)

		await changeEventThumbnail(localEventId, formData)
	}

	const changeEventAvatar = async (localAvatarFileToUpload: File) => {
		const formData = new FormData()
		formData.append('file', localAvatarFileToUpload)

		const fileUploaded = await uploadFile(formData)

		return fileUploaded
	}

	const selectThumbnail = () => {
		openModal('Selecionar Imagem de Capa', <CutImage aspect={1.812} onCutImage={onCutImage} />)
	}

	const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
		if (sambaVideosContent) {
			if (sambaVideosContent.files && sambaVideosContent.files.length) {
				const firstFile = sambaVideosContent.files[0]

				if (firstFile) {
					const playerKey = environmentVariables.playerKey
					const referenceUrl = `${environmentVariables.playerInitialUrl}/${playerKey}/${sambaVideosContent.id}`

					setContentReference(referenceUrl)
					setVideoTitle(title);
				}
			}
		}

		hideModal()
	}

	const selectVideo = () => {
		openModal('Selecionar Vídeo', <SelectSambaVideosContent onSelectContent={onSelectContent} />)
	}

	const removeVideo = () => {
		setVideoTitle('');
		setContentReference('');
	}

	const selectAvatar = () => {
		openModal('Selecionar Imagem do palestrante', <CutImage aspect={1} onCutImage={onCutAvatar} />)
	}

	const onCutImage = (file: File) => {
		if (file) {
			setThumbnailFileToUpload(file)

			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setThumbnail(`${reader.result}`)

			hideModal()
		}
	}

	const onCutAvatar = (file: File) => {
		if (file) {
			setAvatarImage(file)
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setAvatar(`${reader.result}`)
			hideModal()
		}
	}

	const getEvent = useCallback(async () => {
		if (eventId) {
			const event = await getEventService(eventId)
			const url = await getContentReference(event.content_id)
			const live = event?.live_id && await getLive(event?.live_id);
			const channels = await getLivesAndChannel();

			if (event && Object.keys(event).length) {
				live && setLiveStatus(live)
				setTitle(event.title)
				setSubtitle(event.subtitle)
				setDescription(event.description)
				setThumbnail(event.thumbnail)
				setAddress(event.address.street)
				setPlace(event.address.place)
				setState(event.address.state)
				setCity(event.address.city)
				setStartDate(event.startDate)
				setEndDate(event.endDate)
				setSpeakers(event.speakers)
				setLiveId(event.live_id || '')
				setIsLive(!!event.live_id)
				setVideoTitle(event.info?.video_title)
				setContentId(event.content_id)
				setContentReference(url)
				setFormerContentReference(url)
				setIsPresential(!!event.address.street)
				setFormerTitle(event.title)
				setFormerSubtitle(event.subtitle)
				setFormerDescription(event.description)
				setFormerStartDate(event.startDate.toString())
				setFormerEndDate(event.endDate.toString())
				setFormerSpeakers(event.speakers)
				setFormerLocal({
					street: event.address.street,
					place: event.address.place,
					city: event.address.city,
					state: event.address.state,
				})
				const channel = live && channels?.filter((channel: any) => channel.id === live?.channel_sv_id)
				const channelSelected = channel?.map((channel: any) => {
					return {
						label: channel?.name,
						value: channel?.id
					}
				}).shift()

				setCurrentChannel(channelSelected)
				const transmissions = live && channel.shift()?.transmissions?.filter((transmission: any) => transmission.id === live?.live_sv_id)
				const transmissionSelected = transmissions?.map((transmition: any) => {
					return {
						label: transmition?.name,
						value: transmition?.id
					}
				}).shift();
				setCurrentTransmission(transmissionSelected)
			}
		}
	}, [eventId])


	useEffect(() => {
		const setTransmissionForEvent = async () => {
			const event = await getEventService(eventId)
			const live = event?.live_id && await getLive(event?.live_id);
			const channels = await getLivesAndChannel();
			const channel = live && channels?.filter((channel: any) => channel.id === live?.channel_sv_id)

			const transmissions = live && channel.shift()?.transmissions?.filter((transmission: any) => transmission.id === live?.live_sv_id)

			const transmissionSelected = transmissions?.map((transmition: any) => {
				return {
					label: transmition?.name,
					value: transmition?.id
				}
			}).shift();
			setCurrentTransmission(transmissionSelected)
		}
		setTransmissionForEvent();

	}, [currentChannel, eventId])


	const goToEvents = () => {
		history.push('/events')
	}

	useEffect(() => {
		getEvent()
	}, [getEvent])

	useEffect(() => {
		const getChannels = async () => {
			const channels = await getLivesAndChannel();
			const channelsForSelect = channels?.map((channel: any) => {
				return {
					label: channel?.name,
					value: channel?.id
				}
			})
			setAllChannels(channels)
			setChannels(channelsForSelect);
		}
		getChannels();
	}, [])


	const reloadChannels = async (e: any) => {
		e.preventDefault();
		setCurrentChannel(null)
		const getChannels = async () => {
			const channels = await getLivesAndChannel();
			const channelsForSelect = channels?.map((channel: any) => {
				return {
					label: channel?.name,
					value: channel?.id
				}
			})
			setAllChannels(channels)
			setChannels(channelsForSelect);
		}
		getChannels();
	}

	useEffect(() => {
		if (currentChannel) {
			setTransmissions(null)
			const filteredChannel = allChannels?.filter((channel: any) => channel.id === currentChannel.value);
			const transmitions = filteredChannel[0]?.transmissions?.map((transmition: any) => {
				return {
					label: transmition?.name,
					value: transmition?.id
				}
			})
			setTransmissions(transmitions);
		}

	}, [allChannels, currentChannel])

	const isEditing = useMemo(() => {
		if (eventId) {
			return true
		}

		return false
	}, [eventId])

	const actualLocal = useMemo((): EventLocal => {
		return isPresential ? {
			street: address,
			place: place,
			city: city,
			state: state,
		} : {
			street: '',
			place: '',
			city: '',
			state: '',
		}
	}, [address, place, city, state, isPresential])

	const citiesToShow = useMemo(() => {
		if (state) {
			return brazillianCities.filter((c) => c.state === state)
		}

		return []
	}, [state])

	const selectedState = useMemo(() => {
		return brazillianStates.find((s) => s.value === state)
	}, [state])

	const selectedCity = useMemo(() => {
		return brazillianCities.find((c) => c.value === city)
	}, [city])

	return (
		<CreateAndEditEventContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/home">Home</Link>,
					<Link to="/events">Eventos</Link>,
					<span>{isEditing ? 'Editar' : 'Criar'} Evento</span>,
				]}
			/>

			<DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Evento</DefaultPageTitle>

			<DefaultCreationForm
				onSubmit={(e) => {
					e.preventDefault()
					addingSpeaker ? handleAddSpeaker() : isEditing ? updateEvent(e) : createEvent(e)
				}}
			>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="title">
						Categoria do evento
					</label>
					<DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="subtitle">
						Título do evento
					</label>
					<DefaultInput
						value={subtitle}
						onChange={(e) => setSubtitle(e.target.value)}
						id="subtitle"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="description">
						Descrição
					</label>
					<DefaultTextArea
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						id="description"
						required
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<CheckboxBlock>
						<label className="required" htmlFor="place">
							O evento será:
						</label>
						<div onClick={() => setIsLive((formerValue) => !formerValue)}>
							<input className="checkbox" checked={isLive} type="checkbox" />
							<label htmlFor="place">Online</label>
						</div>

						<div onClick={() => setIsPresential((formerValue) => !formerValue)}>
							<input className="checkbox" checked={isPresential} type="checkbox" />
							<label htmlFor="place">Presencial</label>
						</div>
					</CheckboxBlock>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="reference">
						Capa
					</label>
					<span className="observation-img">
						<li>Recomendado: 1296×715</li>
					</span>
					<UploadContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem de Capa
						</DefaultButton>

						{thumbnail && <EventThumbnail src={thumbnail} />}
					</UploadContainer>
				</DefaultCreationFormGroup>

				{isLive && (
					<DefaultCreationFormGroup>
						<label htmlFor="reference">
							Vídeo
						</label>

						<UploadContainer>
							<div className="video-wrapper">
								<div>
									<DefaultButton type="button" onClick={selectVideo}>
										{`${contentReference ? "Alterar" : "Selecionar"} Vídeo`}
									</DefaultButton>
									{contentReference && (
										<DefaultButton type="button" className="danger" onClick={removeVideo}>
											Remover Vídeo
										</DefaultButton>
									)}
								</div>
								{contentReference && (
									<iframe title="referenced-video" allowFullScreen src={contentReference} frameBorder={0}></iframe>
								)}
							</div>
						</UploadContainer>
					</DefaultCreationFormGroup>
				)}

				{isLive && liveStatus?.status !== 'STOPPED' &&
					<>

						<WrapperSelect>
							<DefaultCreationFormGroup>
								<label htmlFor="channel" className="required">
									Canal
								</label>
								<Select
									id="channel"
									placeholder="Selecione um canal"
									options={channels}
									value={currentChannel}
									onChange={(option) => option && setCurrentChannel(option)}
								/>
							</DefaultCreationFormGroup>

							<DefaultCreationFormGroup>
								<label htmlFor="tranmissions" className="required">
									Live
								</label>
								<Select
									id="tranmissions"
									placeholder="Selecione uma transmissão"
									options={tranmissions}
									value={currentTransmission}
									onChange={(option) => option && setCurrentTransmission(option)}
								/>
							</DefaultCreationFormGroup>

							<ReloadWrapper>
								<ButtonReload onClick={e => reloadChannels(e)} title="Atualizar lives e canais">
									<AiOutlineReload />
								</ButtonReload>
							</ReloadWrapper>
						</WrapperSelect>
					</>
				}

				{isPresential && (
					<>
						<DefaultCreationFormGroup>
							<label className="required" htmlFor="address">
								Endereço
							</label>
							<DefaultInput
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								id="address"
								required
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="place">Local</label>
							<DefaultInput value={place} onChange={(e) => setPlace(e.target.value)} id="place" />
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label className="required" htmlFor="state">
								Estado
							</label>

							<Select
								value={selectedState}
								styles={{
									container: (provided) => ({
										...provided,
										flexGrow: 1,
										width: '100%',
									}),
								}}
								options={brazillianStates}
								onChange={(option) => option && setState(option.value)}
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label className="required" htmlFor="city">
								Cidade
							</label>

							<Select
								value={selectedCity}
								styles={{
									container: (provided) => ({
										...provided,
										flexGrow: 1,
										width: '100%',
									}),
								}}
								options={citiesToShow}
								onChange={(option) => option && setCity(option.value)}
							/>
						</DefaultCreationFormGroup>
					</>
				)}

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="startDate">
						Inicio
					</label>

					<DatePicker
						showTimeSelect
						selected={startDate}
						timeIntervals={5}
						onChange={(date) => date && date instanceof Date && setStartDate(date)}
						onKeyDown={(event) => event.preventDefault()}
						maxDate={endDate}
						minDate={addHours(new Date(), 1)}
						dateFormat="dd/MM/yyyy hh:mm"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="endDate">
						Fim
					</label>
					<DatePicker
						showTimeSelect
						selected={endDate}
						timeIntervals={5}
						onChange={(date) => date && date instanceof Date && setEndDate(date)}
						onKeyDown={(event) => event.preventDefault()}
						minDate={startDate}
						dateFormat="dd/MM/yyyy hh:mm"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="speakers" className="required">
						Palestrantes
					</label>
					{speakers.length
						? speakers.map((speaker: any) => (
							<Speaker>
								<img src={speaker.avatar} alt="" />
								<div>
									<strong>{speaker.name}</strong>
									<p>{speaker.bio}</p>
								</div>
								<DefaultButton
									onClick={(e) =>
										(e.detail || document.activeElement?.className.includes('small danger')) &&
										handleRemoveSpeaker(speaker.name)
									}
									className="small danger"
									title="Excluir Palestrante"
								>
									<BiTrash />
								</DefaultButton>
							</Speaker>
						))
						: !addingSpeaker && (
							<span className="no-speakers">
								Ainda não há palestrantes cadastrados para este evento.
							</span>
						)
					}
					{addingSpeaker ? (
						<DefaultCreationFormGroup className="speakers-form">
							<label htmlFor="place" className="required">
								Nome
							</label>
							<DefaultInput
								className="margin"
								value={nameSpeaker}
								onChange={(e) => setNameSpeaker(e.target.value)}
							/>
							<label htmlFor="descriptionSpeaker" className="required">
								Descrição do palestrante
							</label>
							<DefaultTextArea
								className="margin"
								value={descriptionSpeaker}
								onChange={(e) => setDescriptionSpeaker(e.target.value)}
								id="descriptionSpeaker"
							/>
							<label htmlFor="reference" className="required">
								Foto do palestrante
							</label>
							<UploadContainer className="margin">
								<DefaultButton type="button" onClick={selectAvatar}>
									Selecionar Imagem do Palestrante
								</DefaultButton>
								{avatar && <AvatarThumbnail src={avatar} />}
							</UploadContainer>
							<DefaultCreationFormButtonGroup className="speaker-buttons">
								<DefaultButton type="button" className="danger" onClick={() => setAddingSpeaker(false)}>
									Cancelar
								</DefaultButton>
								<DefaultButton type="button" className="info" onClick={handleAddSpeaker}>
									Salvar Palestrante
								</DefaultButton>
							</DefaultCreationFormButtonGroup>
						</DefaultCreationFormGroup>
					) : (
						<div className="add-speakers-wrapper">
							<DefaultButton
								type="button"
								className={speakers.length > 3 ? "disabled-button" : ""}
								onClick={() => {
									if (speakers.length <= 3) {
										setAddingSpeaker(true);
									}
								}}
							>
								Adicionar Palestrantes
							</DefaultButton>
							{speakers.length > 3 && (
								<span className="add-speakers-message">
									Não é possível adicionar mais de 4 palestrantes por evento
								</span>
							)}
						</div>
					)}
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton
						type="button"
						className={`danger ${addingSpeaker ? ' disabled-button' : ''}`}
						onClick={() => {
							if (!addingSpeaker) {
								goToEvents()
							}
						}}
					>
						Cancelar
					</DefaultButton>
					<DefaultButton
						type="submit"
						className={`info ${addingSpeaker ? 'disabled-button' : ''}`}
						onClick={(e) => {
							if (addingSpeaker) {
								e.preventDefault()
							}
						}}
					>
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditEventContainer >
	)
}

export default CreateAndEditEvent
