import httpClient from 'http-client'
import HomeInfo from 'models/home-info'

const getSAC = async () => {
	const homeInfos = (await httpClient.get<HomeInfo[]>(`/home-info`, { params: { type: 'contact_medic' } })).data

	return homeInfos
		.sort((hiA, hiB) => (new Date(hiA.date).getTime() > new Date(hiB.date).getTime() ? -1 : 1))
		.find((hi) => hi.text === 'SAC')
}

const updateSAC = async (sac: HomeInfo) => {
	return httpClient.put(`/home-info/${sac.home_id}`, { text: 'SAC', info: sac.info })
}

const createSAC = async (sacInfo: any) => {
	return httpClient.post(`/home-info/`, { type: 'contact_medic', text: 'SAC', info: sacInfo })
}

export { getSAC, updateSAC, createSAC }
