import AppContext from 'contexts/AppContext'
import EnvironmentVariables from 'models/environment-variables'
import React, { useEffect, useMemo, useState } from 'react'

const AppContextProvider: React.FC = ({ children }) => {
	const [adminType, setAdminType] = useState('gestante' as 'medico' | 'gestante')

	const environmentVariables = useMemo((): Partial<EnvironmentVariables> => {
		const variables: Partial<EnvironmentVariables> = {
			apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
			playerInitialUrl: process.env.REACT_APP_PLAYER_INITIAL_URL,
		}

		switch (adminType) {
			case 'gestante':
				return {
					...variables,
					sambaUploadKey: process.env.REACT_APP_SAMBA_UPLOAD_KEY,
					playerKey: process.env.REACT_APP_PLAYER_KEY,
					sambaPid: process.env.REACT_APP_SAMBA_PID,
					sambaAccessToken: process.env.REACT_APP_SAMBA_ACCESS_TOKEN,
				}
			case 'medico':
				return {
					...variables,
					sambaUploadKey: process.env.REACT_APP_MEDIC_SAMBA_UPLOAD_KEY,
					playerKey: process.env.REACT_APP_MEDIC_PLAYER_KEY,
					sambaPid: process.env.REACT_APP_MEDIC_SAMBA_PID,
					sambaAccessToken: process.env.REACT_APP_MEDIC_SAMBA_ACCESS_TOKEN,
				}

			default:
				return variables
		}
	}, [adminType])

	useEffect(() => {
		const storageAdminType = localStorage.getItem('admin-type') as undefined | 'medico' | 'gestante'
		if (storageAdminType) {
			setAdminType(storageAdminType || 'gestante')
		}
	}, [])

	useEffect(() => {
		localStorage.setItem('admin-type', adminType)
	}, [adminType])

	return (
		<AppContext.Provider value={{ adminType, setAdminType, environmentVariables }}>{children}</AppContext.Provider>
	)
}

export default AppContextProvider
