import httpClient from '../http-client'
import UserFromResponse from '../models/from-api-response/user'
import UserForUpdate from 'models/for-update/users'

const getActualUser = async () => {
	return (await httpClient.get('users/me')).data
}

const getAllUser = async (filters?: any) => {
	return (await httpClient.get('users/', { params: filters })).data
}

const getUsersForSelect = async () => {
	const users = (await httpClient.get<UserFromResponse[]>(`users/`)).data

	if (users && users.length) {
		return users.map((user) => ({
			value: user.user_id,
			label: user.name,
		}))
	}

	return [] as { value: string; label: string }[]
}

const updateUser = async (userId: string, newInfo: UserForUpdate) => {
	await httpClient.put(`users/${userId}`, newInfo)
}

const toogleAdminUser = async (userId: string) => {
	await httpClient.patch(`/users/${userId}/admin`)
}

const deleteUser = async (contentId: string) => {
	await httpClient.delete(`users/${contentId}`)
}

const resetUser = async (userId: string) => {
	await httpClient.delete(`users/admin/${userId}`)
}

export { getUsersForSelect, getActualUser, updateUser, deleteUser, getAllUser, toogleAdminUser, resetUser }
