import styled from 'styled-components'

const ProfileContainer = styled.div`
	.container {
		display: flex;
		justify-content: space-around;
		align-items: center;

		h3 {
			margin: 10px 0;
		}
	}
`
const PageTitle = styled.h2`
	font-size: var(--font-medium);
	margin-bottom: 30px;
	border-bottom: solid 1.2px rgba(0, 0, 0, 0.1);
	color: var(--default-title-color);
	font-weight: 400;
	padding-bottom: 10px;
`

export { ProfileContainer, PageTitle }

export const Container = styled.div``
