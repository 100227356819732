import styled from 'styled-components'

const SelectSambaVideosContentContainer = styled.div`
	width: 100%;
	height: 100%;

	.spinner {
		border: 16px solid #f3f3f3;
		border-radius: 50%;
		border-top: 16px solid var(--info-color);
		width: 120px;
		height: 120px;
		animation: spin 2s linear infinite;
	}

	.spinner.small {
		border: 10px solid #f3f3f3;
		border-radius: 50%;
		border-top: 10px solid var(--info-color);
		width: 35px;
		height: 35px;
		animation: spin 2s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`

const ContentUpload = styled.button`
	height: 100%;
	width: 50%;
	background: none;
	border: solid 1px var(--default-dark-gray);
	border-radius: 15px;
	cursor: pointer;
	color: var(--primary-color);

	&:hover {
		transition: all 0.3s;
		background: var(--primary-color);
		color: white;
	}

	p {
		font-size: var(--font-large);
		margin-bottom: 10px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const ContentListContainer = styled.div`
	width: 50%;
	height: 100%;
`

const ContentList = styled.div`
	height: 92%;
	padding: 0 15px;
	overflow-y: auto;

	& > div {
		margin: 0 auto;
	}

	& > :not(:last-child) {
		margin-bottom: 10px;
	}
`

const ContentListFilters = styled.form`
	height: 8%;
	padding: 0 15px;

	display: flex;
	justify-content: space-between;
	align-items: center;
`

const ContentListFilter = styled.input`
	flex-grow: 1;
	padding: 10px;
	height: 43px;
	outline: none;
	border: solid 1px var(--default-dark-gray);
	border-radius: 5px;
`

export {
	SelectSambaVideosContentContainer,
	ContentUpload,
	ContentList,
	ContentListContainer,
	ContentListFilters,
	ContentListFilter,
}
