import { BreadCrumb, DefaultButton, DefaultPageTitle, DefaultTable } from 'components'
import DefaultFilterForm from 'components/DefaultFilterForm'
import showErrorMessage from 'helpers/show-error-message'
import TagFromResponse from 'models/from-api-response/tag'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import { getTags as getTagsService, removeTag as removeTagService } from 'services/tags'
import CreateAndEditTag from './components/create-and-edit-tag'
import ModalContext from 'contexts/ModalContext'

const TagsContainer = styled.div``

const Tags: React.FC = () => {
	const [tags, setTags] = useState([] as TagFromResponse[])
	const { openModal } = useContext(ModalContext);

	const getTags = async () => {
		const tags = await getTagsService()
		setTags(tags)
	}

	const createTag = () => {
		openModal('Criar tag', <CreateAndEditTag onSubmit={getTags} />)
	}

	const editTag = (tagId: string) => {
		openModal('Editar tag', <CreateAndEditTag onSubmit={getTags} tagId={tagId} />)
	}

	const removeTag = async (tagId: string) => {
		const result = await Swal.fire({
			icon: 'question',
			text: 'Deseja remover esta tag?',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			try {
				await removeTagService(tagId)

				Swal.fire({ icon: 'success', text: 'Tag removida com sucesso!' })

				await getTags()
			} catch (error) {
				showErrorMessage(error)
			}
		}
	}

	const tagsToShow = useMemo(() => {
		return tags?.map((tag) => ({
			name: tag.tag,
			actions: (
				<>
					<DefaultButton
						title="Editar Tag"
						className="small primary"
						type="button"
						onClick={() => editTag(tag.tag_id)}
					>
						<BiEdit />
					</DefaultButton>{' '}
					<DefaultButton
						title="Excluir Tag"
						className="small danger"
						type="button"
						onClick={() => removeTag(tag.tag_id)}
					>
						<BiTrash />
					</DefaultButton>
				</>
			),
		}))
	}, [tags])

	useEffect(() => {
		getTags()
	}, [])

	return (
		<TagsContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Tags</span>]} />
			<DefaultPageTitle>Tags</DefaultPageTitle>
			<DefaultFilterForm onSubmit={getTags}>
				<div>
					<DefaultButton type="button" onClick={createTag}>
						Criar Tag
					</DefaultButton>
				</div>
			</DefaultFilterForm>
			<DefaultTable
				usePagination={false}
				items={tagsToShow}
				headersConfig={[
					{ headerLabel: <>Nome</>, propName: 'name' },
					{ headerLabel: <>Ações</>, propName: 'actions' },
				]}
			/>
		</TagsContainer>
	)
}

export default Tags
