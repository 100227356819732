import httpClient from '../http-client'
import Product from '../models/products'
import ProductForCreate from '../models/for-create/products'
import ProductForUpdate from '../models/for-update/products'
import { ProductItemFromResponse } from '../models/from-api-response/product'
import { uploadFile } from './files'

const getProduct = async (productId: string) => {
	const product = (await httpClient.get<Product>(`products/${productId}`)).data

	return product
}

const getAllProducts = async (filters?: any) => {
	const products = (await httpClient.get<any[]>('products', { params: filters })).data
	if (products && products.length) {
		return products
	}
}

const createProduct = async (newProduct: ProductForCreate) => {
	const createProductResponse = (await httpClient.post<any>(`products/`, newProduct)).data

	return createProductResponse
}

const updateProduct = async (productId: any, newInfo: ProductForUpdate) => {
	await httpClient.put(`products/${productId}`, newInfo)
}

const deleteProduct = async (contentId: string) => {
	await httpClient.delete(`products/${contentId}`)
}

const changeProductVideo = async (productId: string, formData: FormData) => {
	const uploadedFile = await uploadFile(formData)
	await httpClient.patch(`products/${productId}/video-reference`, {
		video_reference: uploadedFile.reference,
	})
}

const activateOrInactivateProduct = async (productId: string, activate: boolean) => {
	await httpClient.put(`products/${productId}/active`, { is_active: activate })
}

const getAllProductsForSelect = async () => {
	const productItem = (await httpClient.get<ProductItemFromResponse[]>('products')).data

	if (productItem && productItem.length) {
		return productItem.map((product) => ({
			label: product.title,
			value: `${product.product_id}`,
		}))
	}
}

export {
	getAllProducts,
	deleteProduct,
	updateProduct,
	createProduct,
	changeProductVideo,
	getProduct,
	activateOrInactivateProduct,
	getAllProductsForSelect,
}
