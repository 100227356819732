import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { DefaultButton, DefaultPageTitle, CreateButtonDefaultContainer, DefaultTable, Loading } from 'components'
import Product from 'models/products'
import { getAllProducts, deleteProduct } from 'services/products'
import { ProductsContainer } from './style'
import { CREATE_PRODUCT, EDIT_PRODUCT_BY_ID } from 'components/Routes/Constants'
import Error404 from 'pages/Error404'

const useProducts = () => {
	const [products, setProducts] = useState<Product[]>([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const getProducts = useCallback(async () => {
		try {
			setLoading(true)
			const products = await getAllProducts()
			if (products && products.length) {
				setProducts(products)
			}
			setError(false)
		} catch (err) {
			setError(true)
		}
		setLoading(false)
	}, [])

	const removeProduct = useCallback(
		async (productId: string) => {
			try {
				const result = await Swal.fire({
					title: '<strong>Confirmação</strong>',
					html: 'Tem certeza que deseja remover este produto?',
					showCancelButton: true,
					cancelButtonText: 'Cancelar',
					focusConfirm: false,
				})

				if (!result.isConfirmed) return

				await deleteProduct(productId)

				Swal.fire({
					icon: 'success',
					title: 'Sucesso!',
					text: 'Produto excluido com sucesso!',
				})
			} catch (e: any) {
				Swal.fire({
					icon: 'error',
					title: 'Erro ao excluir o produto',
					text: e.message,
				})
			}

			await getProducts()
		},
		[getProducts]
	)

	useEffect(() => {
		getProducts()
	}, [])

	return { products, loading, error, removeProduct }
}

const ProductsUI: React.FC = () => {
	const history = useHistory()

	const { products, loading, error, removeProduct } = useProducts()

	const editProduct = useCallback(
		(productId: string) => {
			const editProductRoute = EDIT_PRODUCT_BY_ID(productId)
			history.push(editProductRoute)
		},
		[history]
	)

	const createProduct = async () => {
		history.push(`${CREATE_PRODUCT}`)
	}

	const productsToBeShown = useMemo(() => {
		return products && products.length
			? products.map((product) => ({
					id: product.product_id,
					title: product.title,
					subtitle: product.subtitle,
					actions: (
						<>
							<DefaultButton
								className="small info"
								onClick={() => product.product_id && editProduct(product.product_id.toString())}
							>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => product.product_id && removeProduct(product.product_id.toString())}
								className="small danger"
								title="Excluir Produto"
							>
								<BiTrash />
							</DefaultButton>
						</>
					),
			  }))
			: []
	}, [products, editProduct, removeProduct])

	if (loading) {
		return <Loading />
	}

	if (error) {
		return <Error404 />
	}

	return (
		<ProductsContainer>
			<DefaultPageTitle>Produtos</DefaultPageTitle>
			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createProduct}>Criar Produto</DefaultButton>
			</CreateButtonDefaultContainer>
			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Titulo</span>,
						propName: 'title',
					},
					{
						headerLabel: <span>Subtitulo produto</span>,
						propName: 'subtitle',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={productsToBeShown}
				emptyListMessage={'Não foram encontrados produtos cadastrados!'}
			/>
		</ProductsContainer>
	)
}

export default ProductsUI
