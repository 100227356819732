import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { DefaultButton, DefaultPageTitle, CreateButtonDefaultContainer, DefaultTable, Loading } from 'components'
import Product from 'models/products'
import { getAllProducts, deleteProduct, updateProduct } from 'services/products'
import { ProductsContainer } from './style'
import { CREATE_PRODUCT, EDIT_PRODUCT_BY_ID } from 'components/Routes/Constants'
import Error404 from 'pages/Error404'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const useProducts = () => {
	const [medicine, setMedicine] = useState<Product[]>([])
	const [supplement, setSupplement] = useState<Product[]>([])

	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	

	const getProducts = useCallback(async () => {
		try {
			setLoading(true)
			const medicine = await getAllProducts({type: 'medicine'})
			const supplement = await getAllProducts({type: 'supplement'})

			if (medicine && medicine.length) {
				setMedicine(medicine)
			}
			if (supplement && supplement.length) {
				setSupplement(supplement)
			}
			setError(false)
		} catch (err) {
			setError(true)
		}
		setLoading(false)
	}, [])

	const removeProduct = useCallback(
		async (productId: string) => {
			try {
				const result = await Swal.fire({
					title: '<strong>Confirmação</strong>',
					html: 'Tem certeza que deseja remover este produto?',
					showCancelButton: true,
					cancelButtonText: 'Cancelar',
					focusConfirm: false,
				})

				if (!result.isConfirmed) return

				await deleteProduct(productId)

				Swal.fire({
					icon: 'success',
					title: 'Sucesso!',
					text: 'Produto excluido com sucesso!',
				})
			} catch (e) {
				Swal.fire({
					icon: 'error',
					title: 'Erro ao excluir o produto',
					text: e.message,
				})
			}

			await getProducts()
		},
		[getProducts]
	)

	useEffect(() => {
		getProducts()
	}, [])

	return { medicine, supplement, loading, error, setMedicine, setSupplement, removeProduct }
}

const ProductsUI: React.FC = () => {
	const history = useHistory()
	const [editProducts, setEditProducts] = useState<boolean>(false);
	const [editSupplement, setEditSupplement] = useState<boolean>(false);


	const { medicine, supplement, loading, error, setMedicine, setSupplement, removeProduct } = useProducts()

	const editProduct = useCallback(
		(productId: string) => {
			const editProductRoute = EDIT_PRODUCT_BY_ID(productId)
			history.push(editProductRoute)
		},
		[history]
	)

	const createProduct = async () => {
		history.push(`${CREATE_PRODUCT}`)
	}

	const reorderProducts = async (product: any, up: boolean) => {
		if (medicine) {
			setEditProducts(true)
			const localProducts = [...medicine]
			const indexOfProduct = medicine.indexOf(product)
			if (indexOfProduct > -1 && indexOfProduct < localProducts.length) {
				localProducts.splice(indexOfProduct, 1)
				if (up) {
					localProducts.splice(indexOfProduct - 1, 0, product)
				} else {
					localProducts.splice(indexOfProduct + 1, 0, product)
				}
			}
			setMedicine(localProducts)
		}
	}
	const reorderSupplement = async (product: any, up: boolean) => {
		if (supplement) {
			setEditSupplement(true)
			const localProducts = [...supplement]
			const indexOfProduct = supplement.indexOf(product)
			if (indexOfProduct > -1 && indexOfProduct < localProducts.length) {
				localProducts.splice(indexOfProduct, 1)
				if (up) {
					localProducts.splice(indexOfProduct - 1, 0, product)
				} else {
					localProducts.splice(indexOfProduct + 1, 0, product)
				}
			}
			setSupplement(localProducts)
		}
	}

	const saveReorderedProducts = () => {
		medicine?.map( async (product, index) => {
			const productId = product?.product_id
			
			await updateProduct(productId,
				{
					title: product?.title,
					subtitle: product?.subtitle || undefined,
					description: product.description || undefined,
					video_reference: product.video_reference || undefined,
					thumbnail: product.thumbnail || undefined,
					info: {
						usage_recomendations: product?.info?.usage_recomendations,
						conservation_care: product.info.conservation_care,
						color: product.info.color,
						nutritional_information: product.info.nutritional_information,
						presentation: product.info.presentation || undefined,
						active_principle: product.info.active_principle || undefined,
						specialist_link: product.info.specialist_link || undefined,
						drug_leaflet: product.info.drug_leaflet || undefined
					},
					alerts: product?.alerts || undefined,
					type: product?.type,
					position: index + 1
				}
			);
		})
		Swal.fire({
			icon: 'success',
			title: 'Sucesso!',
			text: 'Reordenação salva com sucesso!',
		})
		setEditProducts(false)
	}
	const saveReorderedSupplement = () => {
		supplement?.map( async (product, index) => {
			const productId = product?.product_id
			
			await updateProduct(productId,
				{
					title: product?.title,
					subtitle: product?.subtitle || undefined,
					description: product.description || undefined,
					video_reference: product.video_reference || undefined,
					thumbnail: product.thumbnail || undefined,
					info: {
						usage_recomendations: product?.info?.usage_recomendations,
						conservation_care: product.info.conservation_care,
						color: product.info.color,
						nutritional_information: product.info.nutritional_information,
						presentation: product.info.presentation || undefined,
						active_principle: product.info.active_principle || undefined,
						specialist_link: product.info.specialist_link || undefined,
						drug_leaflet: product.info.drug_leaflet || undefined
					},
					alerts: product?.alerts || undefined,
					type: product?.type,
					position: index + 1
				}
			);
		})
		Swal.fire({
			icon: 'success',
			title: 'Sucesso!',
			text: 'Reordenação salva com sucesso!',
		})
		setEditSupplement(false)
	}

	const productsToBeShown = useMemo(() => {
		return medicine && medicine.length
			? medicine.map((product, index) => ({
					id: product.product_id,
					title: product.title,
					subtitle: product.subtitle,
					type:
						product.type === 'supplement' ? 'Suplemento' : product.type === 'medicine' ? 'Medicamento' : '',
					actions: (
						<>
							<DefaultButton
								className="small info"
								onClick={() => product?.product_id && editProduct(product.product_id.toString())}
							>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => product?.product_id && removeProduct(product.product_id.toString())}
								className="small danger"
								title="Excluir Produto"
							>
								<BiTrash />
							</DefaultButton>
							{' '}
							{index > 0 ? (
								<DefaultButton type="button"
									title="Subir Faq"
									className="small white up"
									onClick={() => reorderProducts(product, true)}>
										<AiOutlineUp/>
								</DefaultButton>
								): (<></>)
							} {' '}
							{index < medicine.length - 1 ? (
							<DefaultButton type="button"
								title="Descer Faq"
								className="small white down"
								onClick={() => reorderProducts(product, false)}>
								<AiOutlineDown/>
							</DefaultButton>) :(<></>)}
						</>
					),
			  }))
			: []
	}, [medicine, editProduct, removeProduct])

	const supplementToBeShown = useMemo(() => {
		return supplement && supplement.length
			? supplement.map((product, index) => ({
					id: product.product_id,
					title: product.title,
					subtitle: product.subtitle,
					type:
						product.type === 'supplement' ? 'Suplemento' : product.type === 'medicine' ? 'Medicamento' : '',
					actions: (
						<>
							<DefaultButton
								className="small info"
								onClick={() => product?.product_id && editProduct(product.product_id.toString())}
							>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => product?.product_id && removeProduct(product.product_id.toString())}
								className="small danger"
								title="Excluir Produto"
							>
								<BiTrash />
							</DefaultButton>
							{' '}
							{index > 0 ? (
								<DefaultButton type="button"
									title="Subir Faq"
									className="small white up"
									onClick={() => reorderSupplement(product, true)}>
										<AiOutlineUp/>
								</DefaultButton>
								): (<></>)
							} {' '}
							{index < supplement.length - 1 ? (
							<DefaultButton type="button"
								title="Descer Faq"
								className="small white down"
								onClick={() => reorderSupplement(product, false)}>
								<AiOutlineDown/>
							</DefaultButton>) :(<></>)}
						</>
					),
			  }))
			: []
	}, [supplement, editProduct, removeProduct])

	if (loading) {
		return <Loading />
	}

	if (error) {
		return <Error404 />
	}

	return (
		<ProductsContainer>
			<DefaultPageTitle>Produtos</DefaultPageTitle>
			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createProduct}>Criar Produto</DefaultButton>
				&nbsp;
				&nbsp;
				{editProducts && <DefaultButton onClick={saveReorderedProducts} className="success">Salvar Reordenação dos Medicamentos</DefaultButton>}
				{editSupplement && <DefaultButton onClick={saveReorderedSupplement} className="success">Salvar Reordenação dos Suplementos</DefaultButton>}

			</CreateButtonDefaultContainer>
			<Tabs>
				<TabList>
					<Tab onClick={() => setEditSupplement(false)}>Medicamento</Tab>
					<Tab onClick={() => setEditProducts(false)}>Suplemento</Tab>
				</TabList>
				<TabPanel>
					<DefaultTable
						headersConfig={[
							{
								headerLabel: <span>Titulo</span>,
								propName: 'title',
							},
							{
								headerLabel: <span>Subtitulo produto</span>,
								propName: 'subtitle',
							},
							{
								headerLabel: <span>Tipo</span>,
								propName: 'type',
							},
							{
								headerLabel: <span>Ações</span>,
								propName: 'actions',
							},
						]}
						items={productsToBeShown}
						emptyListMessage={'Não foram encontrados produtos cadastrados!'}
					/>
				</TabPanel>
				<TabPanel>
					<DefaultTable
						headersConfig={[
							{
								headerLabel: <span>Titulo</span>,
								propName: 'title',
							},
							{
								headerLabel: <span>Subtitulo produto</span>,
								propName: 'subtitle',
							},
							{
								headerLabel: <span>Tipo</span>,
								propName: 'type',
							},
							{
								headerLabel: <span>Ações</span>,
								propName: 'actions',
							},
						]}
						items={supplementToBeShown}
						emptyListMessage={'Não foram encontrados produtos cadastrados!'}
					/>
				</TabPanel>
			</Tabs>
		</ProductsContainer>
	)
}

export default ProductsUI
