import axios from 'axios'
import MediaFromResponse from '../models/from-api-response/media'

const sambaVideosApiBaseUrl = 'https://sambavideos.sambatech.com/v1'

const getMedias = async (accessToken: string, pid: string, filters?: object) => {
	const medias = (
		await axios.get<MediaFromResponse[]>(`${sambaVideosApiBaseUrl}/medias?access_token=${accessToken}&pid=${pid}`, {
			params: filters,
			headers: {
				'Access-Control-Allow-Origin': '*',
			},
		})
	).data

	return medias
}

const getMedia = async (mediaId: string, accessToken: string, pid: string) => {
	const media = (
		await axios.get<MediaFromResponse>(
			`${sambaVideosApiBaseUrl}/medias/${mediaId}?access_token=${accessToken}&pid=${pid}`,
			{
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
			}
		)
	).data

	return media
}

export { getMedias, getMedia }
