import EnvironmentVariables from 'models/environment-variables'
import { createContext } from 'react'

interface IAppContext {
	adminType: 'medico' | 'gestante'
	setAdminType: (adminType: 'medico' | 'gestante') => void
	environmentVariables: Partial<EnvironmentVariables>
}

const AppContext = createContext({} as IAppContext)

export default AppContext
