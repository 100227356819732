import AppContext from 'contexts/AppContext'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRouter from './PrivateRoute'
import getRoutesByAdminType, { IRoutes } from './routes'

const Routes: React.FC = () => {
	const [routes, setRoutes] = useState([] as IRoutes[])
	const { adminType } = useContext(AppContext)

	const renderRoutes = ({ component: Component, path, exact, isProtected }: IRoutes) => {
		if (isProtected) {
			return (
				<PrivateRouter path={path} key={path} exact={!!exact}>
					{typeof Component === 'function' ? <Component /> : Component}
				</PrivateRouter>
			)
		}
		return (
			<Route path={path} key={path} exact={!!exact}>
				{typeof Component === 'function' ? <Component /> : Component}
			</Route>
		)
	}

	useEffect(() => {
		setRoutes(getRoutesByAdminType(adminType))
	}, [adminType])

	return <Switch>{routes.map(renderRoutes)}</Switch>
}

export default Routes
