import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Swal from 'sweetalert2'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { getHomeInfo as getHomeInfoService, deleteHomeInfo } from 'services/home-info'
import { useHistory, Link, useLocation } from 'react-router-dom'
import HomeInfo from 'models/home-info'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import CreateButtonDefaultContainer from 'components/CreateButtonDefaultContainer'
import DefaultButton from 'components/DefaultButton'
import DefaultTable from 'components/DefaultTable'
import { formatDate } from 'helpers/format-date'
import { HomeInfoContainer } from './style'

const HomeInfoPage: React.FC = () => {
	const history = useHistory()
	const location = useLocation()
	const [homeInfo, setHomeInfo] = useState<HomeInfo[]>()
	const [type, setType] = useState<string>('')

	const getType = useMemo(() => {
		switch (type) {
			case 'politic':
				return 'Políticas de Privacidade'

			case 'terms':
				return 'Termos de Uso'

			case 'welcome_video':
				return 'Vídeo de Apresentação'

			case 'banners':
				return 'Banners Home'

			default:
				return ''
		}
	}, [type])

	const getHomeInfo = useCallback(async () => {
		let localHomeInfo
		let localType = ''

		switch (location.pathname) {
			case '/private-politics':
				setType('politic')
				localHomeInfo = await getHomeInfoService('POLITIC')
				localType = 'politic'
				break

			case '/terms-of-use':
				setType('terms')
				localHomeInfo = await getHomeInfoService('TERM')
				localType = 'terms'
				break

			case '/welcome-video':
				setType('welcome_video')
				localHomeInfo = await getHomeInfoService('WELCOME_VIDEO')
				localType = 'welcome_video'
				break

			case '/banners':
				setType('banners')
				localHomeInfo = await getHomeInfoService('HOME_IMAGE')
				localType = 'banners'
				break

			default:
				break
		}

		if (localHomeInfo !== undefined) {
			if (localType === 'banners') {
				setHomeInfo(localHomeInfo)
				return
			}

			if (localHomeInfo.length) {
				history.push(`edit-home-info/${localHomeInfo[0].home_id}/${localType}`)
			} else {
				history.push(`create-home-info/${localType}`)
			}
		}
	}, [location])

	useEffect(() => {
		getHomeInfo()
	}, [getHomeInfo])

	const editHomeInfo = useCallback(
		(infoId: string) => {
			history.push(`edit-home-info/${infoId}/${type}`)
		},
		[history, type]
	)

	const createHomeInfo = () => {
		history.push(`create-home-info/${type}`)
	}

	const deletePolitic = useCallback(
		async (infoId: string) => {
			Swal.fire({
				title: '<strong>Confirmação</strong>',
				html: `Tem certeza que deseja remover este ${getType}?`,
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				focusConfirm: false,
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						await deleteHomeInfo(infoId)

						Swal.fire({
							icon: 'success',
							title: 'Sucesso!',
							text: `Usuário excluido com sucesso!`,
						})

						await getHomeInfo()
					} catch (error: any) {
						Swal.fire({
							icon: 'error',
							title: 'Erro',
							text: `Erro ao excluir o usuário. ${error.message}`,
						})
					}
				}
			})
		},
		[getHomeInfo, getType]
	)

	const contentsToBeShown = useMemo(() => {
		return homeInfo && homeInfo.length
			? homeInfo.map((info) => ({
					text: info.text,
					date: formatDate(info.date),
					actions: (
						<>
							<DefaultButton
								className="small info"
								title={`Editar ${getType}`}
								onClick={() => editHomeInfo(info.home_id)}
							>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								className="small danger"
								title={`Excluir ${getType}`}
								onClick={() => deletePolitic(info.home_id)}
							>
								<BiTrash />
							</DefaultButton>
						</>
					),
			  }))
			: []
	}, [homeInfo, deletePolitic, editHomeInfo, getType])

	return (
		<HomeInfoContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>{getType}</span>]} />

			<DefaultPageTitle>{getType}</DefaultPageTitle>

			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createHomeInfo}>Criar {getType}</DefaultButton>
			</CreateButtonDefaultContainer>

			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Título</span>,
						propName: 'text',
					},
					{
						headerLabel: <span>Data de Modificação</span>,
						propName: 'date',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={contentsToBeShown}
				emptyListMessage={`Não foram encontrados ${getType} cadastradas!`}
			/>
		</HomeInfoContainer>
	)
}

export default HomeInfoPage
