import styled from "styled-components";

const DefaultButton = styled.button`
  background: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
  font-size: var(--font-small);
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);

  &.default {
    background: #7D1857;
    color: white;
    width: 100%;
    text-transform: uppercase;
  }

  &.danger {
    background: var(--danger-color);
  }

  &.success {
    background: var(--success-color);
  }

  &.info {
    background: var(--info-color);
  }

  &.white {
    background: white;
    color: #444;
    border: solid 1px var(--default-dark-gray);
  }

  &.warning {
    background: #ffc107;
    color: black;
  }

  &.small {
    padding: 3px 6px;
  }

  &.disabled-button {
	opacity: .4;
	cursor: not-allowed;
  }
`;

export default DefaultButton;
