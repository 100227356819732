import {
	PRODUCTS,
	CONTENTS,
	COURSES,
	PROFESSIONALS,
	TERMS_OF_USE,
	PRIVATE_POLITICS,
	WELCOME_VIDEO,
	BANNERS,
	FAQS,
	REPORTS_LOGS_ENGAGE,
	REPORTS_LOGS_USERS,
	REPORTS_USERS
} from 'components/Routes/Constants'
import { AiOutlineFileText, AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { IoLibraryOutline, IoDocumentsSharp } from 'react-icons/io5'
import { GiPencilRuler } from 'react-icons/gi'
import { RiGitRepositoryPrivateFill } from 'react-icons/ri'
import { ImUserTie, ImPencil, ImFileVideo } from 'react-icons/im'
import { BsImage, BsCardList } from 'react-icons/bs'
import { FaQuestion } from 'react-icons/fa'
import { GrDocumentPerformance } from 'react-icons/gr'
import { BiUserCheck } from 'react-icons/bi'

const menus = [
	{
		label: (
			<>
				<span className="icon">
					<AiOutlineUser />
				</span>
				<span className="text">Perfil</span>
			</>
		),
		path: '/profile',
	},
	{
		label: (
			<>
				<span className="icon">
					<IoLibraryOutline />
				</span>
				<span className="text">Produtos</span>
			</>
		),
		path: `${PRODUCTS}`,
	},
	{
		label: (
			<>
				<span className="icon">
					<GiPencilRuler />
				</span>
				<span className="text">Curso</span>
			</>
		),
		children: [
			{
				label: (
					<>
						<span className="icon">
							<AiOutlineFileText />
						</span>
						<span className="text">Aulas</span>
					</>
				),
				path: CONTENTS,
			},
			{
				label: (
					<>
						<span className="icon">
							<IoLibraryOutline />
						</span>
						<span className="text">Módulos</span>
					</>
				),
				path: COURSES,
			},
		],
	},
	{
		label: (
			<>
				<span className="icon">
					<ImUserTie />
				</span>
				<span className="text">Profissionais</span>
			</>
		),
		path: PROFESSIONALS,
	},
	{
		label: (
			<>
				<span className="icon">
					<IoDocumentsSharp />
				</span>
				<span className="text">CMS</span>
			</>
		),
		children: [
			{
				label: (
					<>
						<span className="icon">
							<ImPencil />
						</span>
						<span className="text">Termos de Uso</span>
					</>
				),
				path: TERMS_OF_USE,
			},
			{
				label: (
					<>
						<span className="icon">
							<RiGitRepositoryPrivateFill />
						</span>
						<span className="text">Políticas de Privacidade</span>
					</>
				),
				path: PRIVATE_POLITICS,
			},
			{
				label: (
					<>
						<span className="icon">
							<ImFileVideo />
						</span>
						<span className="text">Vídeo de Apresentação</span>
					</>
				),
				path: WELCOME_VIDEO,
			},
			{
				label: (
					<>
						<span className="icon">
							<BsImage />
						</span>
						<span className="text">Banners Home</span>
					</>
				),
				path: BANNERS,
			},
			{
				label: (
					<>
						<span className="icon">
							<FaQuestion />
						</span>
						<span className="text">FAQS</span>
					</>
				),
				path: FAQS,
			},
		],
	},
	{
		label: (
			<>
				<span className="icon">
					<GrDocumentPerformance />
				</span>
				<span className="text">Relatórios</span>
			</>
		),
		children: [
			{
				label: (
					<>
						<span className="icon">
							<BiUserCheck />
						</span>
						<span className="text">Logs de Acesso</span>
					</>
				),
				path: REPORTS_LOGS_USERS,
			},
			{
				label: (
					<>
						<span className="icon">
							<BsCardList />
						</span>
						<span className="text">Engajamento</span>
					</>
				),
				path: REPORTS_LOGS_ENGAGE,
			},
			{
				label: (
					<>
						<span className="icon">
							<BiUserCheck />
						</span>
						<span className="text">Relatórios de Usuários</span>
					</>
				),
				path: REPORTS_USERS,
			},
		],
	},
	{
		label: (
			<>
				<span className="icon">
					<AiOutlineUsergroupAdd />
				</span>
				<span className="text">Usuários</span>
			</>
		),
		path: '/users',
	},
]

export default menus
