import httpClient from 'http-client'
import HomeInfo from 'models/home-info'

const getEmail = async () => {
	const homeInfos = (await httpClient.get<HomeInfo[]>(`/home-info`, { params: { type: 'contact_medic' } })).data

	return homeInfos
		.sort((hiA, hiB) => (new Date(hiA.date).getTime() > new Date(hiB.date).getTime() ? -1 : 1))
		.find((hi) => hi.text === 'E-mail')
}

const updateEmail = async (email: HomeInfo) => {
	return httpClient.put(`/home-info/${email.home_id}`, { text: 'E-mail', info: email.info })
}

const createEmail = async (emailInfo: any) => {
	return httpClient.post(`/home-info/`, { type: 'contact_medic', text: 'E-mail', info: emailInfo })
}

export { getEmail, updateEmail, createEmail }
