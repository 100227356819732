import styled from 'styled-components'

const EditCourseContainer = styled.div``

const ThumbnailUploadContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	img {
		margin-top: 10px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const LessonSelectionContainer = styled.div`
	width: 100%;
`

const CourseThumbnail = styled.img`
	max-width: 600px;
`

const SortLessonsContainer = styled.div`
	margin-bottom: 40px;
	width: 100%;
	& .selected-lessons {
		width: 100%;
		border-radius: 4px;
		border: solid 1px var(--default-dark-gray);
		padding: 5px;

		& > .buttons {
			& > :not(:last-child) {
				margin-bottom: 5px;
			}

			button {
				svg {
					display: block;
				}
				&.down {
					svg {
						fill: var(--danger-color);
					}
				}
				&.up {
					svg {
						fill: var(--success-color);
					}
				}
			}

			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
		}

		& > .lesson-title {
			flex-grow: 1;
			color: var(--primary-color);
			font-size: var(--font-small);
			font-weight: 600;
		}

		& > :not(:last-child) {
			margin-right: 10px;
		}

		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	& > div:not(:last-child) {
		margin-bottom: 10px;
	}

	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
`

export {
	EditCourseContainer,
	LessonSelectionContainer,
	CourseThumbnail,
	ThumbnailUploadContainer,
	SortLessonsContainer,
}
