import React, { useContext, useEffect, useState } from 'react'
import getActualUser from 'helpers/get-actual-user'
import User from 'models/user'
import { BackgroundFlowers } from 'components'
import { Container, ContainerFluid } from './style'
import AppContext from 'contexts/AppContext'
import { LogoRegenesis, LogoExeltisOn } from 'assets'

const HomePage: React.FC = () => {
	const [user, setUser] = useState({} as User)
	const { adminType } = useContext(AppContext)

	useEffect(() => {
		;(async () => {
			setUser(await getActualUser())
		})()
	}, [])

	return (
		<ContainerFluid>
			<Container>
				<h2>Seja Bem Vindo(a), {user.name || 'Desconhecido'}!</h2>
				{adminType === 'gestante' ? (
					<img src={LogoRegenesis} alt="Exeltis" />
				) : (
					<img src={LogoExeltisOn} alt="Exeltis" />
				)}
			</Container>
		</ContainerFluid>
	)
}

export default HomePage
