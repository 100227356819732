import styled from 'styled-components'

const ArticlesContainer = styled.div``

const ChooseTypeOfArticleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	flex: 1;

	padding: 16px;
`

export { ArticlesContainer, ChooseTypeOfArticleContainer }
