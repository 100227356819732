import styled from 'styled-components'

const ModalsContainer = styled.div`
	z-index: 9;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.3);

	display: flex;
	justify-content: center;
	align-items: center;
`

export { ModalsContainer }
