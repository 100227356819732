import styled from 'styled-components'

const AdminTypeSwitchContainer = styled.button`
	position: absolute;
	top: 8px;
	right: 16px;
	padding: 8px;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	border-radius: 4px;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	gap: 8px;
	background: rgba(255, 255, 255, 0.4);

	span {
		color: #777;
		font-size: 1.2em;
		display: flex;
		align-items: center;
		gap: 4px;
	}

	img {
		height: 48px;
	}

	:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`

const SwitchAdminTypeModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.3);
	z-index: 999;
`

const SwitchAdminTypeModalContent = styled.div`
	padding: 32px;
	background: #efefef;
	border-radius: 8px;
`

const SwitchAdminTypeModalInfo = styled.div`
	color: #777;
	font-size: 1.2em;
	margin-bottom: 8px;
	text-decoration: underline;
`

const SwitchAdminTypeForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 8px;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
	}
`

export {
	AdminTypeSwitchContainer,
	SwitchAdminTypeModalContainer,
	SwitchAdminTypeModalContent,
	SwitchAdminTypeModalInfo,
	SwitchAdminTypeForm,
}
