import styled from 'styled-components'

export const ExtraMaterialFormContainer = styled.div`
	@keyframes fadeIn {
		0% {
			background: rgba(255, 255, 255, 0);
		}
		100% {
			background: rgba(255, 255, 255, 1);
		}
	}

	width: 80%;
	height: 80%;
	overflow-y: scroll;
	background: white;
	border-radius: 15px;
	animation: fadeIn 0.3s;
	padding: 8px 26px;

	.input-radios-check, .input-radios-check-file {
		margin-left: 10px;
		margin-right: 10px;
	}
`

export const ThumbnailUploadContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	img {
		margin-top: 10px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

export const ContentThumbnail = styled.img`
	max-width: 600px;
`

export const ExtraMaterialsInput = styled.input`
	padding: 10px;
	border: 1px solid var(--default-dark-gray);
	width: 100%;
	border-radius: 5px;
`
