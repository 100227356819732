import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 20px 50px 0 0;

	.pagination {
		font-size: 14px;
		font-weight: 600;
		display: flex;
		list-style-type: none;

		a {
			border: 1px solid #e9ecef;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #8898aa;
			cursor: pointer;
		}

		li {
			&.previous {
				margin-right: 21px;
				margin-left: 0;
			}

			&.next {
				margin-right: 0;
				margin-left: 21px;
			}

			&.active {
				a {
					border: 1px solid #5e72e4;
					background-color: #5e72e4;
					color: #fff;
					cursor: default;
					user-select: none;
					outline: none;
				}

				svg {
					color: #5e72e4 !important;
				}
			}

			& + li {
				margin-right: 0;
				margin-left: 10px;
			}
		}
	}
`
