export const LOGIN = '/'
export const HOME = '/home'

//Profile
export const PROFILE = '/profile'
export const EDIT_PROFILE = '/edit-profile/:userId'

// Products
export const PRODUCTS = '/products'
export const CREATE_PRODUCT = '/create-product'

export const EDIT_PRODUCT = '/edit-product'
export const EDIT_PRODUCT_BY_ID = (productId: string) => `${EDIT_PRODUCT}/${productId}`
export const EDIT_PRODUCT_BASE_ID = '/edit-product/:productId'

// Professionals
export const PROFESSIONALS = '/professionals'
export const CREATE_PROFESSIONALS = '/create-professionals'
export const EDIT_PROFESSIONALS = '/edit-professionals/:authorId'

// Users
export const USERS = '/users'
export const USERS_MEDIC = '/users-medic'

export const USERS_BY_ID = 'users/:userId'

// Tags
export const TAGS = '/tags'

// Courses
export const COURSES = '/courses'
export const EDIT_COURSES = '/edit-course/:courseId'

//Contents
export const CONTENTS = '/contents'
export const CREATE_CONTENTS = '/create-content'
export const EDIT_CONTENTS = '/edit-content/:contentId'

// Vídeos
export const VIDEOS = '/videos'
export const CREATE_VIDEO = '/videos/create-video'
export const EDIT_VIDEO = '/videos/edit-video/:contentId'

// Podcasts
export const PODCASTS = '/podcasts'
export const CREATE_PODCAST = '/podcasts/create-podcast'
export const EDIT_PODCAST = '/podcasts/edit-podcast/:contentId'

// Notícias
export const NEWS = '/news'
export const CREATE_NEWS = '/news/create-news'
export const EDIT_NEWS = '/news/edit-news/:newsId'

// Artigos
export const ARTICLES = '/articles'
export const CREATE_ARTICLE = '/articles/create-article/:type'
export const EDIT_ARTICLE = '/articles/edit-article/:articleId'

// Categories
export const CATEGORIES = '/categories'
export const CREATE_CATEGORY = '/categories/create-category'
export const EDIT_CATEGORY = '/categories/edit-category/:categoryId'

//CMS
export const TERMS_OF_USE = '/terms-of-use'
export const PRIVATE_POLITICS = '/private-politics'
export const WELCOME_VIDEO = '/welcome-video'
export const BANNERS = '/banners'
export const BANNERS_LANDING = '/banners_landing'
export const BANNERS_LANDING_EDIT = '/banners_landing/edit/:bannerId'
export const BANNERS_LANDING_CREATE = '/banners_landing/create'
export const CONTACT = '/contato'
export const CONTACTS = '/contatos'
export const FAQS = '/faqs'
export const CREATE_FAQS = '/create-faqs'
export const EDIT_FAQS = '/edit-faqs/:faqId'
export const HOME_INFO_CREATE = '/create-home-info/:type'
export const HOME_INFO_EDIT = '/edit-home-info/:infoId/:type'

//Reports
export const REPORTS_LOGS_USERS = '/reports/user-access'
export const REPORTS_LOGS_ENGAGE = '/reports/engage'
export const REPORTS_USERS = '/reports/users'
export const REPORTS_LIVES = '/reports/lives'


//Events
export const EVENTS = '/events'
export const CREATE_EVENT = '/create-event'
export const EDIT_EVENT = '/edit-event/:eventId'

//Lives
export const LIVES = '/lives'

//Error
export const ERROR_404 = '*'
