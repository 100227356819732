import { DefaultButton } from 'components'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import showErrorMessage from 'helpers/show-error-message'
import checkEmptyString from 'helpers/check-empty-string'
import { createTag as createTagService, getTag as getTagService, updateTag as updateTagService } from 'services/tags'
import Swal from 'sweetalert2'
import ModalContext from 'contexts/ModalContext'

const CreateAndEditTagContainer = styled.div``

interface CreateAndEditTagProps {
	tagId?: string
	onSubmit: () => void
}

const CreateAndEditTag: React.FC<CreateAndEditTagProps> = ({ tagId, onSubmit }) => {
	const { hideModal } = useContext(ModalContext)
	const [name, setName] = useState('')

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			validate()

			if (isEditing) {
				await updateTag()
			} else {
				await createTag()
			}

			await Swal.fire({ icon: 'success', text: `Tag ${isEditing ? 'editada' : 'criada'} com sucesso!` })

			onSubmit()

			goToTags()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const validate = () => {
		if (checkEmptyString(name)) {
			throw new Error('Informe um nome válido para a tag.')
		}
	}

	const createTag = async () => {
		await createTagService({ tag: name })
	}

	const updateTag = async () => {
		await updateTagService(tagId!, { tag: name })
	}

	const goToTags = () => {
		hideModal()
	}

	const getTag = async () => {
		const tag = await getTagService(tagId!)
		setName(tag.tag)
	}

	const isEditing = useMemo(() => {
		return !!tagId
	}, [tagId])

	useEffect(() => {
		if (isEditing) {
			getTag()
		}
	}, [isEditing])

	return (
		<CreateAndEditTagContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
			<DefaultCreationForm onSubmit={handleSubmit} style={{ flex: 1 }}>
				<DefaultCreationFormGroup style={{ flex: 1, justifyContent: 'flex-start' }}>
					<label htmlFor="name">Nome</label>
					<DefaultInput type="text" required value={name} onChange={(e) => setName(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToTags}>
						Cancelar
					</DefaultButton>
					<DefaultButton type="submit">Salvar</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditTagContainer>
	)
}

export default CreateAndEditTag
