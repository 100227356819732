import httpClient from 'http-client'
import ContentFromReponse from 'models/from-api-response/content'

const getArticles = async (type: string, params?: any) => {
	const monographies = (
		await httpClient.get<ContentFromReponse[]>(`/contents`, {
			params: { ...params, is_library: true, type: 'MONOGRAFIA' },
		})
	).data

	const commentedArticles = (
		await httpClient.get<ContentFromReponse[]>(`/contents`, {
			params: { ...params, is_library: true, type: 'ARTIGO_COMENTADO' },
		})
	).data

	const cientificArticles = (
		await httpClient.get<ContentFromReponse[]>(`/contents`, {
			params: { ...params, is_library: true, type: 'ARTIGO_CIENTIFICO' },
		})
	).data

	return { commentedArticles, cientificArticles, monographies }
}

const deleteArticle = async (articleId: string) => {
	return (await httpClient.delete(`/contents/${articleId}`)).data
}

const getArticle = async (articleId: string) => {
	return (await httpClient.get(`/contents/${articleId}`)).data
}

const createArticle = async (newArticle: any) => {
	return (await httpClient.post(`/contents`, { ...newArticle, is_library: true })).data
}

const updateArticle = async (articleId: string, newArticle: any) => {
	return (await httpClient.put(`/contents/${articleId}`, { ...newArticle, is_library: true })).data
}

export { getArticles, createArticle, updateArticle, getArticle, deleteArticle }
