import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DefaultButton from 'components/DefaultButton'
import DefaultTable from 'components/DefaultTable'
import Pagination from 'components/Pagination'
import Content from 'models/content'
import { getAllContents } from 'services/contents'
import { SelectLessonContainer } from './style'

interface SelectLessonProps {
	selectedLessons: Content[]
	onApplySelection: (selectedLessons: Content[]) => void
}

const SelectLesson: React.FC<SelectLessonProps> = ({ selectedLessons, onApplySelection }) => {
	const [lessons, setLessons] = useState([] as any[])
	const [totalPages, setTotalPages] = useState(0)
	const [page, setPage] = useState(1)
	const coursesPerPage = 10

	const getLessons = async () => {
		const localLessons = await getAllContents({ is_active: true })
		if (localLessons && localLessons.length) {
			setTotalPages(Math.ceil(localLessons.length / coursesPerPage))
		}
	}

	const handleCheckLesson = useCallback(
		(event: React.FormEvent<HTMLInputElement>, lesson) => {
			if (!selectedLessons.find((selectedLesson) => selectedLesson.content_id === lesson.id)) {
				selectedLessons.push(lesson)

				event.currentTarget.checked = true
			} else {
				const indexOfLesson = selectedLessons.map((les) => les.content_id).indexOf(lesson.id)

				if (indexOfLesson > -1 && indexOfLesson < selectedLessons.length) {
					selectedLessons.splice(indexOfLesson, 1)

					event.currentTarget.checked = false
				}
			}
		},
		[selectedLessons]
	)

	const applySelection = (event: React.FormEvent<HTMLButtonElement>) => {
		event.preventDefault()
		onApplySelection(selectedLessons)
	}

	const lessonsToBeShown = useMemo(() => {
		return lessons.map((lesson) => {
			const shouldBeChecked = selectedLessons.map((lesson) => lesson.content_id).includes(lesson.id)

			return {
				thumbnail: <img src={lesson.thumbnail} style={{ width: '300px' }} alt="thumbnail" />,
				title: lesson.title,
				description: lesson.description,
				check: (
					<>
						<input
							type="checkbox"
							value={lesson.id}
							defaultChecked={shouldBeChecked}
							onChange={(e) => handleCheckLesson(e, lesson)}
						/>
					</>
				),
			}
		})
	}, [lessons, selectedLessons, handleCheckLesson])

	useEffect(() => {
		getLessons()
	}, [])

	useEffect(() => {
		;(async () => {
			setLessons([])
			const filter = {
				limit: coursesPerPage,
				offset: page > 1 ? (page - 1) * coursesPerPage : undefined,
				is_active: true,
			}

			const localLessons = await getAllContents(filter)
			if (localLessons && localLessons.length) {
				setLessons(localLessons)
			}
		})()
	}, [page])

	return (
		<SelectLessonContainer>
			<DefaultTable
				headersConfig={[
					{ headerLabel: <span>Thumbnail</span>, propName: 'thumbnail' },
					{ headerLabel: <span>Título</span>, propName: 'title' },
					{ headerLabel: <span>Descrição</span>, propName: 'description' },
					{ headerLabel: <span>Selecionar</span>, propName: 'check' },
				]}
				items={lessonsToBeShown}
			/>
			{totalPages > 1 && <Pagination totalPages={totalPages} setPage={setPage} />}
			<DefaultButton onClick={applySelection}>Aplicar Seleção</DefaultButton>
		</SelectLessonContainer>
	)
}

export default SelectLesson
