import React, { useContext } from 'react'
import { BackgroundFlowers } from 'components'
import { Container } from './style'
import AppContext from 'contexts/AppContext'
import { LogoRegenesis, LogoExeltisOn } from 'assets'

type ErrorProps = {
	type: 'permission' | 'loadingError' | 'Error404'
}

const ErrorPage: React.FC<ErrorProps> = ({ type }) => {
	const { adminType } = useContext(AppContext)

	return (
		<BackgroundFlowers>
			<Container>
				<h2>Tem algo errado...</h2>
				{type === 'Error404' && <h3>erro 404: A página que você procura não existe.</h3>}
				{type === 'permission' && <h3>A página que você procura não está disponível</h3>}
				{type === 'loadingError' && <h3>O carregamento da página falhou</h3>}
				{adminType === 'gestante' ? (
					<img src={LogoRegenesis} alt="Exeltis" />
				) : (
					<img src={LogoExeltisOn} alt="Exeltis" />
				)}
			</Container>
		</BackgroundFlowers>
	)
}

export default ErrorPage
