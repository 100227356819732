import styled from 'styled-components'

const DefaultCreationForm = styled.form`
	& > :not(:last-child) {
		margin-bottom: 10px;
	}

	& .react-datepicker-wrapper {
		flex-grow: 1;

		input {
			width: 100%;
			height: 40px;
			border-radius: 5px;
			border: solid 1px var(--default-dark-gray);
			padding: 10px 15px;
		}
	}

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
`

export const DefaultCreationFormGroup = styled.div`
	width: 100%;

	label {
		min-width: 145px;
		margin-bottom: 10px;
		font-size: var(--font-small);
		color: var(--primary-color);
		font-weight: 700;

		&.required {
			::after {
				content: '*';
				color: red;
			}
		}
	}

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	${props => props.className === "speakers-form" ? `
		border-left: 4px solid var(--primary-color);
		padding: 8px 0 0 15px;
	` : ""
	}

	.observation-img {
		font-size: var(--font-small);
		color: grey;
		margin-bottom: 20px;
	}
`

export const DefaultCreationFormButtonGroup = styled.div`
	width: 100%;
	margin: 10px 0;

	& > :not(:last-child) {
		margin-right: 10px;
	}

	display: flex;
	justify-content: flex-${props => props.className === "speaker-buttons" ? "start" : "end"};
	align-items: center;
`

export default DefaultCreationForm
