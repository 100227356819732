import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './pages/Login'
import Loading from './components/Loading'
import 'react-datepicker/dist/react-datepicker.css'
import AppContextProvider from 'components/AppContextProvider'

ReactDOM.render(
	<React.StrictMode>
		<AppContextProvider>
			<Router>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/login" component={Login} />

					<Route component={App} />
				</Switch>
			</Router>
			<Loading />
		</AppContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
