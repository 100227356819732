import React, { useEffect } from 'react'
import sanitizeHtml from 'sanitize-html'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const textareaStyle = {
	border: '1px solid #ced4da',
	borderRadius: '0.25rem',
	flex: '1 1 80%',
}

const toolbarOptions = {
	options: [
		'inline',
		'blockType',
		'fontSize',
		'list',
		'textAlign',
		'colorPicker',
		'link',
		'emoji',
		'image',
		'remove',
		'history',
	],
}

type DescriptionArea = {
	description: any
	setEditorState: any
	editorState: any
}

const getCleanHtml = (editorState: any) => {
	const descriptionHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))

	return sanitizeHtml(descriptionHtml, {
		allowedAttributes: {
			...sanitizeHtml.defaults.allowedAttributes,
			span: ['style'],
			p: ['style'],
		},
	})
}

const DescriptionTextarea: React.FC<DescriptionArea> = ({ description, setEditorState, editorState }) => {
	const onEditorStateChange = (editorState: any) => {
		setEditorState(editorState)
	}

	useEffect(() => {
		const contentBlock = htmlToDraft(description)
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
			const editorStateCreated = EditorState.createWithContent(contentState)
			setEditorState(editorStateCreated)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [description])

	return (
		<div className="form-group row">
			<div style={textareaStyle}>
				<Editor
					toolbar={toolbarOptions}
					editorStyle={{ margin: '0 1rem', maxHeight: '60vh' }}
					editorState={editorState}
					onEditorStateChange={onEditorStateChange}
				/>
			</div>
		</div>
	)
}

export { getCleanHtml, DescriptionTextarea }
