import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import * as Style from './style'
import { login as loginService } from 'services/login'
import { DefaultButton } from 'components'
import { LogoRegenesis, LogoRegenesisWhite, LogoExeltisOn } from 'assets'
import showErrorMessage from '../../helpers/show-error-message'
import checkEmptyString from 'helpers/check-empty-string'
import AppContext from 'contexts/AppContext'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

const showSwitchAdminTypeModal = async (setAdminType: (adminType: 'gestante' | 'medico') => void) => {
	const handleChangeAdminType = (resolve: (value: string) => void, adminType: 'gestante' | 'medico') => {
		setAdminType(adminType)
		resolve(adminType)
		hideModal()
	}

	const hideModal = () => {
		const adminTypeModals = Array.from(document.querySelectorAll('#admin-type-modal'))
		for (let modal of adminTypeModals) {
			modal.remove()
		}
	}

	hideModal()

	const modalPromise = new Promise((resolve: (value: string) => void, reject) => {
		const Modal = (
			<Style.SwitchAdminTypeModalContainer>
				<Style.SwitchAdminTypeModalContent>
					<Style.SwitchAdminTypeModalQuestion>
						Qual plataforma deseja administrar?
						<Style.AdminTypeButtonsContainer>
							<Style.AdminTypeButton
								type="button"
								onClick={() => handleChangeAdminType(resolve, 'medico')}
							>
								<img src={LogoExeltisOn} alt="Logo" />
							</Style.AdminTypeButton>
							<span>Ou</span>
							<Style.AdminTypeButton
								type="button"
								onClick={() => handleChangeAdminType(resolve, 'gestante')}
							>
								<img src={LogoRegenesis} alt="Logo" />
							</Style.AdminTypeButton>
						</Style.AdminTypeButtonsContainer>
					</Style.SwitchAdminTypeModalQuestion>
				</Style.SwitchAdminTypeModalContent>
			</Style.SwitchAdminTypeModalContainer>
		)

		const modalDiv = document.createElement('div')
		modalDiv.id = 'admin-type-modal'
		document.body.appendChild(modalDiv)
		ReactDOM.render(Modal, modalDiv)
	})

	return modalPromise
}

const Login: React.FC = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const history = useHistory()
	const { setAdminType } = useContext(AppContext)

	const validate = () => {
		if (checkEmptyString(email)) {
			throw new Error('Informe seu email.')
		}

		if (checkEmptyString(password)) {
			throw new Error('Informe sua senha.')
		}
	}

	const login = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			validate()

			const localAdminType = await showSwitchAdminTypeModal(setAdminType)

			const isMedic = localAdminType === 'medico'
			const token = await loginService(email, password, isMedic)
			if (token) {
				history.push('/home')
			} else {
				throw new Error('Dados de login incorretos')
			}
		} catch (error) {
			showErrorMessage(error, 'Erro ao fazer Login.')
		}
	}

	return (
		<Style.Container>
			<Style.LogoContainer>
				<CarouselProvider
					isPlaying={true}
					interval={1500}
					totalSlides={2}
					naturalSlideWidth={930}
					naturalSlideHeight={920}
				>
					<Slider>
						<Slide index={0}>
							<Style.LogoContainerGestante className="img-dash-desktop">
								<div>
									<img src={LogoRegenesisWhite} alt="Logo" />
								</div>
							</Style.LogoContainerGestante>
							<Style.LogoContainerGestante className="img-dash-mobile">
								<div>
									<img src={LogoRegenesis} alt="Logo" />
								</div>
							</Style.LogoContainerGestante>
						</Slide>
						<Slide index={1}>
							<Style.LogoContainerMedico>
								<img src={LogoExeltisOn} alt="Logo" style={{ width: '200px' }} />
							</Style.LogoContainerMedico>
						</Slide>
					</Slider>
				</CarouselProvider>
			</Style.LogoContainer>
			<Style.LoginContainer>
				<Style.LoginBlock>
					<Style.LoginTitle>Que bom te ver aqui!</Style.LoginTitle>
					<Style.LoginDescription>
						Seja bem vindo a <strong>plataforma administrativa</strong> da Exeltis.
					</Style.LoginDescription>
					<Style.LoginForm onSubmit={login}>
						<Style.LoginLabel>
							Login
							<Style.LoginInput
								type="email"
								placeholder="Digite seu e-mail"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Style.LoginLabel>
						<Style.LoginLabel>
							Senha
							<Style.LoginInput
								type="password"
								placeholder="Senha"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Style.LoginLabel>

						<div className="container-btn">
							<DefaultButton className="default" type="submit" value="Entrar">
								Entrar
							</DefaultButton>
						</div>
					</Style.LoginForm>
				</Style.LoginBlock>
			</Style.LoginContainer>
		</Style.Container>
	)
}

export default Login
