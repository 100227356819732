import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LogoContainer, LogoutButton, Menus, SideBarContainer } from './style'
import { Link, useHistory, useLocation } from 'react-router-dom'
import getMenus, { Menu } from './data/menus'
import { BiLogOut } from 'react-icons/bi'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import { LogoExeltisOn, LogoRegenesis } from 'assets'
import AppContext from 'contexts/AppContext'

const SideBar: React.FC = () => {
	const history = useHistory()
	const location = useLocation()
	const { adminType } = useContext(AppContext)
	const [menus, setMenus] = useState([] as Menu[])

	const [menuChildrenToBeShown, setMenuChildrenToBeShown] = useState<number[]>([])

	const showOrHideMenuChildren = (index: number) => {
		if (menuChildrenToBeShown.includes(index)) {
			const indexOfMenu = menuChildrenToBeShown.indexOf(index)
			if (indexOfMenu > -1 && indexOfMenu < menuChildrenToBeShown.length) {
				menuChildrenToBeShown.splice(indexOfMenu, 1)
			}
		} else {
			menuChildrenToBeShown.push(index)
		}

		setMenuChildrenToBeShown([...menuChildrenToBeShown])
	}

	const checkShouldShowMenuChildren = useCallback(
		(index: number) => {
			return menuChildrenToBeShown.includes(index)
		},
		[menuChildrenToBeShown]
	)

	const logout = () => {
		window.localStorage.removeItem('exeltis-api-token-admin')
		history.push('/home')
	}

	useEffect(() => {
		const menus = getMenus(adminType)
		setMenus(menus)
	}, [adminType])

	return (
		<SideBarContainer>
			<LogoContainer>
				{adminType === 'gestante' ? (
					<img
						src={LogoRegenesis}
						alt="Logo"
						onClick={() => {
							history.push('/home')
						}}
					/>
				) : (
					<img
						src={LogoExeltisOn}
						alt="Logo"
						onClick={() => {
							history.push('/home')
						}}
					/>
				)}
			</LogoContainer>
			<Menus>
				{menus && menus.length ? (
					menus.map((menu, index) =>
						menu.children && menu.children.length ? (
							<div key={index}>
								<button onClick={() => showOrHideMenuChildren(index)}>
									{menu.label}
									<span
										className={`sub-items ${menuChildrenToBeShown.includes(index) ? 'active' : ''}`}
									>
										<Arrow />
									</span>
								</button>
								<div style={{ marginLeft: '25px' }}>
									{menu.children?.length && checkShouldShowMenuChildren(index) ? (
										menu.children.map((child) =>
											child.path ? (
												<Link
													className={location.pathname === child.path ? 'active' : ''}
													key={child.path}
													to={child.path}
												>
													{child.label}
												</Link>
											) : (
												<></>
											)
										)
									) : (
										<></>
									)}
								</div>
							</div>
						) : menu.path ? (
							<Link
								className={location.pathname === menu.path ? 'active' : ''}
								key={menu.path}
								to={menu.path}
							>
								{menu.label}
							</Link>
						) : (
							<></>
						)
					)
				) : (
					<></>
				)}

				<LogoutButton onClick={logout}>
					<span className="icon">
						<BiLogOut />
					</span>
					<span className="text">Logout</span>
				</LogoutButton>
			</Menus>
		</SideBarContainer>
	)
}

export default SideBar
