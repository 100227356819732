import styled from 'styled-components'

const CreateAndEditProductContainer = styled.div`
	.color-picker-container {
		margin: auto;

		.chrome-picker {
			label {
				min-width: unset;
			}
		}
	}
`

const ButtonGroupInline = styled.div`
	display: flex;
	align-items: center;

	button + button {
		margin-left: 10px;
	}
`

const ContentUploadOrSelectContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	iframe {
		margin-top: 10px;
		width: 600px;
		height: 340px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const ThumbnailUploadContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;

	img {
		margin-top: 10px;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const ContentThumbnail = styled.img`
	max-width: 543px;
`

const ProductColor = styled.div`
	${(props) => (props.color ? `background-color: ${props.color};` : '')}
	width: 100px;
	height: 40px;
	margin: 10px auto;
	border: solid 1px var(--default-dark-gray);
`

export {
	CreateAndEditProductContainer,
	ContentUploadOrSelectContainer,
	ThumbnailUploadContainer,
	ContentThumbnail,
	ButtonGroupInline,
	ProductColor,
}
