import { createContext } from 'react'

interface IModalContext {
	openModal: (title: string, content: JSX.Element) => void
	hideModal: (modalId?: string) => void
	modals: JSX.Element[]
}

const ModalContext = createContext({} as IModalContext)

export default ModalContext
