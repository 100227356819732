import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultButton, DefaultPageTitle, CreateButtonDefaultContainer, DefaultTable } from 'components'
import convertSecondsToTimeText from 'helpers/seconds-to-time-text'
import Content from 'models/content'
import {
	deleteContent,
	getAllContents,
	activateOrInactivateContent as activateOrInactivateContentService,
} from 'services/contents'

import { ContentsContainer } from './style'

const Contents: React.FC = () => {
	const history = useHistory()

	const [contents, setContents] = useState([] as Content[])

	const getContents = async () => {
		const localContents = await getAllContents()
		if (localContents && localContents.length) {
			setContents(localContents)
		}
	}

	const editContent = useCallback(
		(contentId: string) => {
			history.push(`edit-content/${contentId}`)
		},
		[history]
	)

	const removeContent = useCallback(async (contentId: string) => {
		Swal.fire({
			title: '<strong>Confirmação</strong>',
			html: 'Tem certeza que deseja remover esta aula?',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			focusConfirm: false,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await deleteContent(contentId)

					Swal.fire({
						icon: 'success',
						title: 'Sucesso!',
						text: 'Aula excluida com sucesso!',
					})

					await getContents()
				} catch (error: any) {
					let errorText = ''
					switch (error.response.data.message) {
						case 'Unexpected error deleting content - This content can not be deleted. It is already enrolled in a course':
							errorText = ' Aula vinculada a um módulo.'
							break

						default:
							errorText = error.response.data.message
							break
					}

					Swal.fire({
						icon: 'error',
						title: 'Erro',
						text: `Erro ao excluir aula. ${errorText}`,
					})
				}
			}
		})
	}, [])

	const createContent = async () => {
		history.push('create-content')
	}

	const activateOrInactivateContent = useCallback(async (contentId: string, activate: boolean) => {
		Swal.fire({
			title: 'Confirmação',
			text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} esta aula?`,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			focusConfirm: false,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await activateOrInactivateContentService(contentId, activate)

					Swal.fire({
						icon: 'success',
						title: 'Sucesso!',
						text: `Curso ${activate ? 'ativado' : 'inativado'} com sucesso!`,
					})

					await getContents()
				} catch (error: any) {
					let errorText = ''
					switch (error.response.data.message) {
						case 'Unexpected error activating/inactivating content - This content can not be inactivated. It is already enrolled in a course':
							errorText = ' Aula vinculada a um módulo.'
							break

						default:
							errorText = error.response.data.message
							break
					}
					Swal.fire({
						icon: 'error',
						title: 'Erro',
						text: `Erro ao ${activate ? 'ativar' : 'inativar'} aula. ${errorText}`,
					})
				}
			}
		})
	}, [])

	const contentsToBeShown = useMemo(() => {
		return contents && contents.length
			? contents.map((content) => ({
					title: content.title,
					duration: convertSecondsToTimeText(content.duration),
					active: content.active ? <AiOutlineCheck /> : <AiOutlineClose />,
					actions: (
						<>
							<DefaultButton
								onClick={() => editContent(content.id)}
								className="small info"
								title="Editar Aula"
							>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => activateOrInactivateContent(content.id, !content.active)}
								className="small warning"
								title={(content.active ? 'Inativar' : 'Ativar') + ' aula'}
							>
								{content.active ? <AiOutlineClose /> : <AiOutlineCheck />}
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => {
									removeContent(content.id)
								}}
								className="small danger"
								title="Excluir Aula"
							>
								<BiTrash />
							</DefaultButton>
						</>
					),
			  }))
			: []
	}, [contents, editContent, removeContent, activateOrInactivateContent])

	useEffect(() => {
		getContents()
	}, [])

	return (
		<ContentsContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Aulas</span>]} />

			<DefaultPageTitle>Aulas</DefaultPageTitle>

			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createContent}>Criar Aula</DefaultButton>
			</CreateButtonDefaultContainer>

			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Título</span>,
						propName: 'title',
					},
					{
						headerLabel: <span>Duração</span>,
						propName: 'duration',
					},
					{
						headerLabel: <span>Ativo</span>,
						propName: 'active',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={contentsToBeShown}
				emptyListMessage={'Não foram encontrados aulas cadastradas!'}
			/>
		</ContentsContainer>
	)
}

export default Contents
