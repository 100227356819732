import { HOME, LOGIN, ERROR_404 } from './Constants'
import { Redirect, Route, RouteProps } from 'react-router-dom'

export const PrivateRouter = ({ children, ...rest }: RouteProps) => {
	const isUserLogged = localStorage.getItem('exeltis-api-token-admin')

	if (!!isUserLogged === false) {
		return (
			<Route path={rest.path}>
				<Redirect to={rest.location?.pathname === HOME ? LOGIN : ERROR_404} />
			</Route>
		)
	}

	return <Route {...rest}>{children}</Route>
}

export default PrivateRouter
