import styled from 'styled-components'
import StarsBackground from '../../assets/stars-background.png'

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;

	@media (max-width: 768px) {
		background-color: var(--white);
		flex-direction: column;
	}
`

export const LogoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 0 50%;
	position: relative;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.img-dash-desktop {
		img {
			width: 300px;
		}
	}

	.img-dash-mobile {
		display: none;
	}

	&:after {
		content: '';
		background-image: url(${StarsBackground});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	@media (max-width: 768px) {
		background: transparent;
		flex: 1;
		margin: 20vw 0;

		&:after {
			background-image: none;
		}

		.img-dash-desktop {
			display: none;
		}

		.img-dash-mobile {
			display: block;
		}
	}

	.description-admin {
		font-size: 20px;
		font-weight: 400;
		text-transform: uppercase;
	}
`

export const LoginContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 50%;
	padding: 0 20px;

	@media (max-width: 768px) {
		align-items: flex-start;
	}
`

export const LoginBlock = styled.div`
	max-width: 500px;
	width: 100%;
`

export const LoginTitle = styled.h1`
	font-size: 3rem;
	font-weight: 700;
	margin-bottom: 16px;
	color: var(--purple);

	@media (max-width: 768px) {
		font-size: 2rem;
		font-weight: 500;
		text-align: center;
		font-family: 'Nunito Sans ExtraBold';
	}
`

export const LoginDescription = styled.p`
	font-size: 1.2rem;
	margin-bottom: 40px;
	line-height: 32px;

	strong {
		color: var(--blue);
	}

	@media (max-width: 768px) {
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 28px;
		text-align: center;

		strong {
			font-weight: 400;
		}
	}
`

export const LoginForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.container-btn {
		margin: 30px 0;
		width: 100%;
	}
`

export const LoginLabel = styled.label`
	width: 100%;
	font-weight: bold;
	font-size: 1.2rem;

	&:not(:first-child) {
		margin-top: 32px;
	}
`

export const LoginInput = styled.input`
	font-family: 'Nunito Sans SemiBold';
	font-size: 16px;
	padding: 10px;

	&[type='email'],
	&[type='password'] {
		width: 100%;
		margin-top: 8px;
		font-size: 1.4rem;
		border: 1px solid rgba(155, 153, 175, 0.31);
		border-radius: 8px;
		padding: 8px;
		outline: none;
	}

	&[type='submit'] {
		color: var(--white);
		background-color: var(--blue);
		border-radius: 100px;
		border: none;
		line-height: 25px;
		padding: 8px;
		width: 147px;
		font-size: 1.8rem;
		font-weight: 800;
		margin-top: 32px;
		cursor: pointer;
	}
`

export const AdminTypeButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 8px;
`

export const AdminTypeButton = styled.button`
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	padding: 8px;
	transition: all 0.5s;
	height: 120px;
	border-radius: 4px;

	:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`

export const LogoContainerMedico = styled.div`
	height: 100%;
	width: 100%;
	background: radial-gradient(57.45% 101.51% at 50% 50%, #9a6ce4 0%, #57187d 100%);
`

export const LogoContainerGestante = styled.div`
	height: 100%;
	width: 100%;
	background: radial-gradient(57.45% 101.51% at 50% 50%, #e46c9a 0%, #7d1857 100%);
`

export const SwitchAdminTypeModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.3);
	z-index: 999;
`

export const SwitchAdminTypeModalContent = styled.div`
	padding: 32px;
	background: #efefef;
	border-radius: 8px;
`

export const SwitchAdminTypeModalQuestion = styled.h4`
	color: #777;
	font-size: 1.2em;
`
