import BreadCrumb from 'components/BreadCrumb'
import DefaultPageTitle from 'components/DefaultPageTitle'
import DefaultTable from 'components/DefaultTable'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Live from 'models/live'
import { HeaderConfig } from 'components/DefaultTable'
import { changeLiveStatus, deleteLiveService, getAllLives, stopLiveService } from 'services/live'
import { BiCopy, BiStop, BiPlay, BiTrash } from 'react-icons/bi'
import DefaultButton from 'components/DefaultButton'
import Swal from 'sweetalert2'

enum LiveStatusEnum {
	CREATING = 'CREATING',
	STARTING = 'STARTING',
	ACTIVE = 'ACTIVE',
	STOPPING = 'STOPPING',
	STOPPED = 'STOPPED',
	SCHEDULED = 'SCHEDULED',
	FINISHED = 'FINISHED',
	ERROR = 'ERROR',
	TO_STOP = 'TO_STOP',
}

const Lives: React.FC = () => {
	const [lives, setLives] = useState<Live[]>([])

	const getLives = useCallback(async () => {
		const response = await getAllLives()
		if (response && response.length) {
			setLives(response)
		}
	}, [])

	const stopLive = useCallback(
		async (liveId: string, status: string) => {
			Swal.fire({
				icon: 'question',
				text: 'Tem certeza que deseja parar esta live?',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						await changeLiveStatus(liveId, status)
						Swal.fire({
							icon: 'success',
							text: 'Live parada com sucesso!',
						})
						await getLives()
					} catch {
						Swal.fire({
							icon: 'error',
							text: 'Houve um erro ao parar a live.',
						})
					}
				}
			})
		},
		[getLives]
	)

	const startLive = useCallback(
		async (liveId: string, status: string) => {
			Swal.fire({
				icon: 'question',
				text: 'Tem certeza que deseja iniciar esta live?',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						await changeLiveStatus(liveId, status)
						Swal.fire({
							icon: 'success',
							text: 'Live iniciada com sucesso!',
						})
						await getLives()
					} catch {
						Swal.fire({
							icon: 'error',
							text: 'Houve um erro ao iniciar a live.',
						})
					}
				}
			})
		},
		[getLives]
	)

	const deleteLive = useCallback(
		async (liveId: string) => {
			Swal.fire({
				icon: 'question',
				text: 'Tem certeza que deseja excluir esta live?',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						await deleteLiveService(liveId)
						Swal.fire({
							icon: 'success',
							text: 'Live excluída com sucesso!',
						})
						await getLives()
					} catch {
						Swal.fire({
							icon: 'error',
							text: 'Houve um erro ao iniciar a live.',
						})
					}
				}
			})
		},
		[getLives]
	)

	const copyRMTP = useCallback(
		async (liveId: string) => {
			const actualLive = lives.find((live) => live.live_id === liveId)
			if (!actualLive) {
				Swal.fire({ text: 'Erro ao tentar copiar RMTP', icon: 'error' })
				return
			}
			const rtmp = actualLive.rtmp

			const actualDocument = document.getElementById('root')

			const rtmpInput = document.createElement('input')
			rtmpInput.value = rtmp || ''
			actualDocument?.appendChild(rtmpInput)
			rtmpInput.select()
			rtmpInput.setSelectionRange(0, 99999)
			navigator.clipboard.writeText(rtmpInput.value)
			rtmpInput.remove()
			Swal.fire({ text: `RTMP "${rtmp}" copiado!`, icon: 'info' })
		},
		[lives]
	)

	useEffect(() => {
		getLives()
	}, [getLives])

	const convertToDateString = (date: Date) => {
		return new Intl.DateTimeFormat('pt-BR', {
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
		}).format(date)
	}

	const livesToBeShown = useMemo(() => {
		const liveStatus = {
			[LiveStatusEnum.CREATING]: 'A live está sendo criada',
			[LiveStatusEnum.STARTING]: 'A live está sendo criada',
			[LiveStatusEnum.ACTIVE]: 'A live está ativa',
			[LiveStatusEnum.STOPPING]: 'A live está sendo encerrada',
			[LiveStatusEnum.STOPPED]: 'A live está encerrada',
			[LiveStatusEnum.SCHEDULED]: 'A live está agendada',
			[LiveStatusEnum.FINISHED]: 'A live está encerrada',
			[LiveStatusEnum.ERROR]: 'Erro ao criar a live',
			[LiveStatusEnum.TO_STOP]: 'Live irá encerrar em instantes',
		}

		return lives.map((live) => ({
			id: live?.live_id,
			name: live?.name,
			description: live?.description?.length > 170 ? live?.description?.substring(0, 170) + '...' : live?.description,
			start: convertToDateString(live.start),
			stop: convertToDateString(live.stop),
			status:
				live.status === 'ACTIVE' && liveStatus[live.task_status as LiveStatusEnum]
					? liveStatus[live.task_status as LiveStatusEnum]
					: liveStatus[live.status as LiveStatusEnum],
			relatedToCourse: live?.content ? live?.content?.name : '',
			actions: (
				<>
					{live.status === 'ACTIVE' && (
						<>
							<DefaultButton
								title="Parar Live"
								className="small warning"
								style={{ color: 'white', margin: '0 2px' }}
								onClick={() => stopLive(live.live_id, 'STOPPED')}
							>
								<BiStop />
							</DefaultButton>
							<DefaultButton
								onClick={() => copyRMTP(live.live_id)}
								className="small info"
								title="Copiar RMTP"
								style={{ margin: '0 2px' }}
							>
								<BiCopy />
							</DefaultButton>
						</>
					)}
					{(live.status === 'SCHEDULED' || live.status === 'STOPPED') && (
						<>
							<DefaultButton
								onClick={() => copyRMTP(live.live_id)}
								className="small info"
								title="Copiar RMTP"
								style={{ margin: '0 2px' }}
							>
								<BiCopy />
							</DefaultButton>
							<DefaultButton
								onClick={() => startLive(live.live_id, 'ACTIVE')}
								className="small success"
								title="Iniciar Live"
								style={{ margin: '0 2px' }}
							>
								<BiPlay />
							</DefaultButton>
							<DefaultButton
								title="Parar Live"
								className="small warning"
								style={{ color: 'white', margin: '0 2px' }}
								onClick={() => stopLive(live.live_id, 'STOPPED')}
							>
								<BiStop />
							</DefaultButton>
							<DefaultButton
								title="Excluir Live"
								className="small danger"
								style={{ color: 'white', margin: '0 2px' }}
								onClick={() => deleteLive(live.live_id)}
							>
								<BiTrash />
							</DefaultButton>

						</>
					)}

				</>
			),
		}))
	}, [copyRMTP, lives, stopLive])

	const getHeaders = () => {
		let headers: HeaderConfig[] = [
			{
				headerLabel: <span>Título</span>,
				propName: 'name',
			},
			{
				headerLabel: <span>Descrição</span>,
				propName: 'description',
			},
			{
				headerLabel: <span>Inicio</span>,
				propName: 'start',
			},
			{
				headerLabel: <span>Fim</span>,
				propName: 'stop',
			},
			{
				headerLabel: <span>Status</span>,
				propName: 'status',
			},
			{
				headerLabel: <span>Ações</span>,
				propName: 'actions',
				noWrap: true,
			}
		]

		return headers;
	}

	return (
		<>
			<BreadCrumb crumbs={[<Link to="/home">Home</Link>, <span>Lives</span>]} />
			<DefaultPageTitle>Lives</DefaultPageTitle>
			<DefaultTable
				usePagination={false}
				headersConfig={getHeaders()}
				items={livesToBeShown}
				emptyListMessage={'Não foram encontradas lives cadastradas!'}
			/>
		</>
	)
}

export default Lives
