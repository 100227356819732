import httpClient from 'http-client'
import FAQ from 'models/faq'
import FAQCreate from 'models/for-create/faq'
import FAQUpdata from 'models/for-update/faq'

const getFAQS = async () => {
	const faqs = (await httpClient.get<FAQ[]>('faqs')).data

	if (faqs && faqs.length) {
		
		return faqs
	}

	return []
}

const deleteFAQS = async (faqId: string) => {
	await httpClient.delete(`faqs/${faqId}`)
}

const getFAQ = async (faqId: string) => {
	const faq = (await httpClient.get<FAQ>(`faqs/${faqId}`)).data

	return faq
}

const createFAQ = async (faq: FAQCreate) => {
	const newFaq = (await httpClient.post('faqs', faq)).data

	return newFaq
}

const updateFAQ = async (faq: FAQUpdata, faqId: string) => {
	const updateFaq = (await httpClient.put(`faqs/${faqId}`, faq)).data

	return updateFaq
}

export { getFAQS, deleteFAQS, getFAQ, createFAQ, updateFAQ }
