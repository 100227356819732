import {
	PRODUCTS,
	TERMS_OF_USE,
	PRIVATE_POLITICS,
	WELCOME_VIDEO,
	BANNERS,
	FAQS,
	REPORTS_LOGS_ENGAGE,
	REPORTS_LOGS_USERS,
	VIDEOS,
	PODCASTS,
	ARTICLES,
	NEWS,
	CONTACTS,
	CONTACT,
	BANNERS_LANDING,
	REPORTS_USERS,
	EVENTS,
	LIVES,
	REPORTS_LIVES,
} from 'components/Routes/Constants'
import { AiFillAudio, AiOutlineTags, AiOutlineUser, AiOutlineUsergroupAdd, AiOutlineSchedule } from 'react-icons/ai'
import { IoLibraryOutline, IoDocumentsSharp } from 'react-icons/io5'
import { RiArticleLine, RiGitRepositoryPrivateFill } from 'react-icons/ri'
import { ImPencil, ImFileVideo } from 'react-icons/im'
import { BsImage, BsCardList, BsNewspaper, BsFillCameraVideoFill, BsCollectionPlay } from 'react-icons/bs'
import { FaQuestion } from 'react-icons/fa'
import { GrContactInfo, GrDocumentPerformance } from 'react-icons/gr'
import { BiUserCheck } from 'react-icons/bi'
import { FiUsers } from 'react-icons/fi'
import { CgCamera } from 'react-icons/cg'

const menus = [
	{
		label: (
			<>
				<span className="icon">
					<AiOutlineUser />
				</span>
				<span className="text">Perfil</span>
			</>
		),
		path: '/profile',
	},
	{
		label: (
			<>
				<span className="icon">
					<AiOutlineUsergroupAdd />
				</span>
				<span className="text">Usuários</span>
			</>
		),
		path: '/users-medic',
	},
	{
		label: (
			<>
				<span className="icon">
					<AiOutlineTags />
				</span>
				<span className="text">Tags</span>
			</>
		),
		path: '/tags',
	},
	{
		label: (
			<>
				<span className="icon">
					<BsFillCameraVideoFill />
				</span>
				<span className="text">Vídeos</span>
			</>
		),
		path: VIDEOS,
	},
	{
		label: (
			<>
				<span className="icon">
					<AiFillAudio />
				</span>
				<span className="text">Podcasts</span>
			</>
		),
		path: PODCASTS,
	},
	{
		label: (
			<>
				<span className="icon">
					<RiArticleLine />
				</span>
				<span className="text">Artigos</span>
			</>
		),
		path: ARTICLES,
	},
	{
		label: (
			<>
				<span className="icon">
					<BsNewspaper />
				</span>
				<span className="text">Notícias</span>
			</>
		),
		path: NEWS,
	},
	{
		label: (
			<>
				<span className="icon">
					<IoLibraryOutline />
				</span>
				<span className="text">Produtos</span>
			</>
		),
		path: `${PRODUCTS}`,
	},
	{
		label: (
			<>
				<span className="icon">
					<AiOutlineSchedule />
				</span>
				<span className="text">Eventos</span>
			</>
		),
		path: `${EVENTS}`,
	},
	{
		label: (
			<>
				<span className="icon">
					<CgCamera />
				</span>
				<span className="text">Lives</span>
			</>
		),
		path: `${LIVES}`,
	},
	{
		label: (
			<>
				<span className="icon">
					<IoDocumentsSharp />
				</span>
				<span className="text">CMS</span>
			</>
		),
		children: [
			{
				label: (
					<>
						<span className="icon">
							<ImPencil />
						</span>
						<span className="text">Termos de Uso</span>
					</>
				),
				path: TERMS_OF_USE,
			},
			{
				label: (
					<>
						<span className="icon">
							<RiGitRepositoryPrivateFill />
						</span>
						<span className="text">Políticas de Privacidade</span>
					</>
				),
				path: PRIVATE_POLITICS,
			},
			{
				label: (
					<>
						<span className="icon">
							<ImFileVideo />
						</span>
						<span className="text">Vídeo de Apresentação</span>
					</>
				),
				path: WELCOME_VIDEO,
			},
			{
				label: (
					<>
						<span className="icon">
							<BsImage />
						</span>
						<span className="text">Banners Home</span>
					</>
				),
				path: BANNERS,
			},
			{
				label: (
					<>
						<span className="icon">
							<BsImage />
						</span>
						<span className="text">Banners Landing</span>
					</>
				),
				path: BANNERS_LANDING,
			},
			{
				label: (
					<>
						<span className="icon">
							<GrContactInfo />
						</span>
						<span className="text">Contato</span>
					</>
				),
				path: CONTACT,
			},
			{
				label: (
					<>
						<span className="icon">
							<FiUsers />
						</span>
						<span className="text">Equipe de campo</span>
					</>
				),
				path: CONTACTS,
			},
			{
				label: (
					<>
						<span className="icon">
							<FaQuestion />
						</span>
						<span className="text">FAQS</span>
					</>
				),
				path: FAQS,
			},
		],
	},
	{
		label: (
			<>
				<span className="icon">
					<GrDocumentPerformance />
				</span>
				<span className="text">Relatórios</span>
			</>
		),
		children: [
			{
				label: (
					<>
						<span className="icon">
							<BiUserCheck />
						</span>
						<span className="text">Logs de Acesso</span>
					</>
				),
				path: REPORTS_LOGS_USERS,
			},
			{
				label: (
					<>
						<span className="icon">
							<BsCardList />
						</span>
						<span className="text">Engajamento</span>
					</>
				),
				path: REPORTS_LOGS_ENGAGE,
			},
			{
				label: (
					<>
						<span className="icon">
							<BsCollectionPlay />
						</span>
						<span className="text">Lives</span>
					</>
				),
				path: REPORTS_LIVES,
			},
			{
				label: (
					<>
						<span className="icon">
							<BiUserCheck />
						</span>
						<span className="text">Relatórios de Usuários</span>
					</>
				),
				path: REPORTS_USERS,
			},
		],
	},
]

export default menus
