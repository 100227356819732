import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineHighlight } from 'react-icons/ai'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultButton, DefaultPageTitle, CreateButtonDefaultContainer, DefaultTable } from 'components'
import convertSecondsToTimeText from 'helpers/seconds-to-time-text'
import Content from 'models/content'
import {
	deleteContent,
	getAllContents,
	activateOrInactivateContent as activateOrInactivateContentService,
} from 'services/contents'
import { highlightContent as highlightContentService } from 'services/categories'
import { ContentsContainer } from './style'
import showErrorMessage from 'helpers/show-error-message'
import { CREATE_PODCAST, CREATE_VIDEO, EDIT_PODCAST, EDIT_VIDEO } from 'components/Routes/Constants'

interface ContentsProps {
	type: 'VIDEO' | 'PODCAST'
}

const Contents: React.FC<ContentsProps> = ({ type }) => {
	const history = useHistory()

	const [contents, setContents] = useState([] as Content[])

	const getContents = useCallback(async () => {
		const localContents = await getAllContents({ type, is_library: true })
		setContents(localContents)
	}, [type])

	const editContent = useCallback(
		(contentId: string) => {
			const url = type === 'VIDEO' ? EDIT_VIDEO : EDIT_PODCAST
			history.push(url.replace(':contentId', contentId))
		},
		[history, type]
	)

	const removeContent = useCallback(
		async (contentId: string) => {
			Swal.fire({
				title: '<strong>Confirmação</strong>',
				html: `Tem certeza que deseja remover este ${type === 'VIDEO' ? 'vídeo' : 'podcast'}?`,
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				focusConfirm: false,
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						await deleteContent(contentId)

						Swal.fire({
							icon: 'success',
							title: 'Sucesso!',
							text: `${type === 'VIDEO' ? 'Vídeo' : 'Podcast'} excluido com sucesso!`,
						})

						await getContents()
					} catch (error) {
						showErrorMessage(error)
					}
				}
			})
		},
		[getContents, type]
	)

	const createContent = async () => {
		const url = type === 'VIDEO' ? CREATE_VIDEO : CREATE_PODCAST
		history.push(url)
	}

	const highlightContent = useCallback(
		async (categoryId: string, contentId: string) => {
			const result = await Swal.fire({
				icon: 'question',
				text: `Tem certeza que deseja destacar este ${type === 'VIDEO' ? 'vídeo' : 'podcast'}?`,
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
			})

			if (result.isConfirmed) {
				try {
					await highlightContentService(categoryId, contentId)

					await Swal.fire({
						icon: 'success',
						text: `${type === 'VIDEO' ? 'vídeo' : 'podcast'} destacado com sucesso!`,
					})

					await getContents()
				} catch (error) {
					showErrorMessage(error)
				}
			}
		},
		[getContents, type]
	)

	const activateOrInactivateContent = useCallback(
		async (contentId: string, activate: boolean) => {
			Swal.fire({
				title: 'Confirmação',
				text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este ${
					type === 'VIDEO' ? 'vídeo' : 'podcast'
				}?`,
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				focusConfirm: false,
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						await activateOrInactivateContentService(contentId, activate)

						Swal.fire({
							icon: 'success',
							title: 'Sucesso!',
							text: `${type === 'VIDEO' ? 'Vídeo' : 'Podcast'} ${
								activate ? 'ativado' : 'inativado'
							} com sucesso!`,
						})

						await getContents()
					} catch (error) {
						showErrorMessage(error)
					}
				}
			})
		},
		[getContents, type]
	)

	const contentsToBeShown = useMemo(() => {
		return contents && contents.length
			? contents.map((content) => ({
					title: content.title,
					duration: convertSecondsToTimeText(content.duration),
					highlight: content.is_highlight ? <AiOutlineCheck /> : <AiOutlineClose />,
					categoryName: (content.categories[0] || {} || []).title,
					active: content.active ? <AiOutlineCheck /> : <AiOutlineClose />,
					actions: (
						<>
							<DefaultButton
								onClick={() => editContent(content.id)}
								className="small info"
								title={`Editar ${type === 'VIDEO' ? 'vídeo' : 'podcast'}`}
							>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => activateOrInactivateContent(content.id, !content.active)}
								className="small warning"
								title={
									(content.active ? 'Inativar' : 'Ativar') +
									` ${type === 'VIDEO' ? 'vídeo' : 'podcast'}`
								}
							>
								{content.active ? <AiOutlineClose /> : <AiOutlineCheck />}
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => {
									highlightContent(((content.categories || [])[0] || {}).category_id, content.id)
								}}
								className="small success"
								title={`Destacar ${type === 'VIDEO' ? 'vídeo' : 'podcast'}`}
							>
								<AiOutlineHighlight />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => {
									removeContent(content.id)
								}}
								className="small danger"
								title={`Excluir ${type === 'VIDEO' ? 'vídeo' : 'podcast'}`}
							>
								<BiTrash />
							</DefaultButton>
						</>
					),
			  }))
			: []
	}, [contents, type, editContent, activateOrInactivateContent, highlightContent, removeContent])

	useEffect(() => {
		getContents()
	}, [])

	const contentTypeName = useMemo(() => {
		return type === 'VIDEO' ? 'Vídeos' : 'Podcasts'
	}, [type])

	return (
		<ContentsContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>{contentTypeName}</span>]} />

			<DefaultPageTitle>{contentTypeName}</DefaultPageTitle>

			<CreateButtonDefaultContainer>
				<DefaultButton onClick={createContent}>Criar {contentTypeName}</DefaultButton>
			</CreateButtonDefaultContainer>

			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Título</span>,
						propName: 'title',
					},
					{
						headerLabel: <span>Duração</span>,
						propName: 'duration',
					},
					{
						headerLabel: <span>Categoria</span>,
						propName: 'categoryName',
					},
					{
						headerLabel: <span>Destacado</span>,
						propName: 'highlight',
						attributes: { style: { textAlign: 'center' } },
					},
					{
						headerLabel: <span>Ativo</span>,
						propName: 'active',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={contentsToBeShown}
				emptyListMessage={'Não foram encontrados itens cadastrados!'}
			/>
		</ContentsContainer>
	)
}

export default Contents
