import { BreadCrumb, DefaultButton, DefaultPageTitle, DefaultTable } from 'components'
import HomeInfo from 'models/home-info'
import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
	getBannerLanding as getBannerLandingService,
	createBannerLanding as createBannerLandingService,
	updateBannerLanding as updateBannerLandingService,
	getBannerLandingById,
} from 'services/banner-landing'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import showErrorMessage from 'helpers/show-error-message'
import CutImage from 'components/CutImage'
import { uploadFile } from 'services/files'
import Swal from 'sweetalert2'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import ModalContext from 'contexts/ModalContext'
import { editHomeInfo as editHomeInfoService, createHomeInfo as createHomeInfoService, deleteHomeInfo } from 'services/home-info'
import { formatDate } from 'helpers/format-date'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import DefaultInput from 'components/DefaultInput'
import checkEmptyString from 'helpers/check-empty-string'
import { BannerUploadContainer } from 'pages/ForMedico/CreateAndEditHomeInfo/style'

const BannersLandingContainer = styled.div``

const BannersList = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
`

interface CreateAndEditHomeInfoProps {
	bannerId: string
}

const BannersLanding: React.FC = () => {
	const [banner, setBanner] = useState<any>();
	const [bannerPath, setBannerPath] = useState<any>();
	const [link, setLink] = useState<any>();
	const history = useHistory()
	const { bannerId } = useParams<CreateAndEditHomeInfoProps>()



	const { openModal, hideModal } = useContext(ModalContext);

	const getBanner = useCallback(async () => {
		if (bannerId) {
			const banner = await getBannerLandingById(bannerId)
			setBanner(banner);
			setBannerPath(banner.info.image);
			setLink(banner.info.link);
		}
	}, [bannerId])

	const isEditting = useMemo(() => {
		if (bannerId) {
			return true
		}

		return false
	}, [bannerId])


	const cutBanner = () => {
		openModal('Adicionar Banner', <CutImage aspect={2.7} onCutImage={addImage} />)
	}

	const addImage = async (image: File) => {
		hideModal()
		let bannerPath;
		try {
			if (image) {
				try {
					const formData = new FormData()
					formData.append('file', image)
					const { reference } = await uploadFile(formData)
					bannerPath = reference;
					setBannerPath(bannerPath);
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

		} catch (error) {
			showErrorMessage(error)
		}
	}

	const createBanner = async (e: FormEvent) => {
		e.preventDefault();
		try {
			if (!!link && !checkEmptyString(link)) {
				if (!['http', 'https'].includes(link.split('://')[0])) {
					throw new Error('Insira um link válido (iniciando com http:// ou https://)')
				}
			}
			if (checkEmptyString(bannerPath)) {
				throw new Error('Insira uma imagem para o banner.')
			}
			const bannerData = {
				text: 'Banner Landing',
				type: 'landing_medic',
				info: {
					image: bannerPath,
					link: link
				}
			}
			await createHomeInfoService(bannerData);
			history.push('/banners_landing');
			Swal.fire({
				title: 'Sucesso!',
				text: `Banner criado com sucesso!`,
				icon: 'success',
			})
		} catch (error) {
			showErrorMessage(error)
		}
	}


	const updateBanner = async (banner: any) => {
		try {
			if (!!link && !checkEmptyString(link)) {
				if (!['http', 'https'].includes(link.split('://')[0])) {
					throw new Error('Insira um link válido (iniciando com http:// ou https://)')
				}
			}
			if (checkEmptyString(bannerPath)) {
				throw new Error('Insira uma imagem para o banner.')
			}
			const bannerData = {
				text: banner.text,
				info: {
					image: bannerPath,
					link: link
				},
				position: banner.position,
			}

			await editHomeInfoService(bannerData, banner.home_id);
			history.push('/banners_landing');
			Swal.fire({
				title: 'Sucesso!',
				text: `Banner editado com sucesso!`,
				icon: 'success',
			})
		} catch (error) {
			showErrorMessage(error)
		}
	}

	useEffect(() => {
		getBanner()
	}, [getBanner])

	return (
		<BannersLandingContainer>
			<BreadCrumb
				crumbs={[<Link to="/profile">Perfil</Link>, <span>CMS</span>, <span>	{isEditting ? 'Editar' : 'Criar'} Banners Landing Page</span>]}
			/>

			<DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Banners Landing Page</DefaultPageTitle>

			<DefaultCreationForm>
				<DefaultCreationFormGroup>
					<label htmlFor="url" >
						Link
					</label>
					<DefaultInput
						type="url"
						id="url"
						placeholder="http://"
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>
				</DefaultCreationFormGroup>
				<DefaultCreationFormGroup>
					<label className="required">
						Capa
					</label>
					<span className="observation-img">
						<li>Recomendado: 1495x550</li>
					</span>
					<BannerUploadContainer>
						<DefaultButton type="button" onClick={cutBanner}>
							Adicionar Banner
						</DefaultButton>
						<BannersList>
							<BannerLandingCard
								bannerImage={bannerPath}
							/>
						</BannersList>
					</BannerUploadContainer>

				</DefaultCreationFormGroup>


				<DefaultCreationFormGroup style={{ alignItems: 'center' }}>
					<DefaultButton type="button" onClick={(e) => (isEditting ? updateBanner(banner) : createBanner(e))}>
						{isEditting ? 'Salvar Banner' : 'Criar Banner'}
					</DefaultButton>
				</DefaultCreationFormGroup>
			</DefaultCreationForm>
		</BannersLandingContainer>
	)
}

interface BannerLandingCardProps {
	bannerImage: string
}

const BannerLandingCardContainer = styled.div<Partial<BannerLandingCardProps>>`
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	width: 100%;
	min-height: 260px;

	div.actions {
		position: absolute;
		top: 0;
		right: 0;
		padding: 8px;
		display: flex;
		align-items: center;
		gap: 4px;
	}

	background-image: url(${(props) => props.bannerImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`

const BannerLandingCard: React.FC<BannerLandingCardProps> = ({ bannerImage }) => {
	return (
		<BannerLandingCardContainer bannerImage={bannerImage}>
		</BannerLandingCardContainer>
	)
}

export default BannersLanding
