import AppContext from 'contexts/AppContext'
import React, { useContext } from 'react'
import {
	AdminTypeSwitchContainer,
	SwitchAdminTypeForm,
	SwitchAdminTypeModalContainer,
	SwitchAdminTypeModalContent,
	SwitchAdminTypeModalInfo,
} from './style'
import { LogoExeltisOn, LogoRegenesis } from 'assets'
import { AiFillCaretRight } from 'react-icons/ai'
import ReactDOM from 'react-dom'
import DefaultInput from 'components/DefaultInput'
import { DefaultButton } from 'components'
import showErrorMessage from 'helpers/show-error-message'
import { login as loginService } from '../../services/login'

const showSwitchAdminTypeModal = async (adminType: 'medico' | 'gestante') => {
	const handleChangeAdminType = async (event: React.FormEvent, resolve: unknown) => {
		event.preventDefault()

		const form = event.currentTarget

		const loginInput = form.querySelector('#login') as HTMLInputElement
		const passwordInput = form.querySelector('#password') as HTMLInputElement

		const email = loginInput.value
		const password = passwordInput.value

		if (!email || !password) {
			throw new Error('Informe suas credenciais para continuar')
		}

		const isMedic = adminType === 'medico'
		await loginService(email, password, isMedic)
		;(resolve as () => void)()
		hideModal()
	}

	const hideModal = () => {
		const adminTypeModals = Array.from(document.querySelectorAll('#admin-type-modal'))
		for (let modal of adminTypeModals) {
			modal.remove()
		}
	}

	hideModal()

	const modalPromise = new Promise((resolve, reject) => {
		const Modal = (
			<SwitchAdminTypeModalContainer onClick={hideModal}>
				<SwitchAdminTypeModalContent onClick={(event) => event.stopPropagation()}>
					<SwitchAdminTypeModalInfo>Informe novamente suas credenciais</SwitchAdminTypeModalInfo>
					<SwitchAdminTypeForm onSubmit={(event) => handleChangeAdminType(event, resolve)}>
						<div>
							<label htmlFor="login">Login</label>
							<DefaultInput id="login" placeholder="Digite seu e-mail" required />
						</div>
						<div>
							<label htmlFor="password">Senha</label>
							<DefaultInput id="password" type="password" placeholder="Digite sua senha" required />
						</div>
						<DefaultButton type="submit">Entrar</DefaultButton>
					</SwitchAdminTypeForm>
				</SwitchAdminTypeModalContent>
			</SwitchAdminTypeModalContainer>
		)

		const modalDiv = document.createElement('div')
		modalDiv.id = 'admin-type-modal'
		document.body.appendChild(modalDiv)
		ReactDOM.render(Modal, modalDiv)
	})

	return modalPromise
}

const AdminTypeSwitch: React.FC = () => {
	const { adminType, setAdminType } = useContext(AppContext)

	const handleClick = async () => {
		try {
			const newAdminType = adminType === 'gestante' ? 'medico' : 'gestante'
			await showSwitchAdminTypeModal(newAdminType)

			setAdminType(newAdminType)
		} catch (error) {
			showErrorMessage(error)
		}
	}

	return (
		<AdminTypeSwitchContainer onClick={handleClick}>
			<span>
				Ir para Admin {adminType === 'gestante' ? 'Exeltis On' : 'Exeltis Gestante'}
				<AiFillCaretRight size={16} />
			</span>
			{adminType === 'gestante' ? <img src={LogoExeltisOn} alt="Logo" /> : <img src={LogoRegenesis} alt="Logo" />}
		</AdminTypeSwitchContainer>
	)
}

export default AdminTypeSwitch
