import styled from 'styled-components'

const DefaultTextArea = styled.textarea`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	outline: none;
	padding: 10px 15px;
	font-size: var(--font-small);
	color: var(--default-gray);
	width: 100%;
	resize: vertical;
`

export default DefaultTextArea
