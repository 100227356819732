import styled from "styled-components";

export const DefaultPageTitle = styled.h2`
  font-size: var(--font-medium);
  margin-bottom: 30px;
  border-bottom: solid 1.2px rgba(0, 0, 0, 0.1);
  color: var(--default-title-color);
  font-weight: 400;
  padding-bottom: 10px;
`;

export default DefaultPageTitle;
