import convertToEvent from '../helpers/converters/convert-event'
import httpClient from '../http-client'
import Event from '../models/event'
import EventFromReponse, { LiveFromReponse } from '../models/from-api-response/event'
import { uploadFile } from './files'
import EventForCreate from '../models/for-create/event'
import EventForUpdate from '../models/for-update/event'

const getEvent = async (eventId: string) => {
	const event = (await httpClient.get<EventFromReponse>(`events/${eventId}`)).data

	let convertedEvent = {} as Event
	if (event && Object.keys(event).length) {
		convertedEvent = convertToEvent(event)
	}

	return convertedEvent
}

const getAllEvents = async () => {
	const events = (await httpClient.get<EventFromReponse[]>('events')).data

	let convertedEvents = [] as Event[]
	if (events && events.length) {
		convertedEvents = events.map(convertToEvent)
	}

	return convertedEvents
}

const deleteEvent = async (eventId: string) => {
	await httpClient.delete(`events/${eventId}`)
}

const createEvent = async (newEvent: EventForCreate, isLive?: boolean) => {
	let liveId
	if (isLive) {
		liveId = (
			await httpClient.post<LiveFromReponse>(`lives/`, {
				name: newEvent.subtitle,
				description: newEvent.description,
				start: newEvent.start_date,
				stop: newEvent.end_date,
				vod: 2,
				channel_sv_id: newEvent.channel_sv_id,
				live_sv_id: newEvent.live_sv_id
			})
		).data.live_id
	}
	const event = newEvent;
	delete event.channel_sv_id;
	delete event?.live_sv_id;

	const createEventResponse = (
		await httpClient.post<EventFromReponse>(`events/`, isLive ? { ...event, live_id: liveId } : event)
	).data

	return createEventResponse
}

const updateEvent = async (eventId: string, newEvent: EventForUpdate, liveId?: string | undefined, liveData?: any) => {
	console.log('update', liveData)
	let newLiveId
	if (liveId !== undefined) {
		if (liveId) {
			if (liveData) {
				await httpClient.put<LiveFromReponse>(`lives/${liveId}`, liveData)
				newLiveId = liveId
			} else {
				await httpClient.delete(`lives/${liveId}`)
				newLiveId = null
			}
		} else {
			newLiveId = (
				await httpClient.post<LiveFromReponse>(`lives/`, {
					...liveData,
					vod: 2,
				})
			).data.live_id
		}
	}

	await httpClient.put(
		`events/${eventId}`,
		newLiveId || newLiveId === null ? { ...newEvent, live_id: newLiveId } : newEvent
	)
}

const changeEventThumbnail = async (eventId: string, formData: FormData) => {
	const data = await uploadFile(formData)

	await httpClient.put(`events/${eventId}`, {
		thumbnail: data.reference,
	})
}

const changeEventContent = async (
	eventId: string,
	url: string,
	title: string,
	liveId: string | null | undefined,
	contentId?: string | null
) => {
	let content

	if (contentId) {
		content = await httpClient.put(`contents/${contentId}`, {
			title: title,
			reference: url,
		})
	} else {
		content = await httpClient.post(`contents`, {
			title: title,
			reference: url,
			type: 'VIDEO',
		})
	}

	await httpClient.put(`events/${eventId}`, {
		live_id: liveId,
		content_id: content?.data.content_id,
	})
}

const getContentReference = async (contentId: string | null | undefined) => {
	if (!contentId) {
		return null
	}
	const content = await httpClient.get(`contents/${contentId}`)
	return content.data.reference
}

const deleteVideo = async (contentId: string) => {
	await httpClient.delete(`contents/${contentId}`)
}

const activateOrInactivateEvent = async (eventId: string, activate: boolean) => {
	await httpClient.put(`events/active/${eventId}`, { is_active: activate })
}

const getLivesAndChannel = async () => {
	const lives = (await (httpClient.get('lives/samba-videos'))).data;
	return lives;

}

export {
	getAllEvents,
	deleteEvent,
	getEvent,
	createEvent,
	updateEvent,
	changeEventContent,
	changeEventThumbnail,
	activateOrInactivateEvent,
	getContentReference,
	deleteVideo,
	getLivesAndChannel
}
