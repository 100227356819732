import Event from '../../models/event'
import EventFromResponse from '../../models/from-api-response/event'

const convertToEvent = (event: EventFromResponse): Event => {
	return {
		id: event.event_id,
		live_id: event.live_id,
		title: event.title,
		subtitle: event.subtitle,
		description: event.description,
		address: event.local,
		content_id: event.content_id,
		info: event.info,
		startDate: new Date(event.start_date),
		endDate: new Date(event.end_date),
		observation: event.observation,
		price: event.price,
		thumbnail: event.thumbnail,
		active: event.is_active,
		speakers: event.speakers,
	}
}

export default convertToEvent
