import httpClient from 'http-client'
import News from 'models/from-api-response/news'

const getNews = async (params?: any) => {
	return (await httpClient.get<News[]>(`/news`, { params: params })).data
}

const getNewsForSelect = async (params?: any) => {
	const news = (await httpClient.get<News[]>(`/news`, { params: params })).data

	let select
	if (news && news.length) {
		select = news.map((content) => {
			return {
				label: content.title,
				value: content.news_id,
			}
		})
	}

	return select
}

const getSingleNews = async (newsId: string) => {
	return (await httpClient.get<News>(`/news/${newsId}`)).data
}

const createNews = async (newNews: any) => {
	return (await httpClient.post(`/news`, newNews)).data
}

const updateNews = async (newsId: string, newNews: any) => {
	return (await httpClient.put<News>(`/news/${newsId}`, newNews)).data
}

const removeNews = async (newsId: string) => {
	return (await httpClient.delete(`/news/${newsId}`)).data
}

const checkNewsAsHighlighted = async (newsId: string, category_id: string) => {
	return (await httpClient.patch(`/news/${newsId}/highlight`, { is_highlight: true, category_id })).data
}

export { getNews, removeNews, updateNews, getSingleNews, checkNewsAsHighlighted, createNews, getNewsForSelect }
