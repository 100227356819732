import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Swal from 'sweetalert2'
import Select from 'react-select'
import { Link, useLocation } from 'react-router-dom'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import DefaultFilterForm from 'components/DefaultFilterForm'
import { DefaultCreationFormButtonGroup } from 'components/DefaultCreationForm'
import { getUsersForSelect } from 'services/users'
import { getAllContentsForSelect, getAllContentsForSelectByType } from 'services/contents'
import { generateLogUserAccessReport, generateEngagementReport, generateUsersReport, generateLivesReport, generateContentEngagementReport } from 'services/reports'
import DefaultButton from 'components/DefaultButton'
import showErrorMessage from '../../../helpers/show-error-message'
import { LogAccessUsersContainer, WrapperLiveSelect } from './style'
import { getLivesForSelect } from 'services/live'
import { getNewsForSelect } from 'services/news'
import { MdOutlineClear } from "react-icons/md";

const LogAccessUser: React.FC = () => {
	const location = useLocation()

	const [usersSelect, setUsersSelect] = useState([] as unknown as { label: string; value: string }[])
	const [selectedUsers, setSelectedUsers] = useState([] as unknown as { label: string; value: string }[])
	const [selectedUserLive, setSelectedLiveUsers] = useState({} as unknown as { label: string; value: string } | null)


	const [contentSelect, setContentSelect] = useState([] as unknown as { label: string; value: string }[])
	const [selectedContents, setSelectedContents] = useState([] as unknown as { label: string; value: string }[])

	const [livesSelect, setLivesSelect] = useState([] as unknown as { label: string; value: string }[])
	const [selectedLive, setSelectedLive] = useState({} as unknown as { label: string; value: string } | null)

	const [dateAccessLte, setDateAccessLte] = useState<string>()
	const [dateAccessGte, setDateAccessGte] = useState<string>()

	const [startAtLte, setStartAtLte] = useState<string>()
	const [startAtGte, setStartAtGte] = useState<string>()

	const [createdAtLte, setCreatedAtLte] = useState<string>()
	const [createdAtGte, setCreatedAtGte] = useState<string>()

	const [selectedType, setSelectedType] = useState({} as unknown as { label: string; value: string } | null)



	const contentOptions = [
		{
			label: 'Artigo Científico',
			value: 'ARTIGO_CIENTIFICO'
		},
		{
			label: 'Artigo Comentado',
			value: 'ARTIGO_COMENTADO'
		},
		{
			label: 'Monografia',
			value: 'MONOGRAFIA'
		},
		{
			label: 'Podcast',
			value: 'PODCAST'
		},
		{
			label: 'Notícias',
			value: 'NOTICIA'
		},
		{
			label: 'Vídeos',
			value: 'VIDEO'
		}
	]

	const getUsers = useCallback(async () => {
		const localUsers = await getUsersForSelect()

		if (localUsers) {
			setUsersSelect(localUsers)
		}
	}, [])

	const getContents = useCallback(async () => {
		const localContents = await getAllContentsForSelect()

		if (localContents) {
			setContentSelect(localContents)
		}
	}, [])

	const getLives = useCallback(async () => {
		const localLives = await getLivesForSelect()

		if (localLives) {
			setLivesSelect(localLives)
		}
	}, [])

	const getContentByTypes = useCallback(async () => {
		if (selectedType?.value === 'NOTICIA') {
			const localContents = await getNewsForSelect();
			if (localContents) {
				setContentSelect(localContents)
			}
		} else {
			const localContents = await getAllContentsForSelectByType({ type: selectedType?.value })
			setSelectedContents([]);
			if (localContents) {
				setContentSelect(localContents)
			}
		}

	}, [selectedType?.value])


	useEffect(() => {
		getContentByTypes()
	}, [getContentByTypes, selectedType])

	useEffect(() => {
		switch (location.pathname) {
			case '/reports/user-access':
				getUsers()
				break
			case '/reports/engage':
				getUsers()
				getContentByTypes()
				break
			case '/reports/lives':
				getUsers()
				getLives()
				break
			default:
				break
		}
	}, [getContents, getUsers, location])



	const liveReport = async (type: string) => {
		try {
			if (!selectedLive?.value) {
				throw new Error(`Informe uma live.`)
			}
			const livesQueryString: any = {
				live_id: selectedLive?.value,
				user_id: selectedUserLive?.value
			}
			await generateLivesReport(type, livesQueryString)

		} catch (error) {
			showErrorMessage(error)
		}
	}
	const engagementReport = async (type: string) => {
		try {
			if (!selectedType?.value) {
				throw new Error(`Informe um tipo de conteúdo.`)
			}
			const contentQueryString: any = {
				user_ids: selectedUsers.map((user) => user.value),
				content_ids: selectedContents.map((content) => content.value),
				content_types: [selectedType?.value]
			}
			await generateContentEngagementReport(type, contentQueryString)

		} catch (error) {
			showErrorMessage(error)
		}
	}


	const generateReport = async (event: React.FormEvent, type: string) => {
		event.preventDefault()

		const queryStrings: any = {
			access_at_gte: dateAccessGte,
			access_at_lte: dateAccessLte,
			user_id_ft: selectedUsers.map((user) => user.value),
			content_id_ft: selectedContents.map((content) => content.value),
			start_at_lte: startAtLte,
			start_at_gte: startAtGte,
			created_at_lte: createdAtLte,
			created_at_gte: createdAtGte,
		}





		for (let prop of Object.keys(queryStrings)) {
			if (!queryStrings[prop]) {
				delete queryStrings[prop]
			}
		}

		try {
			switch (location.pathname) {
				case '/reports/user-access':
					await generateLogUserAccessReport(type, queryStrings)
					break

				case '/reports/engage':
					engagementReport(type)
					break

				case '/reports/users':
					await generateUsersReport(type, queryStrings)
					break

				case '/reports/lives':
					liveReport(type);
					break

				default:
					break
			}
		} catch (error) {
			Swal.fire({
				title: 'Erro',
				text: 'Houve um erro ao gerar o relatório. ' + error.message,
				icon: 'error',
			})
		}
	}

	const cleanFilters = (event: React.FormEvent) => {
		event.preventDefault()

		setSelectedUsers([])
		setDateAccessGte('')
		setDateAccessLte('')
		setSelectedContents([])
		setStartAtGte('')
		setStartAtLte('')
		setCreatedAtLte('')
		setCreatedAtGte('')
		setSelectedLive(null)
		setSelectedLiveUsers(null)
		setSelectedType(null)
	}

	const getTypeOfReport = useMemo(() => {
		switch (location.pathname) {
			case '/reports/user-access':
				return 'Logs de Acessos dos Usuários'

			case '/reports/engage':
				return 'Engajamento'

			case '/reports/users':
				return 'Relatório de Usuários'

			case '/reports/live':
				return 'Relatório de Lives'
			default:
				return ''
		}
	}, [location])

	return (
		<LogAccessUsersContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Relatórios</span>]} />

			<DefaultPageTitle>{getTypeOfReport}</DefaultPageTitle>

			<DefaultFilterForm>
				{location.pathname === '/reports/user-access' || location.pathname === '/reports/engage' ? (
					<span className="entire-row">
						<label htmlFor="users">Usuário</label>

						<Select
							isMulti
							options={usersSelect}
							value={selectedUsers}
							id="users"
							onChange={(options) => {
								if (options !== null) {
									setSelectedUsers(options.flat())
								}
							}}
							placeholder="Selecione um ou mais usuários"
						/>
					</span>
				) : null}

				{location.pathname === '/reports/lives' ? (
					<span className="entire-row">
						<label htmlFor="users">Usuário</label>

						<Select
							options={usersSelect}
							value={selectedUserLive}
							id="users"
							onChange={(option) => {
								if (option !== null) {
									setSelectedLiveUsers(option)
								}
							}}
							placeholder="Selecione um usuário"
						/>
					</span>
				) : null}

				{location.pathname === '/reports/user-access' ? (
					<>
						<span>
							<label htmlFor="date-gte">Data de Acesso Inicial</label>

							<input
								type="date"
								id="date-gte"
								onChange={(e) => setDateAccessGte(e.target.value)}
								value={dateAccessGte}
							/>
						</span>

						<span>
							<label htmlFor="date-lte">Data de Acesso Final</label>

							<input
								type="date"
								id="date-lte"
								onChange={(e) => setDateAccessLte(e.target.value)}
								value={dateAccessLte}
							/>
						</span>
					</>
				) : location.pathname === '/reports/engage' ? (
					<>
						<span className="entire-row">
							<label htmlFor="users" className="required">Tipo de Conteúdo</label>
							<Select
								options={contentOptions}
								value={selectedType}
								id="users"
								onChange={(option) => {
									setSelectedType(option)
								}}
								placeholder="Selecione um tipo de conteúdo"
							/>
						</span>

						<span className="entire-row">
							<label htmlFor="users">Conteúdo</label>
							<Select
								isMulti
								options={contentSelect}
								value={selectedContents}
								id="users"
								onChange={(options) => {
									if (options !== null) {
										setSelectedContents(options.flat())
									}
								}}
								placeholder="Selecione um ou mais conteúdos"
							/>
						</span>

					</>
				) : location.pathname === '/reports/users' ? (
					<>
						<span>
							<label htmlFor="create-gte">Data de Criação Inicial</label>

							<input
								type="date"
								id="create-gte"
								onChange={(e) => setCreatedAtGte(e.target.value)}
								value={createdAtGte}
							/>
						</span>

						<span>
							<label htmlFor="create-lte">Data de Criação Final</label>

							<input
								type="date"
								id="create-lte"
								onChange={(e) => setCreatedAtLte(e.target.value)}
								value={createdAtLte}
							/>
						</span>
					</>
				) : location.pathname === '/reports/lives' ? (
					<span className="entire-row">
						<label htmlFor="users" className="required">Lives</label>
						<WrapperLiveSelect>
							<Select
								options={livesSelect}
								value={selectedLive}
								id="users"
								onChange={(options) => {
									if (options !== null) {
										setSelectedLive(options)
									}
								}}
								placeholder="Selecione uma live"
							/>
							{selectedLive && <button onClick={(e) => {
								e.preventDefault()
								setSelectedLive(null)
							}}><MdOutlineClear /></button>}
						</WrapperLiveSelect>
					</span>

				) : null}

				<DefaultCreationFormButtonGroup>
					<DefaultButton className="danger" onClick={(e) => cleanFilters(e)}>
						Limpar Campos
					</DefaultButton>

					<DefaultButton type="submit" className="sucess" onClick={(e) => generateReport(e, 'csv')}>
						Gerar Relatório (CSV)
					</DefaultButton>

					<DefaultButton type="submit" className="sucess" onClick={(e) => generateReport(e, 'pdf')}>
						Gerar Relatório (PDF)
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultFilterForm>
		</LogAccessUsersContainer >
	)
}

export default LogAccessUser
