const convertSecondsToTimeText = (seconds: number) => {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const remainingSeconds = seconds % 60

	let timeText = ''
	if (hours) {
		timeText += hours + 'h '
	}

	if (minutes) {
		timeText += minutes + 'm '
	}

	if (remainingSeconds) {
		timeText += remainingSeconds + 's'
	}

	return timeText
}

export default convertSecondsToTimeText
