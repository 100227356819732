import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import Swal from 'sweetalert2'
import BreadCrumb from 'components/BreadCrumb'
import DefaultButton from 'components/DefaultButton'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import { DefaultPageTitle } from 'components/DefaultPageTitle'
import { AvatarThumbnail, CreateAndEditAuthorContainer, ThumbnailUploadContainer } from './style'

import CutImage from 'components/CutImage'
import checkEmptyString from 'helpers/check-empty-string'
import { maskPhone } from 'helpers/masks'
import {
	createAuthor as createAuthorService,
	getAuthor,
	getAllAuthors,
	updateAuthor as updateAuthorService,
} from 'services/authors'
import { uploadFile } from 'services/files'
import states from 'storage/estados-brasileiros.json'
import cities from 'storage/cidades-brasileiras.json'
import { DescriptionTextarea, getCleanHtml } from '../../../components/Editor'
import { EditorState } from 'draft-js'
import showErrorMessage from 'helpers/show-error-message'
import ModalContext from 'contexts/ModalContext'

interface CreateAndEditAuthorProps {
	authorId: string
}

const CreateAndEditAuthor: React.FC = () => {
	const { authorId } = useParams<CreateAndEditAuthorProps>()

	const history = useHistory()

	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [specialties, setSpecialties] = useState([] as { label: string; value: string }[])
	const [selectedSpecialty, setSelectedSpecialty] = useState([] as { label: string; value: string }[])
	const [selectedState, setSelectedState] = useState({} as { label: string; value: string })
	const [filterCities, setFilterCities] = useState([] as { label: string; value: string; state: string }[])
	const [selectedCity, setSelectedCity] = useState({} as { label: string; value: string; state: string })
	const [site, setSite] = useState('')
	const [telephone, setTelephone] = useState('')
	const [socialMedia, setSocialMedia] = useState('')
	const [avatarFileToUpload, setAvatarFileToUpload] = useState<File>()
	const [thumbnail, setThumbnail] = useState<any>()
	const { openModal, hideModal } = useContext(ModalContext)

	const [editorState, setEditorState] = useState(EditorState.createEmpty())

	const getCitiesFromState = useCallback(() => {
		if (selectedState) {
			const aux = cities.filter((city) => city.state === selectedState.value)

			setFilterCities(aux)
		}
	}, [selectedState])

	useEffect(() => {
		getCitiesFromState()
	}, [getCitiesFromState])

	const createAuthor = async (event: React.FormEvent) => {
		event.preventDefault()
		const cleanHTML = getCleanHtml(editorState)

		try {
			if (checkEmptyString(name)) {
				throw new Error('Informe um nome valido para o profissional.')
			}

			if (!selectedState) {
				throw new Error('Informe um estado para o profissional.')
			}

			if (!selectedCity) {
				throw new Error('Informe uma cidade para o profissional.')
			}

			if (!selectedSpecialty.length) {
				throw new Error('Informe uma especialidade para o profissional.')
			}

			let avatarUrl
			if (avatarFileToUpload) {
				try {
					avatarUrl = await uploadThumbnail(avatarFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			await createAuthorService({
				name,
				description: !checkEmptyString(cleanHTML) ? cleanHTML : undefined,
				avatar_url: avatarUrl ? avatarUrl.reference : undefined,
				info: {
					specialty: selectedSpecialty.map((value) => value.label),
					city: `${selectedCity.value}/${selectedState.value}`,
					site: !checkEmptyString(site) ? site : undefined,
					telephone: !checkEmptyString(telephone) ? site : undefined,
					social_media: !checkEmptyString(socialMedia) ? socialMedia : undefined,
				},
			})

			Swal.fire({
				title: 'Sucesso!',
				text: 'Profissional criado com sucesso!',
				icon: 'success',
			})

			goToAuthors()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const updateAuthor = async (event: React.FormEvent) => {
		event.preventDefault()
		const cleanHTML = getCleanHtml(editorState)

		try {
			if (checkEmptyString(name)) {
				throw new Error('Informe um nome valido para o profissional.')
			}

			if (!selectedState) {
				throw new Error('Informe um estado para o profissional.')
			}

			if (!selectedCity) {
				throw new Error('Informe uma cidade para o profissional.')
			}

			if (!selectedSpecialty.length) {
				throw new Error('Informe uma especialidade para o profissional.')
			}

			let avatarUrl
			if (avatarFileToUpload) {
				try {
					avatarUrl = await uploadThumbnail(avatarFileToUpload)
				} catch (error) {
					throw new Error(
						'Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
					)
				}
			}

			await updateAuthorService(authorId, {
				name,
				description: !checkEmptyString(cleanHTML) ? cleanHTML : undefined,
				avatar_url: avatarUrl ? avatarUrl.reference : undefined,
				info: {
					specialty: selectedSpecialty.map((value) => value.label),
					city: `${selectedCity.value}/${selectedState.value}`,
					site: !checkEmptyString(site) ? site : undefined,
					telephone: !checkEmptyString(telephone) ? telephone : undefined,
					social_media: !checkEmptyString(socialMedia) ? socialMedia : undefined,
				},
			})

			Swal.fire({
				title: 'Sucesso!',
				text: 'Profissional editado com sucesso!',
				icon: 'success',
			})

			goToAuthors()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const uploadThumbnail = async (localAvatarFileToUpload: File) => {
		const formData = new FormData()
		formData.append('file', localAvatarFileToUpload)

		const fileUploaded = await uploadFile(formData)

		return fileUploaded
	}

	const selectThumbnail = () => {
		openModal('Selecionar Imagem do Autor', <CutImage aspect={1} onCutImage={onCutImage} />)
	}

	const onCutImage = (file: File) => {
		if (file) {
			setAvatarFileToUpload(file)
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => setThumbnail(`${reader.result}`)
			hideModal()
		}
	}

	const goToAuthors = () => {
		history.push('/professionals')
	}

	const getAuthorFunc = useCallback(async () => {
		if (authorId) {
			const author = await getAuthor(authorId)

			if (author && Object.keys(author).length) {
				setName(author.name)
				setDescription(author.description)
				setThumbnail(author.avatar_url)
				setTelephone(author.info.telephone)
				setSite(author.info.site)
				setSocialMedia(author.info.social_media)

				const specialties =
					author.info.specialty.length &&
					author.info.specialty.map((specialty) => {
						return {
							label: specialty,
							value: specialty,
						}
					})
				if (specialties && specialties.length) {
					setSelectedSpecialty(specialties)
				}

				const state = author.info.city.split('/')
				if (state.length) {
					const filterState = states.find((aux) => aux.value === state[1])
					const filterCity = cities.find((city) => city.label === state[0] && city.state === state[1])

					if (filterState && filterCity) {
						setSelectedState(filterState)
						setSelectedCity(filterCity)
					}
				}
			}
		}
	}, [authorId])

	const getSpecialties = useCallback(async () => {
		const allAuthors = await getAllAuthors()

		if (allAuthors && allAuthors.specialty && allAuthors.specialty.length) {
			const aux = allAuthors.specialty.map((specialty) => {
				return {
					label: specialty,
					value: specialty,
				}
			})

			setSpecialties(aux)
		}
	}, [])

	useEffect(() => {
		getSpecialties()
	}, [getSpecialties])

	useEffect(() => {
		getAuthorFunc()
	}, [getAuthorFunc])

	const isEditting = useMemo(() => {
		if (authorId) {
			return true
		}

		return false
	}, [authorId])

	return (
		<CreateAndEditAuthorContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/profile">Perfil</Link>,
					<Link to="/professionals">Profissionais</Link>,
					<span>{isEditting ? 'Editar' : 'Criar'} Profissional</span>,
				]}
			/>

			<DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Profissional</DefaultPageTitle>

			<DefaultCreationForm>
				<DefaultCreationFormGroup>
					<label className="required" htmlFor="title">
						Nome
					</label>
					<DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="title" required />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="description">Descrição</label>

					<DescriptionTextarea
						description={description}
						editorState={editorState}
						setEditorState={setEditorState}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label className="required" htmlFor="specialties">
						Especialidades
					</label>
					<CreatableSelect
						isSearchable
						required
						styles={{
							container: (provided) => ({
								...provided,
								flexGrow: 1,
								width: '100%',
							}),
						}}
						isMulti
						options={specialties}
						value={selectedSpecialty}
						onChange={(options) => {
							if (options !== null) {
								setSelectedSpecialty(options.flat())
							}
						}}
						id="specialties"
						placeholder="Selecione um ou mais especialidades para este profissional"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="site">Site</label>
					<DefaultInput value={site} onChange={(e) => setSite(e.target.value)} id="site" />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="state" className="required">
						Estado
					</label>
					<Select
						isSearchable
						required
						styles={{
							container: (provided) => ({
								...provided,
								flexGrow: 1,
								width: '100%',
							}),
						}}
						options={states}
						value={selectedState}
						onChange={(options) => {
							if (options !== null) {
								setSelectedState(options)
							}
						}}
						id="state"
						placeholder="Selecione o estado"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="city" className="required">
						Cidade
					</label>
					<Select
						isSearchable
						required
						styles={{
							container: (provided) => ({
								...provided,
								flexGrow: 1,
								width: '100%',
							}),
						}}
						options={filterCities}
						value={selectedCity}
						onChange={(options) => {
							if (options !== null) {
								setSelectedCity(options)
							}
						}}
						id="city"
						placeholder="Selecione a cidade"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="social-media">Instagram</label>
					<DefaultInput
						value={socialMedia}
						onChange={(e) => setSocialMedia(e.target.value)}
						id="social-media"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="telephone">Telefone</label>
					<DefaultInput
						value={maskPhone(telephone)}
						onChange={(e) => setTelephone(maskPhone(e.target.value))}
						id="telephone"
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="reference">Avatar</label>

					<ThumbnailUploadContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem do Autor
						</DefaultButton>
						{thumbnail && <AvatarThumbnail src={thumbnail} />}
					</ThumbnailUploadContainer>
				</DefaultCreationFormGroup>
				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToAuthors}>
						Cancelar
					</DefaultButton>
					<DefaultButton
						onClick={(e) => (isEditting ? updateAuthor(e) : createAuthor(e))}
						className="success"
					>
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditAuthorContainer>
	)
}

export default CreateAndEditAuthor
