import styled from 'styled-components'

const CreateAndEditNewsContainer = styled.div``

const ThumbnailButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	width: 100%;

	img {
		max-width: 100%;
	}
`

export { CreateAndEditNewsContainer, ThumbnailButtonContainer }
