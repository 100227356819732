import { BreadCrumb, DefaultButton, DefaultPageTitle } from 'components'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { CreateAndEditNewsContainer, ThumbnailButtonContainer } from './style'
import DefaultTextArea from 'components/DefaultTextArea'
import { DescriptionTextarea, getCleanHtml } from 'components/Editor'
import { EditorState } from 'draft-js'
import CutImage from 'components/CutImage'
import { uploadFile } from 'services/files'
import Select from 'react-select'
import Category from 'models/category'
import { getAllCategories as getCategoriesService } from 'services/categories'
import {
	getSingleNews as getSingleNewsService,
	createNews as createNewsService,
	updateNews as updateNewsService,
} from 'services/news'
import showErrorMessage from 'helpers/show-error-message'
import checkEmptyString from 'helpers/check-empty-string'
import Swal from 'sweetalert2'
import { NEWS } from 'components/Routes/Constants'
import { getTags as getTagsService } from 'services/tags'
import TagFromResponse from 'models/from-api-response/tag'
import ModalContext from 'contexts/ModalContext'

interface CreateAndEditNewsParams {
	newsId: string
}

const CreateAndEditNews: React.FC = () => {
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [location, setLocation] = useState<any>()
	const [content, setContent] = useState('')
	const [thumbnail, setThumbnail] = useState('')
	const [categories, setCategories] = useState(undefined as Category[] | undefined)
	const [selectedCategory, setSelectedCategory] = useState({} as { value: string; label: string })
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [tags, setTags] = useState([] as TagFromResponse[])
	const [selectedTags, setSelectedTags] = useState<string[]>([])
	const { newsId } = useParams<CreateAndEditNewsParams>()
	const history = useHistory()
	const { openModal, hideModal } = useContext(ModalContext)

	const goToNews = () => {
		history.push(NEWS)
	}

	const validate = () => {
		if (checkEmptyString(title)) {
			throw new Error('Informe um título para a notícia')
		}

		if (checkEmptyString(content) && !getCleanHtml(editorState)) {
			throw new Error('Informe um conteúdo para a notícia')
		}

		if (!selectedTags?.length) {
			throw new Error(`Informe ao menos uma tag para o .`)
		}

		if (!thumbnail) {
			throw new Error('Informe uma thumbnail para a notícia')
		}

		if (!selectedCategory?.value) {
			throw new Error('Informe uma categoria para a notícia')
		}
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			validate()

			const newNews = {
				title,
				description,
				content: getCleanHtml(editorState),
				thumbnail,
				location,
				category_id: selectedCategory ? selectedCategory.value : undefined,
				tags: selectedTags?.length
					? selectedTags.map((tag) => ({
						tag_id: tag,
					}))
					: undefined,
			}

			if (isEditing) {
				await updateNews(newsId, newNews)
			} else {
				await createNews(newNews)
			}

			Swal.fire({ icon: 'success', text: `Notícia ${isEditing ? 'editada' : 'criada'} com sucesso!` })

			goToNews()
		} catch (error) {
			showErrorMessage(error, `Erro ao ${isEditing ? 'editar' : 'criar'} notícia`)
		}
	}

	const createNews = async (newNews: any) => {
		await createNewsService(newNews)
	}

	const updateNews = async (newsId: string, newNews: any) => {
		await updateNewsService(newsId, newNews)
	}

	const selectThumbnail = () => {
		openModal('Selecionar Imagem', <CutImage aspect={972 / 294} onCutImage={handleChangeImage} />)
	}

	const handleChangeImage = async (image: File) => {
		const formData = new FormData()
		formData.append('file', image)
		const { reference } = await uploadFile(formData)
		setThumbnail(reference)
		hideModal()
	}

	const getSingleNews = useCallback(async () => {
		const singleNews = await getSingleNewsService(newsId)
		setTitle(singleNews.title)
		setDescription(singleNews.description)
		setLocation(singleNews.location ? singleNews.location : null)
		setThumbnail(singleNews.thumbnail)
		setContent(singleNews.content)

		if (singleNews.category.category_id) {
			const foundCategory = categories!.find((cat) => cat.category_id === singleNews.category.category_id)!
			setSelectedCategory({ label: foundCategory?.title, value: foundCategory?.category_id })
		}
		setSelectedTags(singleNews.tags?.map((tag: string) => tag))
	}, [setTitle, setDescription, setThumbnail, setContent, setSelectedCategory, categories, newsId])

	const getCategories = async () => {
		const categories = await getCategoriesService()
		setCategories(categories)
	}

	useEffect(() => {
		getCategories()
	}, [])

	const isEditing = useMemo(() => !!newsId, [newsId])

	const tagsForSelect = useMemo(() => {
		return tags?.map((tag) => ({ value: tag.tag_id, label: tag.tag }))
	}, [tags])

	const getTags = useCallback(async () => {
		const tags = await getTagsService()
		setTags(tags)
	}, [setTags])

	useEffect(() => {
		getTags()
	}, [getTags])

	useEffect(() => {
		if (isEditing && categories) {
			getSingleNews()
		}
	}, [isEditing, categories, getSingleNews])

	const categoriesOptions = useMemo(() => {
		return categories?.map((cat) => ({ value: cat.category_id, label: cat.title }))
	}, [categories])

	return (
		<CreateAndEditNewsContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/">Home</Link>,
					<Link to="/news">Notícias</Link>,
					<span>{isEditing ? 'Editar' : 'Criar'} Notícia</span>,
				]}
			/>

			<DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Notícia</DefaultPageTitle>

			<DefaultCreationForm onSubmit={handleSubmit}>
				<DefaultCreationFormGroup>
					<label htmlFor="title" className="required">
						Título
					</label>

					<DefaultInput id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="description" className="required">
						Descrição
					</label>

					<DefaultTextArea
						id="description"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="location">
						Localização
					</label>
					<DefaultInput id="location" value={location} onChange={(e) => setLocation(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="content" className="required">
						Conteúdo
					</label>

					<DescriptionTextarea
						description={content}
						editorState={editorState}
						setEditorState={setEditorState}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="thumbnail" className="required">
						Categoria
					</label>

					<Select
						styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
						options={categoriesOptions}
						value={selectedCategory}
						onChange={(option) => option && setSelectedCategory(option)}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="tags" className="required">
						Tags
					</label>

					<Select
						isMulti
						options={tagsForSelect}
						value={tagsForSelect?.filter((tag) => selectedTags.includes(tag.value))}
						onChange={(opts) => opts && setSelectedTags(opts?.map((opt) => opt.value))}
						styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="thumbnail" className="required">
						Thumbnail
					</label>
					<span className="observation-img">
						<li>Recomendado: 972x294</li>
					</span>
					<ThumbnailButtonContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem
						</DefaultButton>
						{thumbnail && <img src={thumbnail} alt="Thumbnail" />}
					</ThumbnailButtonContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton onClick={goToNews} className="danger" type="button">
						Cancelar
					</DefaultButton>
					<DefaultButton className="primary" type="submit">
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditNewsContainer>
	)
}

export default CreateAndEditNews
