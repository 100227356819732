import httpClient from 'http-client'
import HomeInfo from 'models/home-info'

const getBannerLanding = async () => {
	const homeInfos = (await httpClient.get<HomeInfo[]>(`/home-info`, { params: { type: 'landing_medic' } })).data

	return homeInfos
}

const getBannerLandingById = async (homeInfoId: string) => {
	const homeInfos = (await httpClient.get<HomeInfo>(`/home-info/${homeInfoId}`, { params: { type: 'landing_medic' } })).data

	return homeInfos
}

const createBannerLanding = async (bannerInfo: any) => {
	return httpClient.post(`/home-info/`, {
		type: 'landing_medic',
		text: 'Banner Landing Page Exeltis Medicos',
		info: bannerInfo,
	})
}

const updateBannerLanding = async (homeInfoId: string, bannerInfo: any) => {
	return httpClient.put(`/home-info/${homeInfoId}`, {
		text: 'Banner Landing Page Exeltis Medicos',
		info: bannerInfo,
	})
}

const deleteBannerLanding = async (homeInfoId: string) => {
	return httpClient.delete(`/home-info/${homeInfoId}`)
}



export { getBannerLanding, getBannerLandingById, createBannerLanding, updateBannerLanding, deleteBannerLanding }
