import { DefaultButton } from 'components'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import checkEmptyString from 'helpers/check-empty-string'
import showErrorMessage from 'helpers/show-error-message'
import ContactFromApi from 'models/from-api-response/contact'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import {
	createContact as createContactService,
	updateContact as updateContactService,
	getContact as getContactService,
} from 'services/contacts'
import VMasker from 'vanilla-masker'
import ModalContext from 'contexts/ModalContext'

const CreateAndEditContactContainer = styled.div`
	flex: 1;
`

interface CreateAndEditContactProps {
	contactId?: string
	onSubmit: () => void
}

const CreateAndEditContact: React.FC<CreateAndEditContactProps> = ({ contactId, onSubmit }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [employeeName, setEmployeeName] = useState('')
	const { hideModal } = useContext(ModalContext)

	const goToContacts = () => {
		hideModal()
	}

	const validate = () => {
		if (checkEmptyString(name)) {
			throw new Error('Informe um nome válido para o contato!')
		}

		if (checkEmptyString(email)) {
			throw new Error('Informe um email válido para o contato!')
		}
	}

	const createContact = async (newContact: ContactFromApi) => {
		await createContactService(newContact)
	}

	const updateContact = async (newContact: ContactFromApi) => {
		await updateContactService(contactId!, newContact)
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			validate()

			const newContact = {
				name,
				email,
				employee_name: employeeName || undefined,
				phone_number: phone || undefined,
			}

			if (isEditting) {
				await updateContact(newContact)
			} else {
				await createContact(newContact)
			}

			await Swal.fire({ icon: 'success', text: `Contato ${isEditting ? 'atualizado' : 'criado'} com sucesso!` })

			onSubmit()

			goToContacts()
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const getContact = async () => {
		const contact = await getContactService(contactId!)
		setName(contact.name)
		setEmployeeName(contact.employee_name || '')
		setPhone(contact.phone_number || '')
		setEmail(contact.email)
	}

	const isEditting = useMemo(() => {
		return !!contactId
	}, [contactId])

	useEffect(() => {
		if (isEditting) {
			getContact()
		}
	}, [isEditting])

	return (
		<CreateAndEditContactContainer>
			<DefaultCreationForm onSubmit={handleSubmit}>
				<DefaultCreationFormGroup>
					<label htmlFor="name">Região</label>
					<DefaultInput id="name" value={name} onChange={(e) => setName(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="name">Nome do Colaborador</label>
					<DefaultInput id="name" value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="email">Email</label>
					<DefaultInput id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="phone">Telefone</label>
					<DefaultInput
						id="phone"
						type="phone"
						value={phone}
						onChange={(e) => setPhone(VMasker.toPattern(e.target.value, '(99) 99999-9999'))}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" onClick={goToContacts} className="danger">
						Cancelar
					</DefaultButton>
					<DefaultButton type="submit">Salvar</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditContactContainer>
	)
}

export default CreateAndEditContact
