import styled from 'styled-components'

const CreateAndEditArticleContainer = styled.div``

const ThumbnailButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	width: 100%;
	img {
		max-width: 100%;
	}
`

const SendContentButtonContainer = styled(ThumbnailButtonContainer)`
	flex-direction: column;
	justify-content: center;
	width: 100%;
`

export { CreateAndEditArticleContainer, ThumbnailButtonContainer, SendContentButtonContainer }
