import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Tippy from '@tippy.js/react'
import 'react-tippy/dist/tippy.css'
import { AiOutlineCheck, AiOutlineClose, AiOutlineSearch } from 'react-icons/ai'
import { FaUserSlash } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { DefaultButton, DefaultPageTitle, DefaultTable, Loading, ErrorUI } from 'components'
import { getAllUser, toogleAdminUser, deleteUser } from 'services/users'
import User from 'models/user'
import { DefaultCreateButtonAndSearchFormContainer, SearchContainer, UsersContainer } from './style'
import DefaultInput from 'components/DefaultInput'

const useUSERS = () => {
	const [users, setUsers] = useState<User[]>([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const getUsers = useCallback(async () => {
		try {
			setLoading(true)
			const usersReponse = await getAllUser()
			if (usersReponse && usersReponse.length) {
				setUsers(usersReponse)
			}
			setError(false)
		} catch (err) {
			setError(true)
		}
		setLoading(false)
	}, [setUsers])

	const requestUserAdmin = useCallback(
		async ({ user_id }: User) => {
			try {
				setLoading(true)
				await toogleAdminUser(user_id)
				Swal.fire({
					icon: 'success',
					title: 'Sucesso!',
					text: 'Privilégios alterado com sucesso!',
				})
			} catch (error) {
				Swal.fire({
					icon: 'error',
					text: `Erro ao atribuir privilégios de administrador ao usuário.`,
				})
				setError(true)
			}
			getUsers()
		},
		[getUsers]
	)

	const deleteUsers = useCallback(
		async ({ user_id }: User) => {
			setLoading(true)
			const responseFire = await Swal.fire({
				title: '<strong>Confirmação</strong>',
				html: `Tem certeza que deseja remover este usuário ?`,
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				focusConfirm: false,
			})
			if (responseFire.isConfirmed) {
				await deleteUser(user_id)

				Swal.fire({
					icon: 'success',
					title: 'Sucesso!',
					text: `O usuário foi excluido com sucesso!`,
				})

				await getUsers()
			}
			setLoading(false)
		},
		[getUsers]
	)

	useEffect(() => {
		getUsers()
	}, [getUsers])

	return { users, loading, error, requestUserAdmin, deleteUsers }
}

const getIconAdmin = (user: User) => {
	const { roles } = user
	if (roles && roles.length > 0 && roles.includes('Admin'))
		return (
			<DefaultButton className="small danger">
				<AiOutlineClose />
			</DefaultButton>
		)
	return (
		<DefaultButton className="small warning">
			<AiOutlineCheck />
		</DefaultButton>
	)
}

const UsersUI: React.FC = () => {
	const { users, loading, error, requestUserAdmin, deleteUsers } = useUSERS()
	const [filter, setFilter] = useState('')

	const userFilter = useMemo(() =>{
		const lowerCase = filter.toLowerCase()
		return users.filter(user => (
			user.name.toLowerCase().includes(lowerCase)
			|| user.email.toLowerCase().includes(lowerCase)
		))
	}, [filter, users])

	const UsersToBeShown = useMemo(
		() =>
			(userFilter || [])
				.sort((u1, u2) => u1.name.localeCompare(u2.name))
				.map((user) => ({
					id: user.user_id,
					name: user.name,
					roles: user.roles.join(', '),
					email: (
						<a style={{ textDecoration: 'none', color: '#8898aa' }} href={'mailto:' + user.email}>
							{user.email}
						</a>
					),
					telephone: (
						<a
							style={{ textDecoration: 'none', color: '#8898aa' }}
							className="anchor-link"
							href="tel:{user.info.telephone}"
						>
							{user.info.telephone}
						</a>
					),
					login_by: (
						<a
							style={{ textDecoration: 'none', color: '#8898aa' }}
							className="anchor-link"
							href="login_by:{user.info.login_by}"
						>
							{user.info.login_by ? user.info.login_by : 'Sistema'}
						</a>
					),
					confirmed: (
						<div>
							{user?.is_confirmed ? 'Confirmado' : 'Não confirmado'}
						</div>
					),
					actions: (
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Tippy content="Alterar privilégios">
								<div onClick={() => requestUserAdmin(user)}>{getIconAdmin(user)}</div>
							</Tippy>
							<Tippy content="Excluir usuário">
								<DefaultButton
									style={{ marginLeft: '10px' }}
									className="small danger"
									onClick={() => deleteUsers(user)}
								>
									<FaUserSlash />
								</DefaultButton>
							</Tippy>
						</div>
					),
				})),
		[userFilter, requestUserAdmin, deleteUsers]
	)

	if (loading) {
		return <Loading />
	}

	if (error) {
		return <ErrorUI type="loadingError" />
	}

	return (
		<UsersContainer className="users-list">
			<DefaultPageTitle>Lista de Usuários</DefaultPageTitle>
			<SearchContainer>
				<DefaultCreateButtonAndSearchFormContainer
					style={{ justifyContent: 'flex-end' }}
				>
					<DefaultInput
						type="search"
						placeholder="Digite sua pesquisa aqui..."
						value={filter}
						onChange={e => setFilter(e.target.value)}
						style={{ margin: 0 }}
					/>
				</DefaultCreateButtonAndSearchFormContainer>
			</SearchContainer>
			<DefaultTable
				headersConfig={[
					{
						headerLabel: <span>Nome</span>,
						propName: 'name',
					},
					{
						headerLabel: <span>Email</span>,
						propName: 'email',
					},
					{
						headerLabel: <span>Telefone</span>,
						propName: 'telephone',
					},
					{
						headerLabel: <span>Tipo de Cadastro</span>,
						propName: 'login_by',
					},
					{
						headerLabel: <span>Tipo de usuário</span>,
						propName: 'roles',
					},
					{
						headerLabel: <span>Status</span>,
						propName: 'confirmed',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={UsersToBeShown}
				emptyListMessage={'Não foram encontrados usuários cadastrados!'}
			/>
		</UsersContainer>
	)
}

export default UsersUI
