import styled from 'styled-components'

const CreateAndEditEventContainer = styled.div`
	.margin {
		margin-bottom: 10px;
	}

	.no-speakers {
		color: grey;
		margin-bottom: 15px;
	}

	.add-speakers-wrapper {
		display: block;

		.add-speakers-message {
			opacity: 0;
			margin-left: 20px;
			color: grey;
			transition: ease .3s;
		}

		button:hover + .add-speakers-message {
			opacity: 1;
		}
	}
`

const WrapperSelect = styled.div`
	display: flex;
	width: 100%;
	gap: 25px;
	>div:first-of-type, >div:nth-of-type(2){
		width: 44%;
		>div{ width: 100%; }
	}
`;

const UploadContainer = styled.div`
	border-radius: 5px;
	border: solid 1px var(--default-dark-gray);
	padding: 10px 15px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	img {
		margin-top: 10px;
	}

	.video-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;

		div {
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 15px;
			text-align: center;
		}
		iframe {
			width: 600px;
			aspect-ratio: 9 / 5.3;
		}
	}
`

const VideoInputContainer = styled.div`
	text-align: center;

	input {
		display: none;
	}
`

const LiveVideo = styled.iframe`
	max-width: 600px;
`

const EventThumbnail = styled.img`
	max-width: 600px;
`

const Speaker = styled.div`
	margin-bottom: 20px;
	border-bottom: 1px solid var(--default-dark-gray);
	padding-bottom: 10px;
	display: flex;
	align-items: center;

	img {
		margin-right: 10px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		object-fit: cover;
	}

	button {
		margin-left: 10px;
	}
`

const CheckboxBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 300px;

	label, div, input {
		align-self: center;
		margin: 0;
		min-width: unset;
	}

	div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		cursor: pointer;

		input {
			cursor: pointer;
			margin-right: 5px;
		}

		label {
			cursor: pointer;
		}
	}
`

const ReloadWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;
`;

const ButtonReload = styled.button`
	background-color: var(--primary-color);
	border: 1px solid #cccccc;
	height: 40px;
	padding: 20px;
	display: flex;
	align-items: center;
	color: #FFF;
	cursor: pointer;
	svg{
		width: 15px;
		height: 15px;
	}
`;

export { CreateAndEditEventContainer, EventThumbnail, LiveVideo, VideoInputContainer, UploadContainer, Speaker, CheckboxBlock, WrapperSelect, ButtonReload, ReloadWrapper }
