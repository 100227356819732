import { DefaultButton } from 'components'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import showErrorMessage from 'helpers/show-error-message'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import VMasker from 'vanilla-masker'
import {
	updateSAC as updateSACService,
	getSAC as getSACService,
	createSAC as createSACService,
} from 'services/contact/sac'
import HomeInfo from 'models/home-info'

const SACContainer = styled.div``

const SAC: React.FC = () => {
	let [sac, setSac] = useState<HomeInfo | undefined>(undefined)
	const [sacHasBeenCreated, setSacHasBeenCreated] = useState(false)

	const updateSAC = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (!sac?.info?.subtitle) {
				throw new Error('Informe um número de telefone válido para o SAC!')
			}

			if (sacHasBeenCreated) {
				await updateSACService(sac)
			} else {
				await createSACService(sac.info)
			}
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const getSac = async () => {
		const sac = await getSACService()

		setSacHasBeenCreated(!!sac)

		setSac(sac)
	}

	useEffect(() => {
		getSac()
	}, [])

	return (
		<SACContainer>
			<DefaultCreationForm onSubmit={updateSAC}>
				<DefaultCreationFormGroup>
					<label htmlFor="text">Telefone (SAC)</label>
					<DefaultInput
						required
						type="text"
						value={sac?.info?.subtitle || ''}
						onChange={(e) => {
							if (!sac) {
								sac = {} as HomeInfo
							}

							if (!sac.info) {
								sac.info = {}
							}

							sac.info.subtitle = VMasker.toPattern(
								e.target.value,
								e.target.value.length <= 13
									? '9999 999 9999'
									: e.target.value.length <= 14
									? '(99) 9999-9999'
									: '(99) 99999-9999'
							)

							setSac((sac) => ({ ...(sac as HomeInfo) }))
						}}
					/>
				</DefaultCreationFormGroup>
				<DefaultCreationFormButtonGroup>
					<DefaultButton type="submit" className="primary">
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</SACContainer>
	)
}

export default SAC
