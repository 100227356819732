import { BreadCrumb, DefaultButton, DefaultPageTitle } from 'components'
import CutImage from 'components/CutImage'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import DefaultTextArea from 'components/DefaultTextArea'
import { ARTICLES } from 'components/Routes/Constants'
import checkEmptyString from 'helpers/check-empty-string'
import showErrorMessage from 'helpers/show-error-message'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { uploadFile } from 'services/files'
import { CreateAndEditArticleContainer, SendContentButtonContainer, ThumbnailButtonContainer } from './style'
import {
	createArticle as createArticleService,
	updateArticle as updateArticleService,
	getArticle as getArticleService,
} from 'services/articles'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Select from 'react-select'
import { getAllCategories as getCategoriesService } from 'services/categories'
import { updateContentThumbnail } from 'services/contents'
import { getTags as getTagsService } from 'services/tags'
import TagFromResponse from 'models/from-api-response/tag'
import ModalContext from 'contexts/ModalContext'

interface CreateAndEditArticleParams {
	articleId: string
	type: string
}

const CreateAndEditArticle: React.FC = () => {
	const { type: initialType, articleId } = useParams<CreateAndEditArticleParams>()
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [thumbnail, setThumbnail] = useState('')
	const [reference, setReference] = useState('')
	const [type, setType] = useState('')
	const [categories, setCategories] = useState([] as { value: string; label: string }[])
	const [selectedCategory, setSelectedCategory] = useState('')
	const [tags, setTags] = useState([] as TagFromResponse[])
	const [selectedTags, setSelectedTags] = useState<string[]>([])
	const history = useHistory()
	const fileInput = useRef<HTMLInputElement>(null)
	const { openModal, hideModal } = useContext(ModalContext)

	const isEditing = useMemo(() => {
		return !!articleId
	}, [articleId])

	const getArticle = useCallback(async () => {
		if (isEditing) {
			const article = await getArticleService(articleId)
			setTitle(article.title)
			setDescription(article.description)
			setThumbnail(article.thumbnail)
			setReference(article.reference)
			setType(article.type)
			setSelectedCategory(
				article?.category_id ||
				(article?.categories || [])
					.filter((cat: any) => cat?.category_id)
					?.map((cat: any) => cat?.category_id)[0]
			)
			setSelectedTags(article.tags?.map((tag: { tag_id: string }) => tag.tag_id))
		}
	}, [isEditing, setTitle, setDescription, setThumbnail, setReference, setType, articleId])

	const selectThumbnail = () => {
		openModal('Selecionar Imagem', <CutImage aspect={616 / 186} onCutImage={handleChangeImage} />)
	}

	const handleChangeImage = async (image: File) => {
		const formData = new FormData()
		formData.append('file', image)
		const { reference } = await uploadFile(formData)
		setThumbnail(reference)
		hideModal()
	}

	const sendContent = () => {
		fileInput?.current?.click()
	}

	const handleContentChange = async () => {
		const file = (fileInput?.current?.files || [])[0]
		if (file) {
			const formData = new FormData()
			formData.append('file', file)
			const { reference } = await uploadFile(formData)
			setReference(reference)
		}
	}

	const goToArticles = () => {
		history.push(ARTICLES)
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			validate()

			if (isEditing) {
				await updateArticle()
			} else {
				await createArticle()
			}

			goToArticles()
		} catch (error) {
			showErrorMessage(error, `Erro ao ${isEditing ? 'editar' : 'criar'} artigo`)
		}
	}

	const validate = () => {
		if (checkEmptyString(title)) {
			throw new Error('Informe um título válido para o artigo.')
		}

		if (!selectedTags?.length) {
			throw new Error(`Informe ao menos uma tag para o artigo.`)
		}

		if (checkEmptyString(reference)) {
			throw new Error('Informe um conteúdo para o artigo.')
		}

		if (!selectedCategory) {
			throw new Error('Informe uma categoria para o artigo')
		}
	}

	const createArticle = async () => {
		await createArticleService({
			title,
			description,
			reference,
			type,
			thumbnail,
			category_id: selectedCategory,
			tags: selectedTags?.length
				? selectedTags.map((tag) => ({
					tag_id: tag,
				}))
				: undefined,
		})
	}

	const updateArticle = async () => {
		await updateArticleService(articleId, {
			title,
			description,
			reference,
			type,
			category_id: selectedCategory,
			tags: selectedTags?.length
				? selectedTags.map((tag) => ({
					tag_id: tag,
				}))
				: undefined,
		})

		await updateContentThumbnail(articleId, thumbnail)
	}

	const getCategories = useCallback(async () => {
		const categories = await getCategoriesService()
		setCategories(categories?.map((cat) => ({ label: cat.title, value: cat.category_id })))
	}, [setCategories])

	const articleTypeDescription = useMemo(() => {
		if (type?.toLocaleLowerCase()?.includes('cientifico')) {
			return 'Artigo Científico'
		}

		if (type?.toLocaleLowerCase()?.includes('comentado')) {
			return 'Artigo Comentado'
		}

		if (type?.toLocaleLowerCase()?.includes('monografia')) {
			return 'Monografia'
		}
	}, [type])

	const tagsForSelect = useMemo(() => {
		return tags?.map((tag) => ({ value: tag.tag_id, label: tag.tag }))
	}, [tags])

	const getTags = useCallback(async () => {
		const tags = await getTagsService()
		setTags(tags)
	}, [setTags])

	useEffect(() => {
		getTags()
	}, [getTags])

	useEffect(() => {
		getArticle()
	}, [getArticle])

	useEffect(() => {
		if (initialType) {
			setType(initialType)
		}
	}, [initialType, setType])

	useEffect(() => {
		getCategories()
	}, [getCategories])

	return (
		<CreateAndEditArticleContainer>
			<BreadCrumb
				crumbs={[
					<Link to="/">Home</Link>,
					<Link to="/articles">Artigos</Link>,
					<span>
						{isEditing ? 'Editar' : 'Criar'} {articleTypeDescription}
					</span>,
				]}
			/>
			<DefaultPageTitle>
				{isEditing ? 'Editar' : 'Criar'} {articleTypeDescription}
			</DefaultPageTitle>

			<DefaultCreationForm onSubmit={handleSubmit}>
				<DefaultCreationFormGroup>
					<label htmlFor="title" className="required">
						Título
					</label>
					<DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="description">Descrição</label>
					<DefaultTextArea value={description} onChange={(e) => setDescription(e.target.value)} />
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="description">Capa</label>
					<span className="observation-img">
						<li>Recomendado: 615x186</li>
					</span>
					<ThumbnailButtonContainer>
						<DefaultButton type="button" onClick={selectThumbnail}>
							Selecionar Imagem
						</DefaultButton>
						{thumbnail && <img src={thumbnail} alt="Thumbnail" />}
					</ThumbnailButtonContainer>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="category" className="required">
						Categoria
					</label>
					<Select
						id="category"
						styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
						options={categories}
						value={categories.find((cat) => cat.value === selectedCategory)}
						onChange={(option) => option && setSelectedCategory(option.value)}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="tags" className="required">
						Tags
					</label>

					<Select
						isMulti
						options={tagsForSelect}
						value={tagsForSelect?.filter((tag) => selectedTags.includes(tag.value))}
						onChange={(opts) => opts && setSelectedTags(opts?.map((opt) => opt.value))}
						styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
					/>
				</DefaultCreationFormGroup>

				<DefaultCreationFormGroup>
					<label htmlFor="description" className="required">
						Conteúdo
					</label>
					<Tabs style={{ width: '100%' }}>
						<TabList>
							<Tab>Arquivo</Tab>
							<Tab>Link</Tab>
						</TabList>

						<TabPanel>
							<SendContentButtonContainer>
								{(fileInput?.current?.files || []).length > 0 && (
									<span>
										<strong>Arquivo: </strong> {(fileInput?.current?.files || [])[0]?.name}
									</span>
								)}
								<DefaultButton type="button" onClick={sendContent}>
									Enviar Conteúdo
								</DefaultButton>
								<input
									ref={fileInput}
									type="file"
									style={{ display: 'none' }}
									onChange={handleContentChange}
								/>
							</SendContentButtonContainer>
						</TabPanel>
						<TabPanel>
							<DefaultInput
								type="url"
								id="url"
								placeholder="Url"
								value={reference}
								onChange={(e) => setReference(e.target.value)}
							/>
						</TabPanel>
					</Tabs>
				</DefaultCreationFormGroup>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="button" className="danger" onClick={goToArticles}>
						Cancelar
					</DefaultButton>
					<DefaultButton type="submit" className="primary">
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</CreateAndEditArticleContainer>
	)
}

export default CreateAndEditArticle
