import medicoMenus from './for-medico'
import gestanteMenus from './for-gestante'

export interface Menu {
	label: JSX.Element
	path?: string
	children?: Menu[]
}

const getMenus = (adminType: 'medico' | 'gestante') => {
	if (adminType === 'medico') {
		return medicoMenus
	} else {
		return gestanteMenus
	}
}

export default getMenus
