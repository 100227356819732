import { AppContainer, AppContent, AppLeftContent, AppRightContent } from './AppStyle'
import Routes from './components/Routes'
import { SideBar } from './components'
import './index.css'
import AdminTypeSwitch from 'components/AdminTypeSwitch'
import { useLocation } from 'react-router'
import { LOGIN } from 'components/Routes/Constants'
import ModalContextProvider from 'components/ModalContextProvider'
import Modals from 'components/Modals'

function App() {
	const location = useLocation()

	return (
		<AppContainer>
			<ModalContextProvider>
				<AppLeftContent>
					<SideBar />
				</AppLeftContent>
				<AppRightContent>
					<AppContent>
						<Routes />
					</AppContent>
				</AppRightContent>
				{location.pathname !== LOGIN && <AdminTypeSwitch />}
				<Modals />
			</ModalContextProvider>
		</AppContainer>
	)
}

export default App
