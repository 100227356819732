import httpClient from '../http-client'
import download from 'downloadjs'

const generateLogUserAccessReport = async (format: string, queryString: string) => {
	const reportResponse = await httpClient.get(`reports/user-access/${format}`, {
		params: queryString,
		responseType: 'blob',
	})

	const contentType = reportResponse.headers['content-type']

	download(reportResponse.data, `relatório_log_acesso_usuários.${format}`, contentType)
}

const generateEngagementReport = async (format: string, queryString: string) => {
	const reportResponse = await httpClient.get(`reports/engagement/${format}`, {
		params: queryString,
		responseType: 'blob',
	})

	const contentType = reportResponse.headers['content-type']

	download(reportResponse.data, `relatório_engajamento.${format}`, contentType)
}

const generateContentEngagementReport = async (format: string, queryString: string) => {
	const reportResponse = await httpClient.get(`reports/engagement-user/${format}`, {
		params: queryString,
		responseType: 'blob',
	})

	const contentType = reportResponse.headers['content-type']

	download(reportResponse.data, `relatório_engajamento_usuario_conteudo.${format}`, contentType)
}

const generateUsersReport = async (format: string, queryString: string) => {
	const reportResponse = await httpClient.get(`reports/users/${format}`, {
		params: queryString,
		responseType: 'blob',
	})

	const contentType = reportResponse.headers['content-type']

	download(reportResponse.data, `relatório_usuários.${format}`, contentType)
}

const generateLivesReport = async (format: string, queryString: string) => {
	const reportResponse = await httpClient.get(`reports/live-watch-time/${format}`, {
		params: queryString,
		responseType: 'blob',
	})

	const contentType = reportResponse.headers['content-type']

	download(reportResponse.data, `relatório_lives.${format}`, contentType)
}

export { generateLogUserAccessReport, generateEngagementReport, generateUsersReport, generateLivesReport, generateContentEngagementReport }
