import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'
import BreadCrumb from 'components/BreadCrumb'
import { DefaultButton, DefaultPageTitle, DefaultTable } from 'components'
import Course from 'models/course'
import { getAllCourses, activateOrInactivateCourse as activateOrInactivateCourseService } from 'services/courses'
import { CoursesContainer } from './style'
import Swal from 'sweetalert2'

const Courses: React.FC = () => {
	const history = useHistory()

	const [courses, setCourses] = useState([] as Course[])

	const getCourses = async () => {
		const localCourses = await getAllCourses()
		if (localCourses && localCourses.length) {
			setCourses(localCourses)
		}
	}

	const editCourse = useCallback(
		(courseId: string | undefined) => {
			history.push(`edit-course/${courseId}`)
		},
		[history]
	)

	const activateOrInactivateCourse = useCallback(async (courseId: any, activate: boolean) => {
		Swal.fire({
			title: 'Confirmação',
			text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este curso?`,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			focusConfirm: false,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await activateOrInactivateCourseService(courseId, activate)
					getCourses()
				} catch (error:any) {
					Swal.fire({
						title: 'Erro',
						text: `Erro ao ${activate ? 'ativar' : 'inativar'} curso! ${
							error.response && error.response.status === 423
								? 'Este curso já está associado a uma trilha!'
								: error.message
						}`,
					})
				}
			}
		})
	}, [])

	const coursesToBeShown = useMemo(() => {
		return courses && courses.length
			? courses.map((course) => ({
					id: course.id,
					course_id: course.course_id,
					name: course.title,
					active: course.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
					actions: (
						<>
							<DefaultButton className="small info" onClick={() => editCourse(course.course_id)}>
								<BiEdit />
							</DefaultButton>{' '}
							<DefaultButton
								onClick={() => activateOrInactivateCourse(course.course_id, !course.is_active)}
								className="small warning"
								title={(course.is_active ? 'Inativar' : 'Ativar') + ' curso'}
							>
								{course.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
							</DefaultButton>
						</>
					),
			  }))
			: []
	}, [courses, editCourse])

	useEffect(() => {
		getCourses()
	}, [])

	return (
		<CoursesContainer>
			<BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Módulos</span>]} />

			<DefaultPageTitle>Módulos</DefaultPageTitle>

			<DefaultTable
				usePagination={false}
				headersConfig={[
					{
						headerLabel: <span>Nome</span>,
						propName: 'name',
					},
					{
						headerLabel: <span>Ativo</span>,
						propName: 'active',
					},
					{
						headerLabel: <span>Ações</span>,
						propName: 'actions',
					},
				]}
				items={coursesToBeShown}
				emptyListMessage={'Não foram encontrados módulos cadastrados!'}
			/>
		</CoursesContainer>
	)
}

export default Courses
