import { BreadCrumb, DefaultButton, DefaultPageTitle, DefaultTable } from 'components'
import DefaultFilterForm from 'components/DefaultFilterForm'
import ContentFromReponse from 'models/from-api-response/content'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineDownload, AiOutlineEdit } from 'react-icons/ai'
import { Link, useHistory } from 'react-router-dom'
import showErrorMessage from 'helpers/show-error-message'
import { deleteArticle, getArticles as getArticlesService } from '../../../services/articles'
import { CREATE_ARTICLE, EDIT_ARTICLE } from 'components/Routes/Constants'
import { BiTrash } from 'react-icons/bi'
import Swal from 'sweetalert2'
import { ArticlesContainer, ChooseTypeOfArticleContainer } from './style'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import ModalContext from 'contexts/ModalContext'

interface ArticleTableProps {
	articles: any[]
}

const ArticleTable: React.FC<ArticleTableProps> = ({ articles }) => {
	return (
		<DefaultTable
			headersConfig={[
				{ headerLabel: <>Título</>, propName: 'title' },
				{ headerLabel: <>Descrição</>, propName: 'description' },
				{ headerLabel: <>Arquivo</>, propName: 'file' },
				{ headerLabel: <>Ativo</>, propName: 'isActive' },
				{ headerLabel: <>Ações</>, propName: 'actions' },
			]}
			items={articles}
		/>
	)
}

interface ChooseTypeOfArticleProps {
	onChoose: (tag: string) => void
}

const ChooseTypeOfArticle: React.FC<ChooseTypeOfArticleProps> = ({ onChoose }) => {
	const { hideModal } = useContext(ModalContext);

	const handleChoose = (type: string) => {
		onChoose(type)
		hideModal()
	}

	return (
		<ChooseTypeOfArticleContainer>
			<DefaultButton type="button" onClick={() => handleChoose('ARTIGO_CIENTIFICO')}>
				Artigo Científico
			</DefaultButton>
			<DefaultButton type="button" onClick={() => handleChoose('MONOGRAFIA')}>
				Monografia
			</DefaultButton>
			<DefaultButton type="button" onClick={() => handleChoose('ARTIGO_COMENTADO')}>
				Artigo Comentado
			</DefaultButton>
		</ChooseTypeOfArticleContainer>
	)
}

interface ArticlesProps {
	type: string
}

const Articles: React.FC<ArticlesProps> = ({ type }) => {
	const [commentedArticles, setCommentedArticles] = useState([] as ContentFromReponse[])
	const [cientificArticles, setCientificArticles] = useState([] as ContentFromReponse[])
	const [monographies, setMonographies] = useState([] as ContentFromReponse[])
	const { openModal, hideModal } = useContext(ModalContext);
	const history = useHistory()

	const chooseTypeOfArticle = () => {
		openModal('Escolha o tipo de artigo', <ChooseTypeOfArticle onChoose={createArticle} />)
	}

	const createArticle = (type: string) => {
		history.push(CREATE_ARTICLE.replace(':type', type))
	}

	const editArticle = (articleId: string) => {
		history.push(EDIT_ARTICLE.replace(':articleId', articleId))
	}

	const removeArticle = async (articleId: string) => {
		const result = await Swal.fire({
			icon: 'question',
			text: 'Deseja remover este artigo?',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			try {
				await deleteArticle(articleId)
				Swal.fire({
					icon: 'success',
					title: 'Sucesso!',
					text: `Artigo excluído com sucesso!`,
				})

				await getArticles()
			} catch (error) {
				showErrorMessage(error, 'Erro ao remover artigo.')
			}
		}
	}

	const activeOrInactiveArticle = async (articleId: string, isActive: boolean) => {
		const result = await Swal.fire({
			icon: 'question',
			text: `Deseja ${isActive ? 'inativar' : 'ativar'} este artigo?`,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			try {
			} catch (error) {
				showErrorMessage(error, `Erro ao ${isActive ? 'inativar' : 'ativar'} artigo.`)
			}
		}
	}

	const getArticles = async () => {
		const { monographies, cientificArticles, commentedArticles } = await getArticlesService(type)
		setCommentedArticles(commentedArticles)
		setCientificArticles(cientificArticles)
		setMonographies(monographies)
	}

	const handleDownload = async (reference: string) => {
		try {
			window.open(reference, '_blank')
		} catch (error) {
			showErrorMessage(error, 'Erro ao fazer download do artigo')
		}
	}

	const configureArticleForTableRow = (articles: ContentFromReponse[]) => {
		return articles?.map((article) => ({
			title: article.title,
			description: article.description,
			file: (
				<DefaultButton
					type="button"
					onClick={() => handleDownload(article.reference)}
					className="small default"
				>
					<AiOutlineDownload />
				</DefaultButton>
			),
			isActive: article.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
			actions: (
				<>
					<DefaultButton onClick={() => editArticle(article.content_id)} type="button" className="small info">
						<AiOutlineEdit />
					</DefaultButton>{' '}
					<DefaultButton
						type="button"
						className="small warning"
						onClick={() => activeOrInactiveArticle(article.content_id, article.is_active)}
					>
						{article.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
					</DefaultButton>{' '}
					<DefaultButton
						type="button"
						className="small danger"
						onClick={() => removeArticle(article.content_id)}
					>
						<BiTrash />
					</DefaultButton>
				</>
			),
		}))
	}

	useEffect(() => {
		getArticles()
	}, [])

	const commentedArticleRows = useMemo(() => {
		return configureArticleForTableRow(commentedArticles)
	}, [commentedArticles])

	const cientificArticleRows = useMemo(() => {
		return configureArticleForTableRow(cientificArticles)
	}, [cientificArticles])

	const monographyRows = useMemo(() => {
		return configureArticleForTableRow(monographies)
	}, [monographies])

	return (
		<ArticlesContainer>
			<BreadCrumb crumbs={[<Link to="/">Home</Link>, <span>Artigos</span>]} />
			<DefaultPageTitle>Artigos</DefaultPageTitle>

			<DefaultFilterForm>
				<div>
					<DefaultButton type="button" onClick={chooseTypeOfArticle}>
						Criar Artigo
					</DefaultButton>
				</div>
			</DefaultFilterForm>

			<Tabs>
				<TabList>
					<Tab>Artigos Científicos</Tab>
					<Tab>Monografias</Tab>
					<Tab>Artigos Comentados</Tab>
				</TabList>

				<TabPanel>
					<ArticleTable articles={cientificArticleRows} />
				</TabPanel>
				<TabPanel>
					<ArticleTable articles={monographyRows} />
				</TabPanel>
				<TabPanel>
					<ArticleTable articles={commentedArticleRows} />
				</TabPanel>
			</Tabs>
		</ArticlesContainer>
	)
}

export default Articles
