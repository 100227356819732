import { DefaultButton } from 'components'
import DefaultCreationForm, {
	DefaultCreationFormButtonGroup,
	DefaultCreationFormGroup,
} from 'components/DefaultCreationForm'
import DefaultInput from 'components/DefaultInput'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import cepFetcher from 'cep-promise'
import Address from 'models/address'
import VMasker from 'vanilla-masker'
import { BiTrash } from 'react-icons/bi'
import Swal from 'sweetalert2'
import showErrorMessage from 'helpers/show-error-message'
import HomeInfo from 'models/home-info'
import {
	getEnderecos as getEnderecosService,
	updateEnderecos as updateEnderecosService,
	createEnderecos as createEnderecosService,
} from 'services/contact/enderecos'

const EnderecosContainer = styled.div``

const AddressIndex = styled.div`
	font-size: 1.2rem;
	border-top: solid 1px #777;
	border-bottom: solid 1px #777;
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px;
`

const AddNewAddressButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
`

const emptyAddress: Address = {
	cep: '',
	address: '',
	number: '',
	complement: '',
	neighborhood: '',
	city: '',
	state: '',
}

const Enderecos: React.FC = () => {
	let [enderecos, setEnderecos] = useState<HomeInfo | undefined>(undefined)
	const [addresses, setAddresses] = useState<Address[]>([emptyAddress])

	const handleCEP = async (cep: string, addressReference: Address) => {
		const result = await cepFetcher(cep)
		addressReference.address = result.street
		addressReference.neighborhood = result.neighborhood
		addressReference.state = result.state
		addressReference.city = result.city

		updateAdresses()
	}

	const addNewEmptyAddress = () => {
		setAddresses((add) => [...(add || []), emptyAddress])
	}

	const updateAdresses = () => {
		setAddresses((add) => [...(add || [])])
	}

	const removeAddress = async (addressToBeRemoved: Address) => {
		const result = await Swal.fire({
			icon: 'question',
			text: 'Tem certeza que deseja remover este endereço de contato?',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			try {
				const filteredAddresses = addresses.filter(
					(add) => JSON.stringify(add) !== JSON.stringify(addressToBeRemoved)
				)

				setAddresses(filteredAddresses)
			} catch (error) {
				showErrorMessage(error)
			}
		}
	}

	const [enderecosHasBeenCreated, setEnderecosHasBeenCreated] = useState(false)

	const updateEndereco = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (!addresses?.length) {
				throw new Error('Informe ao menos um endereço!')
			}

			if (enderecosHasBeenCreated) {
				if (!enderecos) {
					enderecos = {} as HomeInfo
				}

				if (!enderecos.info) {
					enderecos.info = {}
				}

				enderecos.info.addresses = addresses
				await updateEnderecosService(enderecos)
			} else {
				await createEnderecosService({ addresses })
			}
		} catch (error) {
			showErrorMessage(error)
		}
	}

	const getEnderecos = async () => {
		const enderecos = await getEnderecosService()

		setEnderecosHasBeenCreated(!!enderecos)

		let addresses = [emptyAddress]
		if (!!enderecos) {
			addresses = enderecos.info.addresses
		}

		setAddresses(addresses)
		setEnderecos(enderecos)
	}

	useEffect(() => {
		getEnderecos()
	}, [])

	return (
		<EnderecosContainer>
			<DefaultCreationForm onSubmit={updateEndereco}>
				{addresses?.map((add, index) => (
					<>
						<AddressIndex>
							<span>Endereço {index + 1}</span>
							{addresses.length > 1 && (
								<DefaultButton
									type="button"
									className="danger small"
									onClick={(e) => removeAddress(add)}
								>
									<BiTrash size={16} />
								</DefaultButton>
							)}
						</AddressIndex>

						<DefaultCreationFormGroup>
							<label htmlFor="cep">CEP</label>
							<DefaultInput
								id="cep"
								type="text"
								value={add.cep}
								onChange={(e) => {
									if (e.target.value.length === 9) {
										handleCEP(e.target.value.replace('-', ''), add)
									}

									add.cep = VMasker.toPattern(e.target.value, '99999-999')
									updateAdresses()
								}}
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="address" className="required">
								Endereço
							</label>
							<DefaultInput
								id="address"
								type="text"
								value={add.address}
								onChange={(e) => {
									add.address = e.target.value
									updateAdresses()
								}}
								required
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="number" className="required">
								Número
							</label>
							<DefaultInput
								id="number"
								type="text"
								value={add.number}
								onChange={(e) => {
									add.number = e.target.value
									updateAdresses()
								}}
								required
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="complement">Complemento</label>
							<DefaultInput
								id="complement"
								type="text"
								value={add.complement}
								onChange={(e) => {
									add.complement = e.target.value
									updateAdresses()
								}}
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="neighborhood" className="required">
								Bairro
							</label>
							<DefaultInput
								id="neighborhood"
								type="text"
								value={add.neighborhood}
								onChange={(e) => {
									add.neighborhood = e.target.value
									updateAdresses()
								}}
								required
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="city" className="required">
								Cidade
							</label>
							<DefaultInput
								id="city"
								type="text"
								value={add.city}
								onChange={(e) => {
									add.city = e.target.value
									updateAdresses()
								}}
								required
							/>
						</DefaultCreationFormGroup>

						<DefaultCreationFormGroup>
							<label htmlFor="state" className="required">
								Estado
							</label>
							<DefaultInput
								id="state"
								type="text"
								value={add.state}
								onChange={(e) => {
									add.state = e.target.value
									updateAdresses()
								}}
								required
							/>
						</DefaultCreationFormGroup>
					</>
				))}

				<AddNewAddressButtonContainer>
					<DefaultButton type="button" onClick={addNewEmptyAddress}>
						Novo Endereço
					</DefaultButton>
				</AddNewAddressButtonContainer>

				<DefaultCreationFormButtonGroup>
					<DefaultButton type="submit" className="primary">
						Salvar
					</DefaultButton>
				</DefaultCreationFormButtonGroup>
			</DefaultCreationForm>
		</EnderecosContainer>
	)
}

export default Enderecos
